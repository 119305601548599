import React from 'react'
import DirectoryIcon from 'assets/DirectoryIcon'
import {route} from 'router'
import {connect} from 'react-redux'
import {
  setDirectoryPath,
  toggleIncludeInactive,
  toggleIncludeOOS,
  setViewType
} from 'store/actions'

import './Toolbar.scss'

const Arr = ({children}) => children

const Breadcrumbs = ({
  setDirectoryPath,
  path,
  isSuperUser,
  isManager,
  includeOOS,
  toggleIncludeOOS,
  includeInactive,
  toggleIncludeInactive,
  viewType,
  setViewType
}) => (
  <div className="directory-top-bar">
    <ul className="breadcrumbs">
      <li
        className="clickable root-directory-icon"
        onClick={() => {
          setDirectoryPath('')
          route('/directory')
        }}
      >
        <DirectoryIcon />
      </li>
      {(path || '')
        .substr(1)
        .split('/')
        .map((f, i, folders) => (
          <Arr key={i}>
            {i !== 0 && <i className="fa fa-angle-right" />}
            <li
              key={i}
              className="clickable"
              onClick={() => {
                setDirectoryPath('/' + folders.slice(0, i + 1).join('/'))
                route('/directory')
              }}
            >
              {f}
            </li>
          </Arr>
        ))}
    </ul>
    {/* {(isSuperUser || isManager) && (
      <div className="show-inactive-toggle">
        <Input
          type="select"
          value={
            includeOOS ? 'all' : includeInactive ? 'includeInactive' : 'active'
          }
          options={[
            {label: 'show active devices', value: 'active'},
            {label: 'show inactive devices', value: 'includeInactive'},
            {label: 'show all devices (include decomissioned)', value: 'all'}
          ]}
          onChange={(e) => {
            if (e.target.value === 'active') {
              toggleIncludeInactive(false)
              toggleIncludeOOS(false)
            } else if (e.target.value === 'includeInactive') {
              toggleIncludeInactive(true)
              toggleIncludeOOS(false)
            } else if (e.target.value === 'all') {
              toggleIncludeOOS(true)
              toggleIncludeInactive(false)
            }
          }}
        ></Input>
      </div>
    )} */}
    {isManager && !isSuperUser && (
      <div className="show-inactive-toggle">
        <select
          value={includeInactive ? 'includeInactive' : 'active'}
          onChange={(e) => {
            if (e.target.value === 'active') {
              toggleIncludeInactive(false)
              toggleIncludeOOS(false)
            } else if (e.target.value === 'includeInactive') {
              toggleIncludeInactive(true)
              toggleIncludeOOS(false)
            }
          }}
        >
          <option value="active">Active Devices</option>
          <option value="includeInactive">Inactive Devices</option>
        </select>
      </div>
    )}
    {/* <button className="time-reload"><i className="fa fa-rotate-right" /></button> */}
    <div className="time-fetched">Last Refreshed: {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
    {isSuperUser && (
      <div className="show-inactive-toggle">
        <select
          value={
            includeOOS ? 'all' : includeInactive ? 'includeInactive' : 'active'
          }
          onChange={(e) => {
            if (e.target.value === 'active') {
              toggleIncludeInactive(false)
              toggleIncludeOOS(false)
            } else if (e.target.value === 'includeInactive') {
              toggleIncludeInactive(true)
              toggleIncludeOOS(false)
            } else if (e.target.value === 'all') {
              toggleIncludeOOS(true)
              toggleIncludeInactive(false)
            }
          }}
        >
          <option value="active">Active Devices</option>
          <option value="includeInactive">Inactive Devices</option>
          <option value="all">Decomissioned Devices</option>
        </select>
      </div>
    )}
    {/* {(isSuperUser || isManager) && (
      <div className="show-inactive-toggle">
        <input
          type="checkbox"
          checked={includeInactive}
          onChange={toggleIncludeInactive}
        />
        <span className="inactive-label">Show Inactive</span>
      </div>
    )}
    {isSuperUser && (
      <div className="show-inactive-toggle">
        <input
          type="checkbox"
          checked={includeOOS}
          onChange={toggleIncludeOOS}
        />
        <span className="inactive-label">Show Out of Service</span>
      </div>
    )} */}

    <div className="view-selector">
      <div
        className={
          viewType === 'icons' ? 'view-option selected' : 'view-option'
        }
        onClick={() => {
          setViewType('icons')
          route('/directory')
        }}
      >
        <i className="fa fa-th-large" />
        {' Icons'}
      </div>
      <div
        className={viewType === 'list' ? 'view-option selected' : 'view-option'}
        onClick={() => {
          setViewType('list')
          route('/directory')
        }}
      >
        <i className={`fa fa-bars`} />
        {' List'}
      </div>
      <div
        className={viewType === 'map' ? 'view-option selected' : 'view-option'}
        onClick={() => {
          setViewType('map')
          route('/directory')
        }}
      >
        <i className={`fa fa-map-marker`} />
        {' Map'}
      </div>
    </div>
  </div>
)

const mapStateToProps = (state) => ({
  path: state.directory.path,
  companyID: state.directory.companyID,
  includeInactive: state.directory.includeInactive,
  includeOOS: state.directory.includeOOS,
  viewType: state.directory.viewType
})
const mapDispatchToProps = (dispatch) => ({
  setDirectoryPath: (path) => dispatch(setDirectoryPath(path)),
  toggleIncludeInactive: (bool) => dispatch(toggleIncludeInactive(bool)),
  toggleIncludeOOS: (bool) => dispatch(toggleIncludeOOS(bool)),
  setViewType: (type) => dispatch(setViewType(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs)
