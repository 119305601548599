import React, {Component} from 'react'
import {connect} from 'react-redux'
import {closeMenu} from 'store/actions'

export const getPath = () => window.location.hash.slice(1)
export const route = path => (window.location.hash = '#' + path)
export const linkHandler = path => e => {
  e.preventDefault()
  e.stopPropagation()
  route(path)
}
export const Link = ({children, path}) => {
  if (!children) return null
  if (typeof children === 'string' || typeof children === 'number')
    return <a href={'#' + path}>{children}</a>
  return React.cloneElement(children, {onClick: linkHandler(path)})
}
const matchRoute = (route, path) => {
  let r = 0,
    p = 0,
    key = '',
    params = {},
    isInteger = false
  const rLen = route.length,
    pLen = path.length

  while (r < rLen && p < pLen) {
    if (route[r] === ':') {
      r++
      if (route.substr(r, 3) === '(#)') {
        isInteger = true
        r += 3
      } else {
        isInteger = false
      }

      for (key = ''; r < rLen && route[r] !== '/' && route[r] !== '?'; r++)
        key += route[r]

      for (params[key] = ''; p < pLen && !['/', '?'].includes(path[p]); p++)
        params[key] += path[p]

      if (isInteger) params[key] *= 1
    } else {
      if (route[r++] !== path[p++]) return false
    }
  }
  if (p < pLen || r < rLen) return false
  return params
}

class Router extends Component {
  state = {path: getPath()}
  componentDidMount = () =>
    window.addEventListener('hashchange', this.onPathChange)
  componentWillUnmount = () =>
    window.removeEventListener('hashchange', this.onPathChange)
  onPathChange = () => {
    this.setState({path: window.location.hash.slice(1)})

    // comment this out because we want the side bar menu to stay toggled and not appear again on route change
    // this.props.closeMenu()
  }
  render() {
    const {side, content, tab} = Object.keys(this.props.routes).reduce(
      (def, route) => {
        const props = matchRoute(route, this.state.path)
        if (props)
          return {
            tab: this.props.routes[route].tab,
            side: React.createElement(this.props.routes[route].side, props),
            content: React.createElement(
              this.props.routes[route].content,
              props
            )
          }
        else return def
      },
      {side: null, content: null, tab: null}
    )
    return this.props.children({
      path: this.state.path,
      side,
      content,
      tab
    })
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  closeMenu: () => dispatch(closeMenu())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router)
