import React from 'react'
import {dateTime as formatDateTime} from 'utils/format'
import {dateTimeWithSeconds as formatDateTimeWithSeconds} from 'utils/format'
import findClosestPoint from 'utils/findClosestPoint'
import downloadCSV from 'utils/downloadCSV'
import List from 'Components/List'
import Loading from 'Components/Loading'
import {Menu, MenuItem} from 'Components/Menu2'
import {max} from 'lodash'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.module.css'

import './Table.scss'

const getDataFormatted = (data = [], Tag) => {
  const arr = data.map((value, index) => {
    if (index === 0) {
      return formatDateTimeWithSeconds(value)
    } else {
      if (value !== null && typeof value === 'number') {
        return Tag.display.render(value).replace('psi', '')
      } else if (value !== null && typeof value === 'object') {
        let fourierString = ''
        value.forEach((bin, i) => {
          i + 1 < value.length
            ? (fourierString += `${bin.toFixed(3)}, `)
            : (fourierString += `${bin.toFixed(3)}`)
        })
        return fourierString
      } else if (
        value === null &&
        Tag.fourierData &&
        Tag.fourierData.length > 0
      ) {
        let length = Tag.fourierData[0].length
        let fourierString = ''
        for (let i = 0; i < length - 5; i++) {
          if ((i = length - 6)) {
            fourierString += null
          } else {
            fourierString += null + ', '
          }
        }
        return fourierString
      } else {
        return value
      }
    }
  })

  // if it's a vibration sensor with fourier data, and there is a bin set for this reading, push the max amplitude
  if (Tag.fourierData && data[3] && data[3].length) {
    arr.push(max(data[3]).toFixed(3))
  }

  return arr
}

class DataTable extends React.Component {
  state = {
    combinedData: [],
    reversedAdditionalData: [],
    blockedTags: [],
    showDateRangePicker: false,
    startDate: new Date(),
    endDate: new Date()
  }

  componentDidMount() {
    if (
      this.state.reversedAdditionalData.length === 0 &&
      this.state.blockedTags.length === 0
    ) {
      const {Tag, additionalTags} = this.props
      const fourierData =
        Tag.fourierData && Tag.fourierData.length > 0 ? Tag.fourierData : null
      let combinedData = Tag.data.length
        ? Tag.data
            .map((row) => {
              return [row[0], row[1]]
            })
            .concat()
            .reverse()
        : [[]]
      let reversedAdditionalData = []
      reversedAdditionalData = additionalTags.map((aTag) => {
        return {
          tag: aTag.tag,
          data: aTag.data.concat().reverse()
        }
      })
      let reversedFourierData = []
      if (!!fourierData) {
        reversedFourierData = fourierData
          .map((row) => {
            let binRow = row.filter((r, i) => {
              return i === 0 || (i > 3 && i < row.length - 1)
            })
            return binRow
          })
          .concat()
          .reverse()
      }
      if (combinedData[0]?.length < reversedAdditionalData?.length + 2) {
        reversedAdditionalData.forEach((aTagSet) => {
          combinedData.forEach((dataNode, index) => {
            if (aTagSet.data[index] !== undefined) {
              let value = null
              if (
                aTagSet.tag === 'temp' ||
                aTagSet.tag === 'ttemp' ||
                aTagSet.tag === 'htemp'
              ) {
                value = aTagSet.data[index][1].toFixed(2)
              } else if (
                aTagSet.tag === 'psr' ||
                aTagSet.tag === 'pdiff' ||
                aTagSet.tag === 'hpres' ||
                aTagSet.tag === 'tpres'
              ) {
                value = aTagSet.data[index][1].toFixed(3)
              } else {
                value = aTagSet.data[index][1]
              }
              dataNode.push(value)
            } else {
              dataNode.push(null)
            }
            if (!!fourierData && !!reversedFourierData) {
              let fourierRow = null
              fourierRow = reversedFourierData.find((r) => dataNode[0] === r[0])
              if (!!fourierRow) {
                dataNode.push(fourierRow.slice(1))
              } else {
                dataNode.push(null)
              }
            }
          })
        })
      }
      this.setState({
        combinedData: combinedData,
        reversedAdditionalData: reversedAdditionalData
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.device !== this.props.device && this.props.device) {
      const {Tag, additionalTags} = this.props
      let combinedData = Tag.data
        .map((row) => [row[0], row[1]])
        .concat()
        .reverse()
      let reversedAdditionalData = []
      reversedAdditionalData = additionalTags.map((aTag) => {
        return {
          tag: aTag.tag,
          data: aTag.data.concat().reverse()
        }
      })
      if (combinedData[0]?.length < reversedAdditionalData?.length + 2) {
        reversedAdditionalData.forEach((aTagSet) => {
          combinedData.forEach((dataNode, index) => {
            if (aTagSet.data[index] !== undefined) {
              dataNode.push(aTagSet.data[index][1])
            } else {
              dataNode.push(null)
            }
          })
        })
      }
      this.setState({
        combinedData: combinedData,
        reversedAdditionalData: reversedAdditionalData
      })
    }
  }

  downloadData = (
    {Tag, device, commentsMap, combinedData},
    startTimestamp,
    endTimestamp
  ) => {
    downloadCSV(
      `${device.name} - ${Tag.label}`,
      combinedData
        .filter((r) => r[0] > startTimestamp && r[0] < endTimestamp)
        .map((r) => [...getDataFormatted(r, Tag), commentsMap[r[0]] || '']),
      Tag.fourierData && Tag.fourierData.length > 0
        ? [
            'Date',
            `Reading (${Tag.display.label})`,
            ...this.state.reversedAdditionalData.map((dataSet) => dataSet.tag),
            'Fourier Bin Data',
            'Max Amplitude',
            'Comment'
          ]
        : [
            'Date',
            `Reading (${Tag.display.label})`,
            ...this.state.reversedAdditionalData.map((dataSet) => dataSet.tag),
            'Comment'
          ]
    )
  }

  readingAverages = (data) => {
    let average7total = 0
    let average7count = 0
    let average30total
    let average30count
    let i = data.length - 1
    while (i > 0 && data[i][0] >= Date.now() / 1000 - 7 * 24 * 60 * 60) {
      average7count++
      average7total += data[i][1]
      i--
    }
    average30count = average7count
    average30total = average7total
    while (i > 0 && data[i][0] >= Date.now() / 1000 - 30 * 24 * 60 * 60) {
      average30count++
      average30total += data[i][1]
      i--
    }
    return {
      '7day': average7total / average7count,
      '30day': average30total / average30count
    }
  }

  generateAdditionalItems = (r) => {
    let className =
      this.props.Tag.fourierData && this.props.Tag.fourierData.length > 0
        ? 'reading-center'
        : 'reading'
    let aItems = []
    for (let i = 2; i < r.length; i++) {
      if (r[i] !== null && typeof r[i] === 'number') {
        aItems.push(
          <div className={className} key={i}>
            {r[i].toFixed(2)}
          </div>
        )
      } else if (r[i] !== null && typeof r[i] === 'string') {
        aItems.push(
          <div className={className} key={i}>
            {r[i]}
          </div>
        )
      } else if (r[i] !== null && typeof r[i] === 'object') {
        aItems.push(<div className={className}>{max(r[i]).toFixed(3)}</div>)
        r[i].forEach((binValue, i2) => {
          aItems.push(
            <div className={className} key={`${i}-${i2}`}>
              {binValue.toFixed(3)}
            </div>
          )
        })
      } else if (
        this.props.Tag.fourierData &&
        this.props.Tag.fourierData.length > 0
      ) {
        this.props.Tag.fourierData[0].forEach((binValue, i2) => {
          if (i2 > 3 && i2 < this.props.Tag.fourierData[0].length - 1) {
            aItems.push(
              <div className={className} key={`${i}-${i2}`}>
                {null}
              </div>
            )
          }
        })
      } else {
        aItems.push(
          <div className={className} key={i}>
            {r[i]}
          </div>
        )
      }
    }
    return aItems
  }

  openDateRangePicker = () => {
    this.setState({showDateRangePicker: true})
  }

  closeDateRangePicker = () => {
    this.setState({showDateRangePicker: false})
  }

  handleDateChange = (dates) => {
    const [startDate, endDate] = dates
    this.setState({startDate, endDate})
  }

  applyDateRange = (Tag, device, commentsMap) => {
    this.closeDateRangePicker()
    const startTimestamp = this.state.startDate
      ? this.state.startDate.getTime() / 1000
      : null
    const endTimestamp = this.state.endDate
      ? this.state.endDate.getTime() / 1000
      : null



    this.downloadData(
      {
        Tag: this.props.Tag,
        device: this.props.device,
        commentsMap: commentsMap,
        combinedData: this.state.combinedData
      },
      startTimestamp,
      endTimestamp,
      7
    )
  }

  render() {
    const {Tag, device, additionalTags} = this.props

    if (!Tag.data.length) return <Loading />

    const averages = this.readingAverages(Tag.data)
    const commentsMap = (device.comments || []).reduce(
      (obj, c) => ({
        ...obj,
        [Tag.data[findClosestPoint(c.time, Tag.data)][0]]: c.comment
      }),
      {}
    )
    let className =
      Tag.fourierData && Tag.fourierData.length > 0
        ? 'reading-center'
        : 'reading'

    return (
      <div className="data-table">
        <div className="toolbar">
          {Tag.tag === 'psr' && (
            <div className="averages">
              <span className="label">7 Day Avg:</span>{' '}
              {Tag.display.render(averages['7day'])}
              <span className="label">30 Day Avg:</span>{' '}
              {Tag.display.render(averages['30day'])}
            </div>
          )}
          <div className="spacer" />
          <div>
            <Menu
              className="download-data-menu"
              width={150}
              left
              button={(onClick) => (
                <button onClick={onClick}>
                  <i className="fa fa-caret-down" />
                  &nbsp; Download Data
                </button>
              )}
            >
              <MenuItem
                onClick={() =>
                  this.downloadData(
                    {
                      Tag,
                      device,
                      commentsMap,
                      combinedData: this.state.combinedData
                    },
                    7 * 24 * 60 * 60,
                    Date.now() / 1000
                  )
                }
              >
                From the past week
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.downloadData(
                    {
                      Tag,
                      device,
                      commentsMap,
                      combinedData: this.state.combinedData
                    },
                    30 * 24 * 60 * 60,
                    Date.now() / 1000
                  )
                }
              >
                From the past 30 days
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.downloadData(
                    {
                      Tag,
                      device,
                      commentsMap,
                      combinedData: this.state.combinedData
                    },
                    90 * 24 * 60 * 60,
                    Date.now() / 1000
                  )
                }
              >
                From the past 90 days
              </MenuItem>
              <MenuItem onClick={this.openDateRangePicker}>
                Custom Date Range
              </MenuItem>
              <MenuItem
                onClick={() =>
                  this.downloadData(
                    {
                      Tag,
                      device,
                      commentsMap,
                      combinedData: this.state.combinedData
                    },
                    0,
                    Date.now() / 1000
                  )
                }
              >
                All data
              </MenuItem>
            </Menu>
            {this.state.showDateRangePicker && (
                <div className="date-range-overlay">
                  <div className="date-range-picker">
                    <DatePicker
                      onChange={this.handleDateChange}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      selectsRange
                      showMonthDropdown
                      showYearDropdown
                      inline
                    />
                    <button
                      onClick={() =>
                        this.applyDateRange(Tag, device, commentsMap)
                      }
                    >
                      Apply
                    </button>
                    <button onClick={this.closeDateRangePicker}>Cancel</button>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="table-container">
          <div className="row-header">
            <div className="time">Date</div>
            <div className={className}>{Tag.display.yAxisLabel}</div>
            {additionalTags.map((aTag) => {
              if (Tag.tag === 'puls') {
                //prevent additional values from appearing
                return null
              } else if (!this.state.blockedTags.includes(aTag.tag)) {
                return (
                  <div key={aTag.tag} className={className}>
                    {aTag.display.yAxisLabel}
                  </div>
                )
              } else {
                return null
              }
            })}
            {Tag.fourierData && Tag.fourierData.length > 0 ? (
              <div className={className}>{`Max Amplitude`}</div>
            ) : null}
            {Tag.fourierData && Tag.fourierData.length > 0
              ? Tag.fourierData[0].map((r, i) => {
                  if (i > 3 && i < Tag.fourierData[0].length - 1) {
                    return (
                      <div key={`fourierBin${i - 3}`} className={className}>
                        {`Bin ${i - 3}`}
                      </div>
                    )
                  } else {
                    return null
                  }
                })
              : null}
            <div className="comment">Comment</div>
          </div>
          <List
            itemHeight={30}
            items={this.state.combinedData}
            renderItem={(r) => (
              <div className="row" key={r[0]}>
                <div className="time">{formatDateTime(r[0])}</div>
                <div className={className}>{Tag.display.render(r[1])}</div>
                {Tag.tag !== 'puls' ? this.generateAdditionalItems(r) : null}
                <div className="comment">{commentsMap[r[0]] || ''}</div>
              </div>
            )}
          />
        </div>
      </div>
    )
  }
}

export default DataTable
