import React, {Component} from 'react'
import {Input, Select} from 'Components/Form'
import {dateTime as formatDateTime, name as formatName} from 'utils/format'
import {graphql, Mutation} from 'react-apollo'
import {gql} from 'graphql.macro'
import update from 'immutability-helper'
import Tooltip from 'Components/Tooltip'

import './Alarms.scss'

const getInitialState = ({Tag, me}) =>
  me.isDemo
    ? {
        actions: (Tag.alarms.actions || [])
          .filter((a) => (a.user ? a.user.id === me.id : false))
          .map(({delay, action, user}) => ({
            delay,
            action,
            userID: user ? user.id : null,
            selectGroups: false
          })),
        rules: (Tag.alarms.rules || [])
          .map(({name, symbol, value, desc, sent}) => ({
            name,
            symbol,
            value,
            desc,
            sent
          })),
        lowWarning: Tag.alarms.lowWarning,
        highWarning: Tag.alarms.highWarning,
        criticalLow: Tag.alarms.criticalLow,
        criticalHigh: Tag.alarms.criticalHigh,
        unconvertedLowWarning: Tag.alarms.unconvertedLowWarning,
        unconvertedHighWarning: Tag.alarms.unconvertedHighWarning,
        unconvertedCriticalLow: Tag.alarms.unconvertedCriticalLow,
        unconvertedCriticalHigh: Tag.alarms.unconvertedCriticalHigh,
        editing: false
      }
    : {
        actions: (Tag.alarms.actions || []).map(({delay, action, user}) => ({
          delay,
          action,
          userID: user ? user.id : null,
          selectGroups: false
        })),
        rules: (Tag.alarms.rules || []).map(({name, symbol, value, desc,sent}) => ({
          name,
          symbol,
          value,
          desc,
          sent
        })),
        lowWarning: Tag.alarms.lowWarning,
        highWarning: Tag.alarms.highWarning,
        criticalLow: Tag.alarms.criticalLow,
        criticalHigh: Tag.alarms.criticalHigh,
        unconvertedLowWarning: Tag.alarms.unconvertedLowWarning,
        unconvertedHighWarning: Tag.alarms.unconvertedHighWarning,
        unconvertedCriticalLow: Tag.alarms.unconvertedCriticalLow,
        unconvertedCriticalHigh: Tag.alarms.unconvertedCriticalHigh,
        editing: false,
        RemoveOrUpdate: null
      }

export const UPDATE_ALARMS = gql`
  mutation UPDATE_ALARMS(
    $deviceID: Int!
    $tag: String!
    $lowWarning: Float
    $highWarning: Float
    $criticalLow: Float
    $criticalHigh: Float
    $actions: [AlarmActionType]
    $rules: [RuleActionType]
    $removeOrUpdate: Int
  ) {
    updateAlarms(
      deviceID: $deviceID
      tag: $tag
      lowWarning: $lowWarning
      highWarning: $highWarning
      criticalLow: $criticalLow
      criticalHigh: $criticalHigh
      actions: $actions
      rules: $rules
      removeOrUpdate: $removeOrUpdate
    ) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          alarms {
            lowWarning
            highWarning
            unconvertedHighWarning
            unconvertedLowWarning
            criticalLow
            criticalHigh
            unconvertedCriticalLow
            unconvertedCriticalHigh
            actions {
              delay
              user {
                id
                firstName
                lastName
              }
              action
            }
            rules {
              name
            }
          }
        }
      }
    }
  }
`

const TEST_ALARM = gql`
  mutation TEST_ALARM($deviceID: Int!, $tag: String!) {
    testAlarm(deviceID: $deviceID, tag: $tag) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          alarms {
            flag
          }
        }
      }
    }
  }
`

class Alarms extends Component {
  state = getInitialState(this.props)
  componentDidUpdate(prevProps) {
    if (prevProps.Tag !== this.props.Tag)
      this.setState(getInitialState(this.props))
  }

  renderHistory = () => {
    const {me, Tag} = this.props
    if (me.isDemo && Tag.alarms.log && Tag.alarms.log.length) {
      let personalHistory = (Tag.alarms.log || []).filter((l) =>
        l.user ? l.user.id === me.id : false
      ).length
      return personalHistory > 0 ? (
        (Tag.alarms.log || [])
          .filter((l) => (l.user ? l.user.id === me.id : false))
          .map((l, i) => <LogEntry key={i} entry={l} />)
      ) : (
        <tr>
          <td colSpan="2" className="center-text">
            <em>This alarm has no events in its history.</em>
          </td>
        </tr>
      )
    } else if (Tag.alarms.log && Tag.alarms.log.length) {
      return (Tag.alarms.log || []).map((l, i) => (
        <LogEntry key={i} entry={l} />
      ))
    } else {
      return (
        <tr>
          <td colSpan="2" className="center-text">
            <em>This alarm has no events in its history.</em>
          </td>
        </tr>
      )
    }
  }

  render() {
    const groupIds = this.props.device.groups.map((g) => g.id)
    const {Tag, me} = this.props
    //this is probably where I check for if an alarm value is null (-1 or that ridiculously large number)
    const alarmLowWarningSetting =
      this.props.device.settings.find((s) => s.key === 'alarm_low_warning') ||
      null
    const alarmHighWarningSetting =
      this.props.device.settings.find((s) => s.key === 'alarm_high_warning') ||
      null
    // const alarmCriticalLowSetting =
    //   this.props.device.settings.find((s) => s.key === 'alarm_critical_low') ||
    //   null
    // const alarmCriticalHighSetting =
    //   this.props.device.settings.find((s) => s.key === 'alarm_critical_high') ||
    //   null
    const convertSetting = (setting) => {
      return setting.reportedValue * this.props.device.defaultTagScale
    }
    const isOnDefaultTag = this.props.Tag.tag === this.props.device.defaultTag
    const validFirmware =
      this.props.device.firmwareVersion >= 167 &&
      this.props.device.firmwareVersion <= 199
    const validFirmwareNewAlarm = 
    this.props.device.firmwareVersion >= 25095
    const canEdit = me.isSuperUser || me.isManager || me.canEdit
    const compareValues = (dbVal, stateVal) => {
      if (!!dbVal && !!stateVal) {
        return convertSetting(dbVal).toFixed(2) === stateVal.toFixed(2)
      } else {
        return false
      }
    }

    const compareSettings = (stateSetting, dbSetting) => {
      if (!validFirmware) {
        return null
      }
      if (!isOnDefaultTag) {
        return 'This sensor type is not connected to the device.'
      }
      if (stateSetting === null && !this.state.editing) {
        return 'N/A'
      }
      if (!this.state.editing) {
        return !compareValues(dbSetting, stateSetting) ? (
          <Tooltip iconClassName="fa fa-cloud-upload pending" right>
            The latest update has not reached the device yet.
          </Tooltip>
        ) : (
          formatDateTime(dbSetting.reportedTime)
        )
      }
    }
    return (
      <div
        className="tag-alarms flex-grid"
        style={{height: '-webkit-fill-available'}}
      >
        <div
          className="tag-alarms-limits box"
          style={{overflowY: 'scroll', height: '95%'}}
        >
          <div className="title">
            <span className="left"> Alarm Settings </span>
            {this.state.editing && (canEdit || me.isDemo) ? (
              <span className="right">
                <button
                  onClick={() => this.setState(getInitialState(this.props))}
                >
                  Cancel
                </button>{' '}
                <button
                  onClick={() => {
                    this.props.updateAlarms({
                      variables: {
                        deviceID: this.props.device.id,
                        tag: Tag.tag,
                        low: null,
                        high: null,
                        lowWarning: null,
                        highWarning: null,
                        criticalLow: null,
                        criticalHigh: null,
                        //since the 'remove all alarms' function also uses update alarms
                        //we need a temp value to know when to remove alarms
                        //for the new system
                        removeOrUpdate: 1
                      }
                    })
                    this.setState(getInitialState(this.props))
                  }}
                >
                  Remove All Alarms
                </button>{' '}
                <button
                  className="primary"
                  onClick={() => {
                    this.props.updateAlarms({
                      variables: {
                        deviceID: this.props.device.id,
                        tag: Tag.tag,
                        lowWarning: this.state.lowWarning,
                        highWarning: this.state.highWarning,
                        criticalLow: this.state.criticalLow,
                        criticalHigh: this.state.criticalHigh,
                        // filter out actions that haven't been completely filled out
                        actions: this.state.actions.filter(
                          (a) => a.userID && a.action
                        ),
                        rules: this.state.rules.filter(
                          (r) => r.name && r.symbol
                        )
                      }
                    })
                    this.setState(getInitialState(this.props))
                  }}
                >
                  Save Changes
                </button>
              </span>
            ) : (
              <span className="right">
                {canEdit || me.isDemo ? (
                  <Mutation
                    mutation={TEST_ALARM}
                    variables={{deviceID: Tag.deviceID, tag: Tag.tag}}
                  >
                    {(testAlarm) => (
                      <button onClick={testAlarm}>Test Alarm</button>
                    )}
                  </Mutation>
                ) : null}
                {canEdit || me.isDemo ? (
                  <button onClick={() => this.setState({editing: true})}>
                    Edit Alarm
                  </button>
                ) : null}
              </span>
            )}
          </div>
          {validFirmwareNewAlarm && (
          <table>
            <thead>
              <tr>
                <th colSpan={5}>Alarms</th>
              </tr>
            </thead>
            <tbody>
              {!this.state.editing && this.state.rules.length === 0 && (
                <tr>
                  <td colSpan="4" className="center-text">
                    <em>There are no alarms set up.</em>
                  </td>
                </tr>
              )}
              {(this.state.editing || this.state.rules.length >= 1) && (
                <tr style={{fontWeight: "bold", color: "blue"}}>
                  <td></td>
                  <td><i className="fa fa-caret-down" /> Alarm Name</td>
                  <td><i className="fa fa-caret-down" /> Rule</td>
                  <td><i className="fa fa-caret-down" /> Value ({Tag?.display?.label ? Tag.display.label : ''})</td>
                  <td><i className="fa fa-caret-down" /> Description (optional)</td>
                </tr>
              )}
              {this.state.rules.map((a, i) => (
                <tr key={i}>
                  <td>
                  {(!this.state.editing && this.state.rules[i].sent === 1) && (
                    <i className="fa-solid fa-triangle-exclamation" style={{color: 'red'}}></i> 
                  )}
                  {this.state.editing && (
                    <button
                      onClick={() =>
                        this.setState({
                          rules: update(this.state.rules, {
                            $splice: [[i, 1]]
                          })
                        })
                      }
                    >
                      x
                    </button>
                  )}
                  </td>
                  <td>
                    <Input
                      type="string"
                      fieldOnly
                      static={!this.state.editing}
                      value={this.state.rules[i].name}
                      onChange={(e) =>
                        this.setState({
                          rules: update(this.state.rules, {
                            [i]: {name: {$set: e.target.value}}
                          })
                        })
                      }
                    />
                  </td>
                  <td>
                    <Input
                      type="select"
                      fieldOnly
                      static={!this.state.editing}
                      value={this.state.rules[i].symbol}
                      onChange={(e) =>
                        this.setState({
                          rules: update(this.state.rules, {
                            [i]: {symbol: {$set: e.target.value}}
                          })
                        })
                      }
                      options={[
                        {label: 'greater than', value: 1},
                        {label: 'less than', value: -1},
                        // {label: 'rate of change', value: 2}
                      ]}
                    />
                  </td>
                  <td>
                    <Input
                      type={Tag.display.input || "float"}
                      fieldOnly
                      static={!this.state.editing}
                      value={this.state.rules[i].value}
                      onChange={(e) =>
                        this.setState({
                          rules: update(this.state.rules, {
                            [i]: {value: {$set: e.target.value}}
                          })
                        })
                      }
                      render={Tag.render}
                    />
                  </td>
                  <td>
                    <Input
                      type="string"
                      fieldOnly
                      static={!this.state.editing}
                      value={this.state.rules[i].desc}
                      onChange={(e) =>
                        this.setState({
                          rules: update(this.state.rules, {
                            [i]: {desc: {$set: e.target.value}}
                          })
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
              {this.state.editing && (
                <tr>
                  <td colSpan="5" className="center-text">
                    <button
                      className="primary"
                      onClick={() =>
                        this.setState({
                          rules: this.state.rules.concat({
                            name: '',
                            symbol: null,
                            value: null,
                            desc: ''
                          })
                        })
                      }
                    >
                      Add Alarm
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>)}
          <div className="extra-space" />
          <p>
            When an alarm is triggered, notifications will be sent to selected users/group below. <br></br>
            <br></br>
            <span style={{color: 'red'}}>If you cannot select any users, make sure the device is in a group and there are users in that group.</span>
          </p>
          <table>
            <thead>
              <tr>
                <th colSpan={5}>Notifications</th>
              </tr>
            </thead>
            <tbody>
              {!this.state.editing && this.state.actions.length === 0 && (
                <tr>
                  <td colSpan="4" className="center-text">
                    <em>There are no notifications set up.</em>
                  </td>
                </tr>
              )}
              {this.state.actions.map((a, i) => (
                <tr key={i}>
                  <td>
                    {this.state.editing && (
                      <button
                        onClick={() =>
                          this.setState({
                            actions: update(this.state.actions, {
                              $splice: [[i, 1]]
                            })
                          })
                        }
                      >
                        x
                      </button>
                    )}
                  </td>
                  <td>
                    <Input
                      type="select"
                      fieldOnly
                      static={!this.state.editing}
                      value={this.state.actions[i].delay}
                      onChange={(e) =>
                        this.setState({
                          actions: update(this.state.actions, {
                            [i]: {delay: {$set: e.target.value}}
                          })
                        })
                      }
                      options={[
                        0,
                        5 * 60,
                        15 * 60,
                        30 * 60,
                        60 * 60,
                        120 * 60
                      ].map((delay) => ({
                        label: secondsToTimespan(delay),
                        value: delay
                      }))}
                    />
                  </td>
                  <td>
                    <Input
                      type="select"
                      fieldOnly
                      static={!this.state.editing}
                      value={this.state.actions[i].action}
                      onChange={(e) =>
                        this.setState({
                          actions: update(this.state.actions, {
                            [i]: {action: {$set: e.target.value}}
                          })
                        })
                      }
                      options={[
                        {label: 'text', value: 'sms'},
                        // {label: 'call', value: 'call'}, // NOTE: eventually we will uncomment this when calls are working
                        {label: 'email', value: 'email'}
                      ]}
                    />
                  </td>
                  {!this.state.actions[i].selectGroups ? (
                    <td>
                      {me.isDemo ? (
                        <Select
                          options={[{label: formatName(me), value: me.id}]}
                          fieldOnly
                          static={!this.state.editing}
                          onChange={(e) =>
                            this.setState({
                              actions: update(this.state.actions, {
                                [i]: {userID: {$set: e.target.value}}
                              })
                            })
                          }
                          value={this.state.actions[i].userID}
                        />
                      ) : (
                        <Input
                          type="groupUsers"
                          fieldOnly
                          static={!this.state.editing}
                          onChange={(e) =>
                            this.setState({
                              actions: update(this.state.actions, {
                                [i]: {userID: {$set: e.target.value}}
                              })
                            })
                          }
                          groupIds={groupIds}
                          value={this.state.actions[i].userID}
                        />
                      )}
                    </td>
                  ) : (
                    <td>
                      <Input
                        type="group"
                        fieldOnly
                        static={!this.state.editing}
                        filter={(g) => {
                          return groupIds.includes(g.id)
                        }}
                        onChange={(e) =>
                          this.setState({
                            actions: update(this.state.actions, {
                              [i]: {userID: {$set: e.target.value}}
                            })
                          })
                        }
                        value={this.state.actions[i].userID}
                      />
                    </td>
                  )}
                  <td>
                    {this.state.editing && !me.isDemo && (
                      <Input
                        type="checkbox"
                        value={this.state.actions[i].selectGroups}
                        label="Select from groups"
                        options={[
                          {label: 'Yes', value: true},
                          {label: 'No', value: false}
                        ]}
                        onChange={(e) => {
                          this.setState({
                            actions: update(this.state.actions, {
                              [i]: {
                                userID: {$set: null},
                                selectGroups: {
                                  $set: !this.state.actions[i].selectGroups
                                }
                              }
                            })
                          })
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
              {this.state.editing && (
                <tr>
                  <td colSpan="5" className="center-text">
                    <button
                      onClick={() =>
                        this.setState({
                          actions: this.state.actions.concat({
                            delay: 0,
                            action: 'sms',
                            userID: null,
                            selectGroups: false
                          })
                        })
                      }
                    >
                      Add Notification
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        <div className="extra-space" />
          <p style={{color: 'red'}}>
            Below is the old alarm system for backwards compability. 
            If you previously had an alarm it will still work,
            however you should switch if your device has the newest firmware update and you can see the new alarm system.
            The old alarm system will no longer receive updates.<br></br>
          </p>
          <table>
            <thead>
              <tr>
                <th colSpan={5}>Alarms (Legacy)</th>
                {/* {this.state.editing || !validFirmware ? (
                  <td></td>
                ) : (
                  <td>
                    Last Update to Device
                    <Tooltip left={true}>
                      Alarm settings are sent to the device to be handled by
                      device firmware. This column shows if and when the device
                      last received settings.
                    </Tooltip>
                  </td>
                )} */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{width: '50%'}}>
                  <Input
                    type={Tag.display.input || 'float'}
                    label={
                      <span>
                        <i className="fa fa-caret-up" /> Critical High
                      </span>
                    }
                    static={!this.state.editing}
                    onChange={(e) =>
                      this.setState({criticalHigh: e.target.value})
                    }
                    value={this.state.criticalHigh}
                    render={Tag.render}
                    className="high-low-settings"
                  />
                </td>
                <td style={{width: '50%'}}>
                  {/* {compareSettings(
                    this.state.unconvertedCriticalHigh,
                    alarmCriticalHighSetting
                  )} */}
                  {this.state.editing ? null : (
                    <div>
                      N/A
                      <Tooltip>
                        Critical alarm values are temporarily unavailable at the
                        device level.
                        <br />
                        <br />
                        They are still handled by the web app.
                      </Tooltip>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{width: '50%'}}>
                  <Input
                    type={Tag.display.input || 'float'}
                    label={
                      <span>
                        <i className="fa fa-caret-up" /> High Warning
                      </span>
                    }
                    static={!this.state.editing}
                    onChange={(e) =>
                      this.setState({highWarning: e.target.value})
                    }
                    value={this.state.highWarning}
                    render={Tag.render}
                    className="high-low-settings"
                  />
                </td>
                <td style={{width: '50%'}}>
                  {compareSettings(
                    this.state.unconvertedHighWarning,
                    alarmHighWarningSetting
                  )}
                </td>
              </tr>
              <tr>
                <td style={{width: '50%'}}>
                  <Input
                    type={Tag.display.input || 'float'}
                    label={
                      <span>
                        <i className="fa fa-caret-down" /> Low Warning
                      </span>
                    }
                    static={!this.state.editing}
                    onChange={(e) =>
                      this.setState({lowWarning: e.target.value})
                    }
                    value={this.state.lowWarning}
                    render={Tag.render}
                  />
                </td>
                <td style={{width: '50%'}}>
                  {compareSettings(
                    this.state.unconvertedLowWarning,
                    alarmLowWarningSetting
                  )}
                </td>
              </tr>
              <tr>
                <td style={{width: '50%'}}>
                  <Input
                    type={Tag.display.input || 'float'}
                    label={
                      <span>
                        <i className="fa fa-caret-up" /> Critical Low
                      </span>
                    }
                    static={!this.state.editing}
                    onChange={(e) =>
                      this.setState({criticalLow: e.target.value})
                    }
                    value={this.state.criticalLow}
                    render={Tag.render}
                    className="high-low-settings"
                  />
                </td>
                <td style={{width: '50%'}}>
                  {/* {compareSettings(
                    this.state.unconvertedCriticalLow,
                    alarmCriticalLowSetting
                  )} */}
                  {this.state.editing ? null : (
                    <div>
                      N/A
                      <Tooltip>
                        Critical alarm values are temporarily unavailable at the
                        device level.
                        <br />
                        <br />
                        They are still handled by the web app.
                      </Tooltip>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className="alarm-log"
          style={{overflowY: 'scroll', height: '95%'}}
        >
          <div className="title">Alarm History</div>
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Entry</th>
              </tr>
            </thead>
            <tbody>{this.renderHistory()}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

const secondsToTimespan = (seconds) => {
  if (seconds === 0) return 'Immediately'
  if (seconds < 60) return `After ${seconds} secs`
  if (seconds < 60 * 60) return `After ${seconds / 60} min`
  else return `After ${seconds / (60 * 60)} hours`
}

const LogEntry = ({entry}) => {
  let text
  switch (entry.action) {
    case 'ack':
      text = `${formatName(entry.user, {
        lastFirst: false
      })} acknowledged the alarm.`
      break
    case 'email':
      text = `${formatName(entry.user, {lastFirst: false})} received an email.`
      break
    case 'sms':
      text = `${formatName(entry.user, {
        lastFirst: false
      })} received a text message.`
      break
    case 'call':
      text = `${formatName(entry.user, {lastFirst: false})} received a call.`
      break
    case 'edit':
      text = `${formatName(entry.user, {
        lastFirst: false
      })} changed an alarm rule.`
      break
    case 'del':
      text = `${formatName(entry.user, {
        lastFirst: false
      })} deleted alarms.`
      break
    case 'critical_high':
      text = `The sensor triggered a critical high alarm.`
      break
    case 'high_warning':
      text = `The sensor triggered a high warning.`
      break
    case 'critical_low':
      text = `The sensor triggered a critical low alarm.`
      break
    case 'low_warning':
      text = `The sensor triggered a low warning.`
      break
    case 'norm':
      text = `The sensor returned to normal.`
      break
    default:
      text = `ERROR.`
  }
  return (
    <tr>
      <td>{formatDateTime(entry.time)}</td>
      <td>{text}</td>
    </tr>
  )
}

export default graphql(UPDATE_ALARMS, {
  name: 'updateAlarms'
})(Alarms)