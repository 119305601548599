import React from 'react'

const Main = () => {

    return (
      <div className='directory-body'>
        <i className="missing-icon fa fa-warning fa-5x" aria-hidden="true"></i>
        <h1 className="empty-text">Experimental Features</h1>
        <p className="empty-desc">Routes on this page are considered experimental.<br />Only use if you know what you are doing and have read all tooltips properly.<br />Contact Sensorfield if you have any issues or concerns.</p>
      </div>
    )
  

}

export default Main
