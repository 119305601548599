import React, {Component} from 'react'
import {Input} from 'Components/Form'
import {gql} from 'graphql.macro'
import {Query} from 'react-apollo'
import generateStickerZPL from 'utils/generateStickerZPL'

const GET_DEVICE = gql`
  query GET_DEVICE($deviceID: Int!) {
    deviceRe(id: $deviceID) {
      id
      pin
      model
    }
  }
`

class Stickers extends Component {
  state = {temp: null, deviceID: '', printer: null, printers: [], dpi: 300}
  async componentDidMount() {
    try{
      const res = await fetch(`http://localhost:9100/available`)
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json()
        const printers = (json && json.printer) || []
        const printer = printers[0] || null
        this.setState({printers, printer})
      }
    } catch (e) { console.log(e) }
  }
  printSticker = async ({id, pin, model}, dpi) => {
    await fetch('http://localhost:9100/write', {
      method: 'POST',
      body: JSON.stringify({
        device: this.state.printer,
        data: generateStickerZPL({id, pin, model, dpi})
      })
    })
  }
  handleIdChange = () => {
    if(!!this.state.temp) this.setState({deviceID: this.state.temp})
  }
  render() {
    return (
      <div
        className="grid"
        style={{gridTemplateColumns: '1fr 1fr', gridAutoRows: '600px'}}
      >
        <div className="box">
          <div>
            <p>
              Make sure the printer is connected and that the BrowserPrint
              software from Zebra is running on the computer.
            </p>
            <Input
              label="Printer"
              type="select"
              value={this.state.printer}
              onChange={(e) => this.setState({printer: e.target.value})}
              options={this.state.printers.map((p, i) => ({
                label: p.uid,
                value: p
              }))}
            />
            <Input
              label="DPI Selection"
              type="select"
              value={this.state.dpi}
              onChange={(e) => this.setState({dpi: e.target.value})}
              options={[
                {
                  label: '300 dpi',
                  value: 300
                },
                {label: '203 dpi', value: 203}
              ]}
            
            />
            <div style={{display: 'grid', gridTemplateColumns: '4fr 1fr', alignItems: 'center'}}>
              <Input
                label="Device ID"
                type="number"
                value={this.state.temp}
                onChange={(e) => this.setState({temp: e.target.value})}
              />
              <button style={{height: '33%', width: '50%', marginTop: '5px', marginLeft: '-80%', borderColor: 'green'}} onClick={this.handleIdChange}><i className="fa fa-magnifying-glass"></i></button>
            </div>
            {!!this.state.deviceID && (
            <Query
              query={GET_DEVICE}
              variables={{deviceID: this.state.deviceID}}
            >
              {({loading, error, data}) => (
                <div>
                  {loading ? (
                    'loading...'
                  ) : !(data && data.deviceRe) ? (
                    'device not found'
                  ) : (
                    <button
                      onClick={() =>
                        this.printSticker(data.deviceRe, this.state.dpi)
                      }
                    >
                      Print Label for {data.deviceRe.id}
                    </button>
                  )}
                </div>
              )}
            </Query>)}
          </div>
        </div>
      </div>
    )
  }
}

// const generateStickerZPL = ({id, pin, model, dpi}) => {
//   switch (dpi) {
//     case 300:
//       switch (model) {
//         case 'S1-U':
//           return `^
// XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR4,4~SD30^JUS^LRN^CI0^XZ
// ^XA
// ^MMT
// ^PW300
// ^LL0300
// ^LS0
// ^FO96,192^GFA,01536,01536,00024,:Z64:
// eJztkTGLE0EUx984A3PFspuARQKLK+QLzJpmT+HWa/RrhGyRNkHLg7zLCrFxt4143X2JgM0Me3Ifw41biI0YrglcnPVN9ANYyNncq/78+M9v3jAA93MXc1zll+2jIw7Bpk1rfmnhqR8gQt/ITRuGEuS2jX7IzZ7ZKDhH6DXFSJ0cPQDvGJPHfoa8eLaqqL8VU/WQ+qKPHSVmwKC7+oSQfCxBPelx8KmvAo4chutv1H8vtcr++Gtpdhzi1Sn5P+S1ysgfbGyKvDnwmvrvYKrmzs+wq2EGW+KGeFko1b4lP8Ok9od4uxge+FIodXpx6HdGIga2jPvEe3lZK1M2O4+162nQINiodO89Zzo2n7/uBGs7ihmAM08y/N/f89fDzH6uX4pA/tx3pmfRPpIicDxo7LzOirS8tYmyqU3zIkXi4hWMYPImRiNVvORidk2ZuD/AEWRFoqs8GSy4VzeUiUvm+lchGNmZAfe0y46TH8ffU6xsMgDu1y67fW72c5y8ltrs1BC40C67A0Pyj09AVxfJAMnjMmGvS/4XodLGO/ifu+z2+d1P3B7ZkvxfKLt7b+xcT8JIGxkZy0V6TZk4p3302Pexyktcc89fUL67b7iffzS/AMG8wNQ=:E926
// ^FO96,32^GFA,01536,01536,00024,:Z64:
// eJztkbFKw1AUhv8k5VyHkMXBopFGAs5262Tq1MWHuJAh67046FC8l2Zw8QF8nFsKzSL4Ag7XVJzTrcM1mvgSBem3HPj5OJzDDxw48P/x3MhrSAHzCIVTy49WcOkYApeVO1IajxHyVpVNe8XTluBBVjFJg/UIs4FgY/MwlWyEAOb5yE9yLM5QU+IP9cSmwWmfhyBRgI5RkGA3Zj69YycIdn3OOaKx3jhVWnfZ7ffhfZsYTAjEYz1r1HLr7qd/92gdwk8STDJdT7qhU5u+dD7M7YAEh1DvRSzo3MhPGXY++DokbsH5ax1yGuqLr5x6Xz11f63ww9+qSK22RlZb1vsRrH8doLWbBWXa6mRTBot913NgT/wCsE9hvA==:B4D8
// ^FO0,0^GFA,02304,02304,00036,:Z64:
// eJztUrGO1DAUHMdW4iJsUqaIlMAXBF2TAm2cE4IGiV/Y3BekoKAArX05RRQooqfg+AskGt8iXYUEf4B1S71KmSqHE/bo6JBoMsWzpTcaz3tjYMGCv4H0x8sLuBCIiUmA9FEyoPbw0ptbwXDkHOBAomiGQsKMyUDGxIyBnlq+f9STYNAoL6JSQl8hJG5YtZGYenz1hzPpmI4bST82MqVOdtLk2cyB59Yx0e9/em4sIC6sKVIpWylOkIXzW5OFMTCfD8X5WqJ6Yzo496XUVvZBns46ns6exqUfXrHscaWR3Ot/gFXKchhOX4WzDh3SM254rtr0bGPn6vK95OleSaywb/N6Nhtvd6wOI8W210KgfMv083eb14r0EWlo8n3mrLY3fB1x1W1vjJ36q6+/fRjyRg05PafB5USJmGC+N+kIJjTEM6aFXSABBLN19sPd1OH00+THsX7MwTcGadOAXEa0ydN5dpad+qWfKZY9eWj33EaVxOYLhdUgbbT5nVex47eBUV2xW9/llY4O6Fjc5UV6j/l2z4rZU9vcdQxsYsu5Lfra+4c/bMGCBQv+F34BTt+ITA==:35D5
// ^FO128,224^GFA,00768,00768,00012,:Z64:
// eJztjrENhEAMBH0i+JAOoA0HC67rJQjo7OiAEkwHkBG8xB86W1wFL4J3tB6tdpfof8+8cC5AiPk5D+6DGv9ATFOhgw6QyfzasEy6Zd5A5qxJe8jqfEC3x83zeb3zsXv+AbydR2CM1kXMnfcS0Jr/0nXJfU/y196b+Mv3JF0VvLI9P7svt+hGNw==:557E
// ^FO0,224^GFA,00512,00512,00008,:Z64:
// eJxjYBgFqKCi/guYtrD/B+X/h/L/N4D5FewHwHwL5gMPwHzmgx8g/IMQPiOYBvIfQNQzQtUzovAr4PLMMPNQzQfa9wBiP1gj0D2MEP4HiEFUAQCI/iki:E180
// ^FO0,0^GFA,00768,00768,00012,:Z64:
// eJxjYBgFgx/Isx9gYChgMACx+duB7AcMFkjiEmB2/wMQGywu23qwgK2gwCIByObrPfzD/sE/C6Asg0zvcRmLj20SDiDx7nN8Fo/bweplIo+xFRT/B7N5fp7jK0iGsGUKj7AUFELMt/v9js/iM1R9IdCcwmNg83kqkcwvPPCBp6AAbP7QAADiQTF3:3AF5
// ^FO0,192^GFA,00512,00512,00008,:Z64:
// eJxjYBgFEHDw4AULEH2g+QOYPnjcoAbMb7LIAVKMB483+EDkHxyDqP8Apg80WPwD849J/gfRD5skm0F0PftHMM3H8Li5gZ4eIQkAAHoYHIg=:BEAB
// ^FO32,192^GFA,00256,00256,00008,:Z64:
// eJxjYBgwYAGlJdDEJedAaMt/UP4xKN0HpVug8m0QWrANuzxRAAA5cwb6:DBB9
// ^FO0,192^GFA,00256,00256,00008,:Z64:
// eJxjYBggwPjxA4MMiPHgAQMPiP4IpR8ufvDGAih/8fvHPzVA/m0l455DIDqhnPc/kD6l/IjnH5C+X14sewBIn1NT7jkGpI+lQ9QfUzYA6ScSAADv6CBZ:C9B5
// ^FO0,160^GFA,00768,00768,00012,:Z64:
// eJxjYBgFxAM7Pv7m5gc/wOw6ef72ww8/gNnJ1jP6nhk3QNiGAkfSDCHqkzfOOPPO+gGYLWM440yaIES9neGON2nGFlD1E3zSDCTA7Mc7Qeoh7GRBmTNuhRC2nT1//+PHEHtl2NiZGQ8k0NSfIwcAADfkJIo=:4638
// ^FO96,64^GFA,04480,04480,00028,:Z64:
// eJztls9rG0cUx9/siBkdFq1728AGLQn0WEZRKSoES1DIOX9ADpsEVtcRCvGldMcW2D4Yes0hkEP/AUMuhUIZV6C9hEDvhiw2JD30oOBSq2QT983sSt51m0NLKSn1w17N6sObNz++7z0BXNql/TPGoQERgAYu8Y1kVUaBFcwR5u0PrBgYRmpz0u7X393L5pPN6Y0m3ZxOavH6L9rDt3n66nQj8NLTF7rq15v14s93n8fdru/G3WaNdWZrwys/HpJxZ80jo3aNiW1xz1/s0pPOPp2ePKmzmRj6d57xjdEchiMfLrD4yt7z+LrI3Pj6hXgpxnubvjrpZF76+kK8yT7Gm0yeioxOnlL4c9Pv+d7MoP4e+wDsSxlAgne/BhxFo/kv0H6tZcHeiSYk2wACvDnwOV+A9yYrGNkRPow0gIQA/3o8wK/kbcuoQhbGU9iHHnzbBIcBUeGjggHGk/GQCJBkEQBHpg8OS4bxovgexhOgfWDGL9tdMh/kww1cZ+c3ZJwjG+2ds/DjG8ZvoXxy5CDr5JZxZuN9ZthVHXx0inNuj98t94DxXt5E5veU22MtZN0iD8iZuKKTWQBrAzwP3Hsuyc4pL86lJ3y1zpqAclGKKpqFZHPLWR3pwD6j8m2tdtyqxsIqIlXv888PzJZ6kUYvVKMw2nNdsqVeFkYvVHkL6GclW+qFPDZ64doNgJKSFfeHetkzenGU2wRKS8ZLvYCLesnZwHXx6s/9Cr00jV7cCBll1TmNXnyjF1ca1qjFW+rFO/4Z2bJY0EIvn+IVoQDaJ8h4e17uYbvUizZ6aQpcp+NlJSv1QnKjF194iwFdVYxSLyQ3eun1vCyj1WoysM+ofIugaur97L+hF5lgWUkJphAmOrQB4kSTs6BkCrxjUMiwG/U1xPh/9r1lt7HOBGOiTGGSRjlDOQB/w7JHoRsJiqzbw1aWbWXXwgh8m4DkULJMcgw1zpkEzbPY+LnWbzfZ0YLimR7vMVQMxXgK/Jb120umysxJpgETZPoKHuLCfdsLSB7Sgk2a6Dc9AhvPMvpOci2GuD/uYrzBEGKJ9XBoGYuY9segGHVZCNERxMbvvmGcJ88G7RPymJ8E/LXqpzBMUvATwxynvxvSCdn13jTJpmo5cK2/BX5QPdaGXVgxrndrwHQ4N+cCW6s4NP7SRf5LZusLXj2QObDoAVYXuWK2vgCsA82B3c/BW0RLZOsL7msM2InYGN0350tm85121XVoNT9xroHpKktm6wvva44F5isWI9uUFb8mtHqaIROWbUW1OYMO+gXrkg/NAmSN+UK54K6HNt52uGK2vgh9ExpMsmEDSLpaZ9GPhBbAWiG7j91oZ7Fitr50dOLTXLIRdiN1fi6mvpztq8SHLHTCRYjdtnqkA/Mo21C9Gy3rS2FhHZGqwwA+VDvQ+gHeo2np3vHcjM9tevRNbnLUMjteGUlHaUBMScXHFyStZhf9oeMAfZJFC9rXW6SWQ3w2ugXt9vxufic5SA9uVRmbCYYtDKK9DMgkY1XWMAyvLLqKTZCJKqM/zYQDvTbGm/enIKrx+NmvkjdMvMM7ScoEryat9zKkLfx9eXeX448sQVvVgOmc5Li1u/jgHMe1M1NglBU9wwJBivGlXdr/1H4HdZSgqg==:0327
// ^FO0,96^GFA,01152,01152,00012,:Z64:
// eJzt0r1qwzAQAOATHoSW6gVE/CKBvFjAgg5ZCnmFPkaHDoYOHfMKMi14CbW9aRBW5buTJejQqVsPDx+O7idnAfzHbyHjvPsU193RRctsVtCOrT00nt06ECGn9gAdu0uJJ06O27keKbbiXKgJlbd6kqaQPr9IP8/5IJubYZpY8wgp2H3t+UG4SKO9+dPFs99Dlx7yxSw3w76aYTKdpfcwTJBtnbbZzsme/TiPHzPbhnHw2efRnbPN4I7s62H4yvVvh6XYLBOfadNgY2CnmUdPS6T/SNY/3KJpfbW13XebjeuTaFplg6aVCXL+hqUNVMtVr1gOG6g7lsMG6hlL4I7VCxWK0YF6ouQYo1Wf6CbZ6ePeI1+m7Vgo1uUmppRisd/KFLEv7lw1eLnS0FYNZNUAqgZ/Fd+hGJ3F:4F5C
// ^FO0,32^GFA,01152,01152,00012,:Z64:
// eJztkjEOwyAMRR0xMLJ2qOQeoSdortIjdOyW5GYchSMwMiBcJ9gJqB07dKgF0hMC8/1tgH/8TiARWJqFC4wUlQeiLJwN8b3KyTFvD5BXBvLCY1hX5WkGF4ULgEmVB044ZOH1TNhEQ/CsbIMjf1EeKdR/wflJtYGbiZLyUozoBFwS6DmaCPedg9YLaA++Wb/7UL7Dbc7ur04Da3t80NzW0tXY1N56snl1ffdw87YIN563veh6xI3DIIzp6Gnb630GzpxQZ+PEW7Wt4WSWfjde3GiGag==:F960
// ^FT215,279^A0N,29,28^FH^FD${pin}^FS
// ^FT59,279^A0N,29,28^FH^FD${id}^FS
// ^FT97,50^A0N,21,21^FH^FD${model}^FS
// ^PQ1,0,1,Y^XZ
// `
//         case 'G1N-U':
//           return `CT~~CD,~CC^~CT~
// ^XA
// ~TA000
// ~JSN
// ^LT0
// ^MNW
// ^MTT
// ^PON
// ^PMN
// ^LH0,0
// ^JMA
// ^PR3,3
// ~SD30
// ^JUS
// ^LRN
// ^CI27
// ^PA0,1,1,0
// ^XZ
// ~DGR:G5410010.GRF,1488,16,gR0::::N01H5gG0M0BJFAY0L01LFY0K02IFABHFEX0K03HF4H01HFCW0K0HFEJ0BFEW0J01HFL0HFW0J0HFEL03FEV0I01HFCL01HFV0I03HF8M0HF8U0I07FCN07FCU0I0HFEN03FEU0H01HF8N01HFU0H03HF8O0HF8T0H07HFP07FCT0H0HFEP03FET0H07FCP03HFT0H0HF8P03HF8S001HF8P01HFT003HF8Q0HF8S001HFR07FCS003FER0HFES007FER07FCS007FER03FES007FCR03HFS00HFE0HF80FE80HF83FES007FC1HFC1HFC1HFC1HFS00HFE3HFE3HFE3HFE3HF8R00HFC1C1C3C1C3C3C1HFS00HF83C3E3C1E3C3E1HF8R00HF83C1C7C1E3C1C1HFS00HF83EH2782A3E203HF8R01HF81FH078H03EH01HFS00HF83F8078H03F801HF8R01HF81FC078H01FC01HF8R01HF80HF878HA0HF81HF8R01HF803F8787E03F01HFS00HF8H0FEF8FEH0FC0HF8R01HF8H07C785EH07C1HF8R00HF8203E783E203E1HF8R00HF8341E781E701C1HFS00HF83C1E381E383E0HF8R00HFC3C1C3C1C7C1C1HF8R00HFE3EBE3E3E3EBE1HF8R007FC1HFC1HFE1HFC1HFS00HFE0HF80HFE1HF80HF8R007FC054H0H54054H0HF8R003FEL02L0HF8R003HFS07FS003HFS0HF8R001HFS07F8R002HF8R03F8R0H07F8R01FS0H0HFCS0F8R0H07FCS078R0H03FES078R0H01HFS01S0I0HF8S08R0I07FCgL0I03FEgL0I01HFgL0J0HF8S0623CN0J07FCS043H4N0J03HF8R062E2N0J01HFCR0637O0K0BFE8Q06238N0K01HFDQ0610CN0L0BNFE8I0632EN0L017NFCI063H4N0N0OF8H03IEN0T05HFCI041O0T03IF8S0U07HF8S0U0IFES0U01HFES0U03IF8R0V07HFCR0V0BHFER0V01IFR0V02IF8Q0W07HFCQ0W03HFEQ0X07HFQ0X02HFAP0Y015Q0gR0::
// ~DGR:G5410004.GRF,96,4,N0:::71HFJ071HF8I071HFCI071C1CI071C1E38071C0E380:71C0EI0:::71C1CI071HFC38071HF838071FE0380N0::::
// ~DGR:G5410007.GRF,1536,32,iJ0:::7381C0F83HF81F01CH0EI01HF07HF1HFI01F03HFI07C01E01EH07N073C1C3FE3HF81F01CH0EI01HF87HF1HF8H07FC3HFH01HF07F87F807N073C1C7FE3HF81F01CH0EI01HFC7HF1HFCH0HFC3HFH03HF87387380FN073E1C70F03803B81CH0EI01C3C7H01C1CH0E1E38I03C3CE1CE1C3FN073F1C70703803B81CH0EI01C1C7H01C1CH0E0E38I07818E1CE1C7FN073B1C78H03803B81CH0EI01C3C7H01C1CH0FH038I07I0E1CE1CH7N073B9C3FH038071C1CH0EI01HFC7FE1HFCH07E03FEH07I0E1CE1C47N073H9C1FE038071C1CH0EI01HF87FE1HF8H03FC3FEH07I0E1CE1C07N0739DC07E0380E0E1CH0EI01HF07FE1FEJ0FC3FE3F7I0E1CE1C07N0738DCH0F0380HFE1CH0EI01CH07H01C7J01E3803F7I0E1CE1C07N0738FCE070380HFE1CH0EI01CH07H01C7801C0E3803F7818E1CE1C07N07387CF0F0381IF1CH0EI01CH07H01C3801E1E38I03C3CE1CE1C07N07387C7HF0381C071HF8HFC01CH07HF1C1CH0HFE38I03HF873873807N07383C7FE0381C071HF8HFC01CH07HF1C1CH0HFC38I01HF07F87F807N07381C1F803838039HF8HFC01CH07HF1C0EH03F038J07C01E01EH07N0iJ0::::::::J03E07HF1HFC039C0E07C1HFC7FC1C0E07C0HFE703F0381C0F8Q0J0HF87HF1HFC039E0E1HF1HFC7FE1C0E1HF0HFE70HFC3C1C3FEQ0I01HF87HF1HFC039E0E3HF1HFC7HF1C0E3HF8HFE71HFE3C1C7FEQ0I01C3C7H01CI039F0E38781C07071C0E3C3C0E071E1E3E1C70FQ0I01C1C7H01CI039F8E38381C07071C0E78180E073C0F3F1C707Q0I01EH07H01CI039D8E3CH01C07071C0E7J0E0738073B1C78R0J0FC07FE1HF8039DCE1F801C07HF1C0E7J0E0738073B9C3FR0J07F87FE1HF8039HCE0HF01C07FE1C0E7J0E0738073H9C1FEQ0J01F87FE1HF8039CHE03F01C07F81C0E7J0E07380739DC07EQ0K03C7H01CI039C6EH0781C071C1C0E7J0E07380738DCH0FQ0I0381C7H01CI039C7E70381C071E1C0E78180E073C0F38FCE07Q0I03C3C7H01CI039C3E78781C070E1E1E3C3C0E071E1E387CF0FQ0I01HFC7HF1HFC039C3E3HF81C07070HFC3HF80E071HFE387C7HFQ0I01HF87HF1HFC039C1E3HF01C07070HFC1HFH0E070HFC383C7FEQ0J07E07HF1HFC039C0E0FC01C070383FH07CH0E0703F0381C1F8Q0iJ0::::
// ~DGR:G5410003.GRF,456,24,hN0::7F83E03EH0CFEJ0F01CH0CHF0HF87C3FHC0C067F80CI07F8HF0HFH0CHF8H01F81CH0CHF8HF8FE3FCE0C067FC1CI06H0C38C380HC18H038C36H0HC1HC01C73H0F0C0H60E3CI0601CH1C1H0HC0C6030C36H0HC0HC018H3H0F0C0H60H6CI06018018I0HC0C6I0C36H0HC1HC01E03H0D8C0H60E4CI07F18018I0HC0CI01863H0CHF8HF8FC3F8IC067FC0CI07F18018I0HC0CI03863H0CFE0HF83E3F8IC067FH0CI06018018I0HC0CI0707FH0HC70CI073H0C6C0H6380CI0601CH1C1H0HC0CI0E0HFB0HC38C018H3H0C3D8H61C0CI06H0C38C380HC18H0180C1B9HC18C01C73H0C3DCE60C0CI06H0HF0HFH0CHF8603FHC19F8C1CHF8FE3H0C1CFC60E0CI06H03E03EH0CFE0603FD80CF0C0EHF87C3H0C0C786070CI0hN0:::
// ~DGR:G5410006.GRF,864,36,iR0:::07CU01FEI07H038I07E03E03EI01C0783HFH01F81F80F80FN01HFU03FEI07H038I0HF0HF0HFI03C1FE3HFH03FC3FC3FC3FCM03HFU038J07H038H01E78E78E78H03C1CEH07H079E79E39E39CM03878T038J07H038H01C39C21C2I07C387H0EH070E70E70870EM038383C1CF03F01F0H7FHE0F0707B8H01C39C01CJ07C38701CJ0EH0E7H070EM03CH0HF1HF87F87FC7HFHE3FC71HF8I0E71DE1DEI0DC38701CJ0EH0EH7870EM01F80E71E3CE1C71C7838E39C71C78I07E1HF1HFH019C387038I01C01C7FC70EM00HF1C39C1CEH0E0E7038E70E73838I07E1E71E7H019C1CF038I03C03C79C39EM003F1HF9C1CFC0E0E7038E7FE73838I0E71C39C39FB1C1HF0703F07807870E3FEM0H079HF9C1C7F8E0E7038E7FE73838H01C39C39C39FE1C0F70703F0FH0F070E1HEM07039C01C1C0FCE0E7038E7H073838H01C39C39C39JFH070703F1E01E070EH0EM07879C39C1C01CE0E7038E70E73838H01C39C39C3807HF1870EI01C01C070E30EM03HF8E71C1CE1C71C7038E39C71C78H01E78E70E7I01C3CE0EI038038039C79CM03HF0HF1C1C7F87FC7038E3FC71HF8I0HF0HF0HFI01C1FE0EI07FE7FE3FC3FCM00FC03C1C1C3F01F07038E0F0707B8I07E03C03CI01C0F80EI07FE7FE0F01FN0iR0::::
// ~DGR:G5410005.GRF,288,12,gJ0:::7C1FK01CI0EO07E3FK01CI0EO0:7637K01CI0EO0763707C03DC1E0E38M076371HF0HFC7F8E38M0J71C70E3C738E38M0J73839C1CE1CEO073673839C1CHFCEO0:73E73839C1CEH0EO073E73839C1CE1CEO073E71C70E3C738E38M071C71HF0HFC7F8E38M071C707C03DC1E0E38M0gJ0::::
// ~DGR:G5410002.GRF,152,8,V0::3C1E0F0783C3FI07E3F1F8FC7E3FI0C373B9DCHE73J0C361B0D86C36J0C361B0D86C37EI07E61B0D86C37FI07E61B0D86C3638H0C361B0D86C3018H0C361B0D86C3618H0C373B9DCHEH738H07E3F1F8FC7E3FI03C1E0F0783C1EI0V0:::
// ~DGR:G5410008.GRF,1824,24,hN0::0F8603I0FE0CI01EJ0FCJ07H01FCI0HFC18L03FC603I0HF8CI03FI03FEJ07H01HFI0HFC38L030E603I0C18J0718H0307J0D80183J0C038L0704603I0C0IC60618H070237H0D801818I0C078L06H0603I0C0IC6H018H06H03FH0D801818I0C0D8L06H0603I0C0IC6H03I06H038018C01818I0C0D8L06H0603I0C0HC6CH07I061F3H018C01818I0C198L06H0603I0C0HC6CH0EI061F3H01FC7D818I0C318L0704603I0C0HC6C01CI070H3H03FE7D818I0C3FCL030E603I0C18C3803J03073H03060183J0C3FCL03FC60H3H0HF8C3807F9803HF3H030601HF18H0C018L00F860H3H0FE0C3807F98H0FC3H060301FC18H0C018L0L01R08U08R0:L02Q01U01S0hN0:::0F8603I0HFJ078I03FJ0HC1FI07FE0CP03FC603I0HFJ0FCI0HF8I0HC7F8H07FE1CP030E603J06I01C6I0C1CI0HC61CI0601CP0704603J0C6F0186H01C08DC0HCE08I0603CP06H0603I01C7F8H06H018H0FC0ICK0606CP06H0603I018718H0CH018H0EH0ICK0606CP06H0603I03061801CH0187HCH0ICK060HCP06H0603I070618038H0187HCH0ICK0618CP0704603I06061807I01C0HCH0HCE08I061FEP030E603I0C06180CJ0C1HCH0HC61CI061FEP03FC60H301HF6181FE6H0HFHCH0HC7F8CH06H0CP00F860H301HF6181FE6H03F0CH0HC1F0CH06H0CP0L01P02S04V0:L02P04S08V0hN0:::H01878101FK01HF8L03FCFC101FT0H038FC7C7F8J01HF8L03FCFC7C7F8S0H039CE1061C02I018J04I018C01061CS0H0798628E080E7FC181F01CHF8318028E08S0H0D98H6HCH03C7FC183F878HF831F86HCU0H0D986H0CH0FJ018319EJ061FCH0CU001H986H0C01CJ01807B8J0618EH0CU07F1986H0CH0F07FC181F9E0HF86H06H0CU07HFD86H0E083C7FC1839878HF8E186H0E08S003FDCEH061C0EI0183181CI0C1CEH061CS0H018FCH07F802I0183F804I0C0FCH07F8S0H01878H01FL0181ECK0C078H01FT0hN0::::::K0707J06I018I03030F80EY0K0707J06I018I03031FC0EY0K078FJ06N030H38E1BY0K078F1F0761C019BC030H3061BY0K068B3F8FE3E019FE030H3C01BY0K06DB319CE63019C603031F8318X0K06DB079867F01986030307C318X0K06DB1F9867F019860303H0E3F8X0K067H3H98H6H01986030H3067FCX0K067H319CE7301986038738E60CX0K067H3F8FE3E0198601FE1FC60CX0K06231EC761C01986H0FC0F8C06X0hN0:::
// ~DGR:G5410009.GRF,352,8,V0:2JAH03FAL03IFC017HFCK03IF80KF8J03IF01HFDHFCJ03EHA03FA03FEJ03CI07FI01FJ03CI0FEJ0F8I03CH01FK07CI038H03EH02H03EI03CH07C0174018I03CH0F80IF808I03CH0701IFCK038H0F03IFEK03C01F07F01FK03C01E0FCH0EK03C01C07I04K03803E0FO03C01C1FO03EABE1EO03IFC1CO03IFE1EO03IFC1EO03EABE1EO03C01C1FO03803E0FO03C01C07O03C01E0FCH0EK03C01F07C05FK038H0F03IFEK03CH0701IFCK03CH0F80IF808I03CH07C01FC01CI038H03EH02H03EI03CH01FK07CI03CI0F8J0F8I03CI07CI05FJ038I03FA02FEJ038I017F57FCJ038J0KF8J0L01IFCK0M03FEL0V0
// ~DGR:G5410013.GRF,192,8,V0:::3FE1CE07N03HF1CF07N03HF9CF07N03879CF87N03839CFC71CL03879CEC71CL03HF9CHE71CL03HF1CE67N03FE1CEH7N03801CE37N03801CE3FN03801CE1FN03801CE1F1CL03801CE0F1CL03801CE071CL0V0::::
// ^XA
// ^MMT
// ^PW301
// ^LL300
// ^LS0
// ^FO0,114^XGR:G5410010.GRF,1,1^FS
// ^FO13,259^XGR:G5410004.GRF,1,1^FS
// ^FO65,208^XGR:G5410007.GRF,1,1^FS
// ^FO67,78^XGR:G5410003.GRF,1,1^FS
// ^FO1,21^XGR:G5410006.GRF,1,1^FS
// ^FO1,43^XGR:G5410005.GRF,1,1^FS
// ^FO5,190^XGR:G5410002.GRF,1,1^FS
// ^FO107,114^XGR:G5410008.GRF,1,1^FS
// ^FO2,66^XGR:G5410009.GRF,1,1^FS
// ^FPH,1^FT73,66^A0N,25,25^FH^CI28^FD${model}^FS^CI27
// ^FT39,282^A0N,29,28^FH^CI28^FD${id}^FS^CI27
// ^FO150,259^XGR:G5410013.GRF,1,1^FS
// ^FT192,282^A0N,29,28^FH^CI28^FD${pin}^FS^CI27
// ^PQ1,0,1,Y
// ^XZ
// ^XA^IDR:G5410013.GRF^FS^XZ
// ^XA^IDR:G5410009.GRF^FS^XZ
// ^XA^IDR:G5410008.GRF^FS^XZ
// ^XA^IDR:G5410002.GRF^FS^XZ
// ^XA^IDR:G5410005.GRF^FS^XZ
// ^XA^IDR:G5410006.GRF^FS^XZ
// ^XA^IDR:G5410003.GRF^FS^XZ
// ^XA^IDR:G5410007.GRF^FS^XZ
// ^XA^IDR:G5410004.GRF^FS^XZ
// ^XA^IDR:G5410010.GRF^FS^XZ
// `
//         case 'G1N':
//           return `CT~~CD,~CC^~CT~
// ^XA
// ~TA000
// ~JSN
// ^LT0
// ^MNW
// ^MTT
// ^PON
// ^PMN
// ^LH0,0
// ^JMA
// ^PR3,3
// ~SD30
// ^JUS
// ^LRN
// ^CI27
// ^PA0,1,1,0
// ^XZ
// ~DGR:G5410010.GRF,1488,16,gR0::::N01H5gG0M0BJFAY0L01LFY0K02IFABHFEX0K03HF4H01HFCW0K0HFEJ0BFEW0J01HFL0HFW0J0HFEL03FEV0I01HFCL01HFV0I03HF8M0HF8U0I07FCN07FCU0I0HFEN03FEU0H01HF8N01HFU0H03HF8O0HF8T0H07HFP07FCT0H0HFEP03FET0H07FCP03HFT0H0HF8P03HF8S001HF8P01HFT003HF8Q0HF8S001HFR07FCS003FER0HFES007FER07FCS007FER03FES007FCR03HFS00HFE0HF80FE80HF83FES007FC1HFC1HFC1HFC1HFS00HFE3HFE3HFE3HFE3HF8R00HFC1C1C3C1C3C3C1HFS00HF83C3E3C1E3C3E1HF8R00HF83C1C7C1E3C1C1HFS00HF83EH2782A3E203HF8R01HF81FH078H03EH01HFS00HF83F8078H03F801HF8R01HF81FC078H01FC01HF8R01HF80HF878HA0HF81HF8R01HF803F8787E03F01HFS00HF8H0FEF8FEH0FC0HF8R01HF8H07C785EH07C1HF8R00HF8203E783E203E1HF8R00HF8341E781E701C1HFS00HF83C1E381E383E0HF8R00HFC3C1C3C1C7C1C1HF8R00HFE3EBE3E3E3EBE1HF8R007FC1HFC1HFE1HFC1HFS00HFE0HF80HFE1HF80HF8R007FC054H0H54054H0HF8R003FEL02L0HF8R003HFS07FS003HFS0HF8R001HFS07F8R002HF8R03F8R0H07F8R01FS0H0HFCS0F8R0H07FCS078R0H03FES078R0H01HFS01S0I0HF8S08R0I07FCgL0I03FEgL0I01HFgL0J0HF8S0623CN0J07FCS043H4N0J03HF8R062E2N0J01HFCR0637O0K0BFE8Q06238N0K01HFDQ0610CN0L0BNFE8I0632EN0L017NFCI063H4N0N0OF8H03IEN0T05HFCI041O0T03IF8S0U07HF8S0U0IFES0U01HFES0U03IF8R0V07HFCR0V0BHFER0V01IFR0V02IF8Q0W07HFCQ0W03HFEQ0X07HFQ0X02HFAP0Y015Q0gR0::
// ~DGR:G5410004.GRF,96,4,N0:::71HFJ071HF8I071HFCI071C1CI071C1E38071C0E380:71C0EI0:::71C1CI071HFC38071HF838071FE0380N0::::
// ~DGR:G5410007.GRF,1536,32,iJ0:::7381C0F83HF81F01CH0EI01HF07HF1HFI01F03HFI07C01E01EH07N073C1C3FE3HF81F01CH0EI01HF87HF1HF8H07FC3HFH01HF07F87F807N073C1C7FE3HF81F01CH0EI01HFC7HF1HFCH0HFC3HFH03HF87387380FN073E1C70F03803B81CH0EI01C3C7H01C1CH0E1E38I03C3CE1CE1C3FN073F1C70703803B81CH0EI01C1C7H01C1CH0E0E38I07818E1CE1C7FN073B1C78H03803B81CH0EI01C3C7H01C1CH0FH038I07I0E1CE1CH7N073B9C3FH038071C1CH0EI01HFC7FE1HFCH07E03FEH07I0E1CE1C47N073H9C1FE038071C1CH0EI01HF87FE1HF8H03FC3FEH07I0E1CE1C07N0739DC07E0380E0E1CH0EI01HF07FE1FEJ0FC3FE3F7I0E1CE1C07N0738DCH0F0380HFE1CH0EI01CH07H01C7J01E3803F7I0E1CE1C07N0738FCE070380HFE1CH0EI01CH07H01C7801C0E3803F7818E1CE1C07N07387CF0F0381IF1CH0EI01CH07H01C3801E1E38I03C3CE1CE1C07N07387C7HF0381C071HF8HFC01CH07HF1C1CH0HFE38I03HF873873807N07383C7FE0381C071HF8HFC01CH07HF1C1CH0HFC38I01HF07F87F807N07381C1F803838039HF8HFC01CH07HF1C0EH03F038J07C01E01EH07N0iJ0::::::::J03E07HF1HFC039C0E07C1HFC7FC1C0E07C0HFE703F0381C0F8Q0J0HF87HF1HFC039E0E1HF1HFC7FE1C0E1HF0HFE70HFC3C1C3FEQ0I01HF87HF1HFC039E0E3HF1HFC7HF1C0E3HF8HFE71HFE3C1C7FEQ0I01C3C7H01CI039F0E38781C07071C0E3C3C0E071E1E3E1C70FQ0I01C1C7H01CI039F8E38381C07071C0E78180E073C0F3F1C707Q0I01EH07H01CI039D8E3CH01C07071C0E7J0E0738073B1C78R0J0FC07FE1HF8039DCE1F801C07HF1C0E7J0E0738073B9C3FR0J07F87FE1HF8039HCE0HF01C07FE1C0E7J0E0738073H9C1FEQ0J01F87FE1HF8039CHE03F01C07F81C0E7J0E07380739DC07EQ0K03C7H01CI039C6EH0781C071C1C0E7J0E07380738DCH0FQ0I0381C7H01CI039C7E70381C071E1C0E78180E073C0F38FCE07Q0I03C3C7H01CI039C3E78781C070E1E1E3C3C0E071E1E387CF0FQ0I01HFC7HF1HFC039C3E3HF81C07070HFC3HF80E071HFE387C7HFQ0I01HF87HF1HFC039C1E3HF01C07070HFC1HFH0E070HFC383C7FEQ0J07E07HF1HFC039C0E0FC01C070383FH07CH0E0703F0381C1F8Q0iJ0::::
// ~DGR:G5410003.GRF,456,24,hN0::7F83E03EH0CFEJ0F01CH0CHF0HF87C3FHC0C067F80CI07F8HF0HFH0CHF8H01F81CH0CHF8HF8FE3FCE0C067FC1CI06H0C38C380HC18H038C36H0HC1HC01C73H0F0C0H60E3CI0601CH1C1H0HC0C6030C36H0HC0HC018H3H0F0C0H60H6CI06018018I0HC0C6I0C36H0HC1HC01E03H0D8C0H60E4CI07F18018I0HC0CI01863H0CHF8HF8FC3F8IC067FC0CI07F18018I0HC0CI03863H0CFE0HF83E3F8IC067FH0CI06018018I0HC0CI0707FH0HC70CI073H0C6C0H6380CI0601CH1C1H0HC0CI0E0HFB0HC38C018H3H0C3D8H61C0CI06H0C38C380HC18H0180C1B9HC18C01C73H0C3DCE60C0CI06H0HF0HFH0CHF8603FHC19F8C1CHF8FE3H0C1CFC60E0CI06H03E03EH0CFE0603FD80CF0C0EHF87C3H0C0C786070CI0hN0:::
// ~DGR:G5410006.GRF,864,36,iR0:::07CU01FEI07H038I07E03E03EI01C0783HFH01F81F80F80FN01HFU03FEI07H038I0HF0HF0HFI03C1FE3HFH03FC3FC3FC3FCM03HFU038J07H038H01E78E78E78H03C1CEH07H079E79E39E39CM03878T038J07H038H01C39C21C2I07C387H0EH070E70E70870EM038383C1CF03F01F0H7FHE0F0707B8H01C39C01CJ07C38701CJ0EH0E7H070EM03CH0HF1HF87F87FC7HFHE3FC71HF8I0E71DE1DEI0DC38701CJ0EH0EH7870EM01F80E71E3CE1C71C7838E39C71C78I07E1HF1HFH019C387038I01C01C7FC70EM00HF1C39C1CEH0E0E7038E70E73838I07E1E71E7H019C1CF038I03C03C79C39EM003F1HF9C1CFC0E0E7038E7FE73838I0E71C39C39FB1C1HF0703F07807870E3FEM0H079HF9C1C7F8E0E7038E7FE73838H01C39C39C39FE1C0F70703F0FH0F070E1HEM07039C01C1C0FCE0E7038E7H073838H01C39C39C39JFH070703F1E01E070EH0EM07879C39C1C01CE0E7038E70E73838H01C39C39C3807HF1870EI01C01C070E30EM03HF8E71C1CE1C71C7038E39C71C78H01E78E70E7I01C3CE0EI038038039C79CM03HF0HF1C1C7F87FC7038E3FC71HF8I0HF0HF0HFI01C1FE0EI07FE7FE3FC3FCM00FC03C1C1C3F01F07038E0F0707B8I07E03C03CI01C0F80EI07FE7FE0F01FN0iR0::::
// ~DGR:G5410005.GRF,288,12,gJ0:::7C1FK01CI0EO07E3FK01CI0EO0:7637K01CI0EO0763707C03DC1E0E38M076371HF0HFC7F8E38M0J71C70E3C738E38M0J73839C1CE1CEO073673839C1CHFCEO0:73E73839C1CEH0EO073E73839C1CE1CEO073E71C70E3C738E38M071C71HF0HFC7F8E38M071C707C03DC1E0E38M0gJ0::::
// ~DGR:G5410002.GRF,152,8,V0::3C1E0F0783C3FI07E3F1F8FC7E3FI0C373B9DCHE73J0C361B0D86C36J0C361B0D86C37EI07E61B0D86C37FI07E61B0D86C3638H0C361B0D86C3018H0C361B0D86C3618H0C373B9DCHEH738H07E3F1F8FC7E3FI03C1E0F0783C1EI0V0:::
// ~DGR:G5410008.GRF,1824,24,hN0::0F8603I0FE0CI01EJ0FCJ07H01FCI0HFC18L03FC603I0HF8CI03FI03FEJ07H01HFI0HFC38L030E603I0C18J0718H0307J0D80183J0C038L0704603I0C0IC60618H070237H0D801818I0C078L06H0603I0C0IC6H018H06H03FH0D801818I0C0D8L06H0603I0C0IC6H03I06H038018C01818I0C0D8L06H0603I0C0HC6CH07I061F3H018C01818I0C198L06H0603I0C0HC6CH0EI061F3H01FC7D818I0C318L0704603I0C0HC6C01CI070H3H03FE7D818I0C3FCL030E603I0C18C3803J03073H03060183J0C3FCL03FC60H3H0HF8C3807F9803HF3H030601HF18H0C018L00F860H3H0FE0C3807F98H0FC3H060301FC18H0C018L0L01R08U08R0:L02Q01U01S0hN0:::0F8603I0HFJ078I03FJ0HC1FI07FE0CP03FC603I0HFJ0FCI0HF8I0HC7F8H07FE1CP030E603J06I01C6I0C1CI0HC61CI0601CP0704603J0C6F0186H01C08DC0HCE08I0603CP06H0603I01C7F8H06H018H0FC0ICK0606CP06H0603I018718H0CH018H0EH0ICK0606CP06H0603I03061801CH0187HCH0ICK060HCP06H0603I070618038H0187HCH0ICK0618CP0704603I06061807I01C0HCH0HCE08I061FEP030E603I0C06180CJ0C1HCH0HC61CI061FEP03FC60H301HF6181FE6H0HFHCH0HC7F8CH06H0CP00F860H301HF6181FE6H03F0CH0HC1F0CH06H0CP0L01P02S04V0:L02P04S08V0hN0:::H01878101FK01HF8L03FCFC101FT0H038FC7C7F8J01HF8L03FCFC7C7F8S0H039CE1061C02I018J04I018C01061CS0H0798628E080E7FC181F01CHF8318028E08S0H0D98H6HCH03C7FC183F878HF831F86HCU0H0D986H0CH0FJ018319EJ061FCH0CU001H986H0C01CJ01807B8J0618EH0CU07F1986H0CH0F07FC181F9E0HF86H06H0CU07HFD86H0E083C7FC1839878HF8E186H0E08S003FDCEH061C0EI0183181CI0C1CEH061CS0H018FCH07F802I0183F804I0C0FCH07F8S0H01878H01FL0181ECK0C078H01FT0hN0::::::K0707J06I018I03030F80EY0K0707J06I018I03031FC0EY0K078FJ06N030H38E1BY0K078F1F0761C019BC030H3061BY0K068B3F8FE3E019FE030H3C01BY0K06DB319CE63019C603031F8318X0K06DB079867F01986030307C318X0K06DB1F9867F019860303H0E3F8X0K067H3H98H6H01986030H3067FCX0K067H319CE7301986038738E60CX0K067H3F8FE3E0198601FE1FC60CX0K06231EC761C01986H0FC0F8C06X0hN0:::
// ~DGR:G5410009.GRF,352,8,V0:2JAH03FAL03IFC017HFCK03IF80KF8J03IF01HFDHFCJ03EHA03FA03FEJ03CI07FI01FJ03CI0FEJ0F8I03CH01FK07CI038H03EH02H03EI03CH07C0174018I03CH0F80IF808I03CH0701IFCK038H0F03IFEK03C01F07F01FK03C01E0FCH0EK03C01C07I04K03803E0FO03C01C1FO03EABE1EO03IFC1CO03IFE1EO03IFC1EO03EABE1EO03C01C1FO03803E0FO03C01C07O03C01E0FCH0EK03C01F07C05FK038H0F03IFEK03CH0701IFCK03CH0F80IF808I03CH07C01FC01CI038H03EH02H03EI03CH01FK07CI03CI0F8J0F8I03CI07CI05FJ038I03FA02FEJ038I017F57FCJ038J0KF8J0L01IFCK0M03FEL0V0
// ~DGR:G5410013.GRF,192,8,V0:::3FE1CE07N03HF1CF07N03HF9CF07N03879CF87N03839CFC71CL03879CEC71CL03HF9CHE71CL03HF1CE67N03FE1CEH7N03801CE37N03801CE3FN03801CE1FN03801CE1F1CL03801CE0F1CL03801CE071CL0V0::::
// ^XA
// ^MMT
// ^PW301
// ^LL300
// ^LS0
// ^FO0,114^XGR:G5410010.GRF,1,1^FS
// ^FO13,259^XGR:G5410004.GRF,1,1^FS
// ^FO65,208^XGR:G5410007.GRF,1,1^FS
// ^FO67,78^XGR:G5410003.GRF,1,1^FS
// ^FO1,21^XGR:G5410006.GRF,1,1^FS
// ^FO1,43^XGR:G5410005.GRF,1,1^FS
// ^FO5,190^XGR:G5410002.GRF,1,1^FS
// ^FO107,114^XGR:G5410008.GRF,1,1^FS
// ^FO2,66^XGR:G5410009.GRF,1,1^FS
// ^FPH,1^FT73,66^A0N,25,25^FH^CI28^FD${model}^FS^CI27
// ^FT39,282^A0N,29,28^FH^CI28^FD${id}^FS^CI27
// ^FO150,259^XGR:G5410013.GRF,1,1^FS
// ^FT192,282^A0N,29,28^FH^CI28^FD${pin}^FS^CI27
// ^PQ1,0,1,Y
// ^XZ
// ^XA^IDR:G5410013.GRF^FS^XZ
// ^XA^IDR:G5410009.GRF^FS^XZ
// ^XA^IDR:G5410008.GRF^FS^XZ
// ^XA^IDR:G5410002.GRF^FS^XZ
// ^XA^IDR:G5410005.GRF^FS^XZ
// ^XA^IDR:G5410006.GRF^FS^XZ
// ^XA^IDR:G5410003.GRF^FS^XZ
// ^XA^IDR:G5410007.GRF^FS^XZ
// ^XA^IDR:G5410004.GRF^FS^XZ
// ^XA^IDR:G5410010.GRF^FS^XZ
// `
//         default:
//           return `CT~~CD,~CC^~CT~
// ^XA
// ~TA000
// ~JSN
// ^LT0
// ^MNW
// ^MTT
// ^PON
// ^PMN
// ^LH0,0
// ^JMA
// ^PR3,3
// ~SD30
// ^JUS
// ^LRN
// ^CI27
// ^PA0,1,1,0
// ^XZ
// ~DGR:G5410010.GRF,1488,16,gR0::::N01H5gG0M0BJFAY0L01LFY0K02IFABHFEX0K03HF4H01HFCW0K0HFEJ0BFEW0J01HFL0HFW0J0HFEL03FEV0I01HFCL01HFV0I03HF8M0HF8U0I07FCN07FCU0I0HFEN03FEU0H01HF8N01HFU0H03HF8O0HF8T0H07HFP07FCT0H0HFEP03FET0H07FCP03HFT0H0HF8P03HF8S001HF8P01HFT003HF8Q0HF8S001HFR07FCS003FER0HFES007FER07FCS007FER03FES007FCR03HFS00HFE0HF80FE80HF83FES007FC1HFC1HFC1HFC1HFS00HFE3HFE3HFE3HFE3HF8R00HFC1C1C3C1C3C3C1HFS00HF83C3E3C1E3C3E1HF8R00HF83C1C7C1E3C1C1HFS00HF83EH2782A3E203HF8R01HF81FH078H03EH01HFS00HF83F8078H03F801HF8R01HF81FC078H01FC01HF8R01HF80HF878HA0HF81HF8R01HF803F8787E03F01HFS00HF8H0FEF8FEH0FC0HF8R01HF8H07C785EH07C1HF8R00HF8203E783E203E1HF8R00HF8341E781E701C1HFS00HF83C1E381E383E0HF8R00HFC3C1C3C1C7C1C1HF8R00HFE3EBE3E3E3EBE1HF8R007FC1HFC1HFE1HFC1HFS00HFE0HF80HFE1HF80HF8R007FC054H0H54054H0HF8R003FEL02L0HF8R003HFS07FS003HFS0HF8R001HFS07F8R002HF8R03F8R0H07F8R01FS0H0HFCS0F8R0H07FCS078R0H03FES078R0H01HFS01S0I0HF8S08R0I07FCgL0I03FEgL0I01HFgL0J0HF8S0623CN0J07FCS043H4N0J03HF8R062E2N0J01HFCR0637O0K0BFE8Q06238N0K01HFDQ0610CN0L0BNFE8I0632EN0L017NFCI063H4N0N0OF8H03IEN0T05HFCI041O0T03IF8S0U07HF8S0U0IFES0U01HFES0U03IF8R0V07HFCR0V0BHFER0V01IFR0V02IF8Q0W07HFCQ0W03HFEQ0X07HFQ0X02HFAP0Y015Q0gR0::
// ~DGR:G5410004.GRF,96,4,N0:::71HFJ071HF8I071HFCI071C1CI071C1E38071C0E380:71C0EI0:::71C1CI071HFC38071HF838071FE0380N0::::
// ~DGR:G5410007.GRF,1536,32,iJ0:::7381C0F83HF81F01CH0EI01HF07HF1HFI01F03HFI07C01E01EH07N073C1C3FE3HF81F01CH0EI01HF87HF1HF8H07FC3HFH01HF07F87F807N073C1C7FE3HF81F01CH0EI01HFC7HF1HFCH0HFC3HFH03HF87387380FN073E1C70F03803B81CH0EI01C3C7H01C1CH0E1E38I03C3CE1CE1C3FN073F1C70703803B81CH0EI01C1C7H01C1CH0E0E38I07818E1CE1C7FN073B1C78H03803B81CH0EI01C3C7H01C1CH0FH038I07I0E1CE1CH7N073B9C3FH038071C1CH0EI01HFC7FE1HFCH07E03FEH07I0E1CE1C47N073H9C1FE038071C1CH0EI01HF87FE1HF8H03FC3FEH07I0E1CE1C07N0739DC07E0380E0E1CH0EI01HF07FE1FEJ0FC3FE3F7I0E1CE1C07N0738DCH0F0380HFE1CH0EI01CH07H01C7J01E3803F7I0E1CE1C07N0738FCE070380HFE1CH0EI01CH07H01C7801C0E3803F7818E1CE1C07N07387CF0F0381IF1CH0EI01CH07H01C3801E1E38I03C3CE1CE1C07N07387C7HF0381C071HF8HFC01CH07HF1C1CH0HFE38I03HF873873807N07383C7FE0381C071HF8HFC01CH07HF1C1CH0HFC38I01HF07F87F807N07381C1F803838039HF8HFC01CH07HF1C0EH03F038J07C01E01EH07N0iJ0::::::::J03E07HF1HFC039C0E07C1HFC7FC1C0E07C0HFE703F0381C0F8Q0J0HF87HF1HFC039E0E1HF1HFC7FE1C0E1HF0HFE70HFC3C1C3FEQ0I01HF87HF1HFC039E0E3HF1HFC7HF1C0E3HF8HFE71HFE3C1C7FEQ0I01C3C7H01CI039F0E38781C07071C0E3C3C0E071E1E3E1C70FQ0I01C1C7H01CI039F8E38381C07071C0E78180E073C0F3F1C707Q0I01EH07H01CI039D8E3CH01C07071C0E7J0E0738073B1C78R0J0FC07FE1HF8039DCE1F801C07HF1C0E7J0E0738073B9C3FR0J07F87FE1HF8039HCE0HF01C07FE1C0E7J0E0738073H9C1FEQ0J01F87FE1HF8039CHE03F01C07F81C0E7J0E07380739DC07EQ0K03C7H01CI039C6EH0781C071C1C0E7J0E07380738DCH0FQ0I0381C7H01CI039C7E70381C071E1C0E78180E073C0F38FCE07Q0I03C3C7H01CI039C3E78781C070E1E1E3C3C0E071E1E387CF0FQ0I01HFC7HF1HFC039C3E3HF81C07070HFC3HF80E071HFE387C7HFQ0I01HF87HF1HFC039C1E3HF01C07070HFC1HFH0E070HFC383C7FEQ0J07E07HF1HFC039C0E0FC01C070383FH07CH0E0703F0381C1F8Q0iJ0::::
// ~DGR:G5410003.GRF,456,24,hN0::7F83E03EH0CFEJ0F01CH0CHF0HF87C3FHC0C067F80CI07F8HF0HFH0CHF8H01F81CH0CHF8HF8FE3FCE0C067FC1CI06H0C38C380HC18H038C36H0HC1HC01C73H0F0C0H60E3CI0601CH1C1H0HC0C6030C36H0HC0HC018H3H0F0C0H60H6CI06018018I0HC0C6I0C36H0HC1HC01E03H0D8C0H60E4CI07F18018I0HC0CI01863H0CHF8HF8FC3F8IC067FC0CI07F18018I0HC0CI03863H0CFE0HF83E3F8IC067FH0CI06018018I0HC0CI0707FH0HC70CI073H0C6C0H6380CI0601CH1C1H0HC0CI0E0HFB0HC38C018H3H0C3D8H61C0CI06H0C38C380HC18H0180C1B9HC18C01C73H0C3DCE60C0CI06H0HF0HFH0CHF8603FHC19F8C1CHF8FE3H0C1CFC60E0CI06H03E03EH0CFE0603FD80CF0C0EHF87C3H0C0C786070CI0hN0:::
// ~DGR:G5410006.GRF,864,36,iR0:::07CU01FEI07H038I07E03E03EI01C0783HFH01F81F80F80FN01HFU03FEI07H038I0HF0HF0HFI03C1FE3HFH03FC3FC3FC3FCM03HFU038J07H038H01E78E78E78H03C1CEH07H079E79E39E39CM03878T038J07H038H01C39C21C2I07C387H0EH070E70E70870EM038383C1CF03F01F0H7FHE0F0707B8H01C39C01CJ07C38701CJ0EH0E7H070EM03CH0HF1HF87F87FC7HFHE3FC71HF8I0E71DE1DEI0DC38701CJ0EH0EH7870EM01F80E71E3CE1C71C7838E39C71C78I07E1HF1HFH019C387038I01C01C7FC70EM00HF1C39C1CEH0E0E7038E70E73838I07E1E71E7H019C1CF038I03C03C79C39EM003F1HF9C1CFC0E0E7038E7FE73838I0E71C39C39FB1C1HF0703F07807870E3FEM0H079HF9C1C7F8E0E7038E7FE73838H01C39C39C39FE1C0F70703F0FH0F070E1HEM07039C01C1C0FCE0E7038E7H073838H01C39C39C39JFH070703F1E01E070EH0EM07879C39C1C01CE0E7038E70E73838H01C39C39C3807HF1870EI01C01C070E30EM03HF8E71C1CE1C71C7038E39C71C78H01E78E70E7I01C3CE0EI038038039C79CM03HF0HF1C1C7F87FC7038E3FC71HF8I0HF0HF0HFI01C1FE0EI07FE7FE3FC3FCM00FC03C1C1C3F01F07038E0F0707B8I07E03C03CI01C0F80EI07FE7FE0F01FN0iR0::::
// ~DGR:G5410005.GRF,288,12,gJ0:::7C1FK01CI0EO07E3FK01CI0EO0:7637K01CI0EO0763707C03DC1E0E38M076371HF0HFC7F8E38M0J71C70E3C738E38M0J73839C1CE1CEO073673839C1CHFCEO0:73E73839C1CEH0EO073E73839C1CE1CEO073E71C70E3C738E38M071C71HF0HFC7F8E38M071C707C03DC1E0E38M0gJ0::::
// ~DGR:G5410002.GRF,152,8,V0::3C1E0F0783C3FI07E3F1F8FC7E3FI0C373B9DCHE73J0C361B0D86C36J0C361B0D86C37EI07E61B0D86C37FI07E61B0D86C3638H0C361B0D86C3018H0C361B0D86C3618H0C373B9DCHEH738H07E3F1F8FC7E3FI03C1E0F0783C1EI0V0:::
// ~DGR:G5410008.GRF,1824,24,hN0::0F8603I0FE0CI01EJ0FCJ07H01FCI0HFC18L03FC603I0HF8CI03FI03FEJ07H01HFI0HFC38L030E603I0C18J0718H0307J0D80183J0C038L0704603I0C0IC60618H070237H0D801818I0C078L06H0603I0C0IC6H018H06H03FH0D801818I0C0D8L06H0603I0C0IC6H03I06H038018C01818I0C0D8L06H0603I0C0HC6CH07I061F3H018C01818I0C198L06H0603I0C0HC6CH0EI061F3H01FC7D818I0C318L0704603I0C0HC6C01CI070H3H03FE7D818I0C3FCL030E603I0C18C3803J03073H03060183J0C3FCL03FC60H3H0HF8C3807F9803HF3H030601HF18H0C018L00F860H3H0FE0C3807F98H0FC3H060301FC18H0C018L0L01R08U08R0:L02Q01U01S0hN0:::0F8603I0HFJ078I03FJ0HC1FI07FE0CP03FC603I0HFJ0FCI0HF8I0HC7F8H07FE1CP030E603J06I01C6I0C1CI0HC61CI0601CP0704603J0C6F0186H01C08DC0HCE08I0603CP06H0603I01C7F8H06H018H0FC0ICK0606CP06H0603I018718H0CH018H0EH0ICK0606CP06H0603I03061801CH0187HCH0ICK060HCP06H0603I070618038H0187HCH0ICK0618CP0704603I06061807I01C0HCH0HCE08I061FEP030E603I0C06180CJ0C1HCH0HC61CI061FEP03FC60H301HF6181FE6H0HFHCH0HC7F8CH06H0CP00F860H301HF6181FE6H03F0CH0HC1F0CH06H0CP0L01P02S04V0:L02P04S08V0hN0:::H01878101FK01HF8L03FCFC101FT0H038FC7C7F8J01HF8L03FCFC7C7F8S0H039CE1061C02I018J04I018C01061CS0H0798628E080E7FC181F01CHF8318028E08S0H0D98H6HCH03C7FC183F878HF831F86HCU0H0D986H0CH0FJ018319EJ061FCH0CU001H986H0C01CJ01807B8J0618EH0CU07F1986H0CH0F07FC181F9E0HF86H06H0CU07HFD86H0E083C7FC1839878HF8E186H0E08S003FDCEH061C0EI0183181CI0C1CEH061CS0H018FCH07F802I0183F804I0C0FCH07F8S0H01878H01FL0181ECK0C078H01FT0hN0::::::K0707J06I018I03030F80EY0K0707J06I018I03031FC0EY0K078FJ06N030H38E1BY0K078F1F0761C019BC030H3061BY0K068B3F8FE3E019FE030H3C01BY0K06DB319CE63019C603031F8318X0K06DB079867F01986030307C318X0K06DB1F9867F019860303H0E3F8X0K067H3H98H6H01986030H3067FCX0K067H319CE7301986038738E60CX0K067H3F8FE3E0198601FE1FC60CX0K06231EC761C01986H0FC0F8C06X0hN0:::
// ~DGR:G5410009.GRF,352,8,V0:2JAH03FAL03IFC017HFCK03IF80KF8J03IF01HFDHFCJ03EHA03FA03FEJ03CI07FI01FJ03CI0FEJ0F8I03CH01FK07CI038H03EH02H03EI03CH07C0174018I03CH0F80IF808I03CH0701IFCK038H0F03IFEK03C01F07F01FK03C01E0FCH0EK03C01C07I04K03803E0FO03C01C1FO03EABE1EO03IFC1CO03IFE1EO03IFC1EO03EABE1EO03C01C1FO03803E0FO03C01C07O03C01E0FCH0EK03C01F07C05FK038H0F03IFEK03CH0701IFCK03CH0F80IF808I03CH07C01FC01CI038H03EH02H03EI03CH01FK07CI03CI0F8J0F8I03CI07CI05FJ038I03FA02FEJ038I017F57FCJ038J0KF8J0L01IFCK0M03FEL0V0
// ~DGR:G5410013.GRF,192,8,V0:::3FE1CE07N03HF1CF07N03HF9CF07N03879CF87N03839CFC71CL03879CEC71CL03HF9CHE71CL03HF1CE67N03FE1CEH7N03801CE37N03801CE3FN03801CE1FN03801CE1F1CL03801CE0F1CL03801CE071CL0V0::::
// ^XA
// ^MMT
// ^PW301
// ^LL300
// ^LS0
// ^FO0,114^XGR:G5410010.GRF,1,1^FS
// ^FO13,259^XGR:G5410004.GRF,1,1^FS
// ^FO65,208^XGR:G5410007.GRF,1,1^FS
// ^FO67,78^XGR:G5410003.GRF,1,1^FS
// ^FO1,21^XGR:G5410006.GRF,1,1^FS
// ^FO1,43^XGR:G5410005.GRF,1,1^FS
// ^FO5,190^XGR:G5410002.GRF,1,1^FS
// ^FO107,114^XGR:G5410008.GRF,1,1^FS
// ^FO2,66^XGR:G5410009.GRF,1,1^FS
// ^FPH,1^FT73,66^A0N,25,25^FH^CI28^FD${model}^FS^CI27
// ^FT39,282^A0N,29,28^FH^CI28^FD${id}^FS^CI27
// ^FO150,259^XGR:G5410013.GRF,1,1^FS
// ^FT192,282^A0N,29,28^FH^CI28^FD${pin}^FS^CI27
// ^PQ1,0,1,Y
// ^XZ
// ^XA^IDR:G5410013.GRF^FS^XZ
// ^XA^IDR:G5410009.GRF^FS^XZ
// ^XA^IDR:G5410008.GRF^FS^XZ
// ^XA^IDR:G5410002.GRF^FS^XZ
// ^XA^IDR:G5410005.GRF^FS^XZ
// ^XA^IDR:G5410006.GRF^FS^XZ
// ^XA^IDR:G5410003.GRF^FS^XZ
// ^XA^IDR:G5410007.GRF^FS^XZ
// ^XA^IDR:G5410004.GRF^FS^XZ
// ^XA^IDR:G5410010.GRF^FS^XZ
// `
//       }
//     case 203:
//       switch (model) {
//         case 'S1-U':
//           return `^
// XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR4,4~SD30^JUS^LRN^CI0^XZ
// ^XA
// ^MMT
// ^PW300
// ^LL0300
// ^LS0
// ^FO96,192^GFA,01536,01536,00024,:Z64:
// eJztkTGLE0EUx984A3PFspuARQKLK+QLzJpmT+HWa/RrhGyRNkHLg7zLCrFxt4143X2JgM0Me3Ifw41biI0YrglcnPVN9ANYyNncq/78+M9v3jAA93MXc1zll+2jIw7Bpk1rfmnhqR8gQt/ITRuGEuS2jX7IzZ7ZKDhH6DXFSJ0cPQDvGJPHfoa8eLaqqL8VU/WQ+qKPHSVmwKC7+oSQfCxBPelx8KmvAo4chutv1H8vtcr++Gtpdhzi1Sn5P+S1ysgfbGyKvDnwmvrvYKrmzs+wq2EGW+KGeFko1b4lP8Ok9od4uxge+FIodXpx6HdGIga2jPvEe3lZK1M2O4+162nQINiodO89Zzo2n7/uBGs7ihmAM08y/N/f89fDzH6uX4pA/tx3pmfRPpIicDxo7LzOirS8tYmyqU3zIkXi4hWMYPImRiNVvORidk2ZuD/AEWRFoqs8GSy4VzeUiUvm+lchGNmZAfe0y46TH8ffU6xsMgDu1y67fW72c5y8ltrs1BC40C67A0Pyj09AVxfJAMnjMmGvS/4XodLGO/ifu+z2+d1P3B7ZkvxfKLt7b+xcT8JIGxkZy0V6TZk4p3302Pexyktcc89fUL67b7iffzS/AMG8wNQ=:E926
// ^FO96,32^GFA,01536,01536,00024,:Z64:
// eJztkbFKw1AUhv8k5VyHkMXBopFGAs5262Tq1MWHuJAh67046FC8l2Zw8QF8nFsKzSL4Ag7XVJzTrcM1mvgSBem3HPj5OJzDDxw48P/x3MhrSAHzCIVTy49WcOkYApeVO1IajxHyVpVNe8XTluBBVjFJg/UIs4FgY/MwlWyEAOb5yE9yLM5QU+IP9cSmwWmfhyBRgI5RkGA3Zj69YycIdn3OOaKx3jhVWnfZ7ffhfZsYTAjEYz1r1HLr7qd/92gdwk8STDJdT7qhU5u+dD7M7YAEh1DvRSzo3MhPGXY++DokbsH5ax1yGuqLr5x6Xz11f63ww9+qSK22RlZb1vsRrH8doLWbBWXa6mRTBot913NgT/wCsE9hvA==:B4D8
// ^FO0,0^GFA,02304,02304,00036,:Z64:
// eJztUrGO1DAUHMdW4iJsUqaIlMAXBF2TAm2cE4IGiV/Y3BekoKAArX05RRQooqfg+AskGt8iXYUEf4B1S71KmSqHE/bo6JBoMsWzpTcaz3tjYMGCv4H0x8sLuBCIiUmA9FEyoPbw0ptbwXDkHOBAomiGQsKMyUDGxIyBnlq+f9STYNAoL6JSQl8hJG5YtZGYenz1hzPpmI4bST82MqVOdtLk2cyB59Yx0e9/em4sIC6sKVIpWylOkIXzW5OFMTCfD8X5WqJ6Yzo496XUVvZBns46ns6exqUfXrHscaWR3Ot/gFXKchhOX4WzDh3SM254rtr0bGPn6vK95OleSaywb/N6Nhtvd6wOI8W210KgfMv083eb14r0EWlo8n3mrLY3fB1x1W1vjJ36q6+/fRjyRg05PafB5USJmGC+N+kIJjTEM6aFXSABBLN19sPd1OH00+THsX7MwTcGadOAXEa0ydN5dpad+qWfKZY9eWj33EaVxOYLhdUgbbT5nVex47eBUV2xW9/llY4O6Fjc5UV6j/l2z4rZU9vcdQxsYsu5Lfra+4c/bMGCBQv+F34BTt+ITA==:35D5
// ^FO128,224^GFA,00768,00768,00012,:Z64:
// eJztjrENhEAMBH0i+JAOoA0HC67rJQjo7OiAEkwHkBG8xB86W1wFL4J3tB6tdpfof8+8cC5AiPk5D+6DGv9ATFOhgw6QyfzasEy6Zd5A5qxJe8jqfEC3x83zeb3zsXv+AbydR2CM1kXMnfcS0Jr/0nXJfU/y196b+Mv3JF0VvLI9P7svt+hGNw==:557E
// ^FO0,224^GFA,00512,00512,00008,:Z64:
// eJxjYBgFqKCi/guYtrD/B+X/h/L/N4D5FewHwHwL5gMPwHzmgx8g/IMQPiOYBvIfQNQzQtUzovAr4PLMMPNQzQfa9wBiP1gj0D2MEP4HiEFUAQCI/iki:E180
// ^FO0,0^GFA,00768,00768,00012,:Z64:
// eJxjYBgFgx/Isx9gYChgMACx+duB7AcMFkjiEmB2/wMQGywu23qwgK2gwCIByObrPfzD/sE/C6Asg0zvcRmLj20SDiDx7nN8Fo/bweplIo+xFRT/B7N5fp7jK0iGsGUKj7AUFELMt/v9js/iM1R9IdCcwmNg83kqkcwvPPCBp6AAbP7QAADiQTF3:3AF5
// ^FO0,192^GFA,00512,00512,00008,:Z64:
// eJxjYBgFEHDw4AULEH2g+QOYPnjcoAbMb7LIAVKMB483+EDkHxyDqP8Apg80WPwD849J/gfRD5skm0F0PftHMM3H8Li5gZ4eIQkAAHoYHIg=:BEAB
// ^FO32,192^GFA,00256,00256,00008,:Z64:
// eJxjYBgwYAGlJdDEJedAaMt/UP4xKN0HpVug8m0QWrANuzxRAAA5cwb6:DBB9
// ^FO0,192^GFA,00256,00256,00008,:Z64:
// eJxjYBggwPjxA4MMiPHgAQMPiP4IpR8ufvDGAih/8fvHPzVA/m0l455DIDqhnPc/kD6l/IjnH5C+X14sewBIn1NT7jkGpI+lQ9QfUzYA6ScSAADv6CBZ:C9B5
// ^FO0,160^GFA,00768,00768,00012,:Z64:
// eJxjYBgFxAM7Pv7m5gc/wOw6ef72ww8/gNnJ1jP6nhk3QNiGAkfSDCHqkzfOOPPO+gGYLWM440yaIES9neGON2nGFlD1E3zSDCTA7Mc7Qeoh7GRBmTNuhRC2nT1//+PHEHtl2NiZGQ8k0NSfIwcAADfkJIo=:4638
// ^FO96,64^GFA,04480,04480,00028,:Z64:
// eJztls9rG0cUx9/siBkdFq1728AGLQn0WEZRKSoES1DIOX9ADpsEVtcRCvGldMcW2D4Yes0hkEP/AUMuhUIZV6C9hEDvhiw2JD30oOBSq2QT983sSt51m0NLKSn1w17N6sObNz++7z0BXNql/TPGoQERgAYu8Y1kVUaBFcwR5u0PrBgYRmpz0u7X393L5pPN6Y0m3ZxOavH6L9rDt3n66nQj8NLTF7rq15v14s93n8fdru/G3WaNdWZrwys/HpJxZ80jo3aNiW1xz1/s0pPOPp2ePKmzmRj6d57xjdEchiMfLrD4yt7z+LrI3Pj6hXgpxnubvjrpZF76+kK8yT7Gm0yeioxOnlL4c9Pv+d7MoP4e+wDsSxlAgne/BhxFo/kv0H6tZcHeiSYk2wACvDnwOV+A9yYrGNkRPow0gIQA/3o8wK/kbcuoQhbGU9iHHnzbBIcBUeGjggHGk/GQCJBkEQBHpg8OS4bxovgexhOgfWDGL9tdMh/kww1cZ+c3ZJwjG+2ds/DjG8ZvoXxy5CDr5JZxZuN9ZthVHXx0inNuj98t94DxXt5E5veU22MtZN0iD8iZuKKTWQBrAzwP3Hsuyc4pL86lJ3y1zpqAclGKKpqFZHPLWR3pwD6j8m2tdtyqxsIqIlXv888PzJZ6kUYvVKMw2nNdsqVeFkYvVHkL6GclW+qFPDZ64doNgJKSFfeHetkzenGU2wRKS8ZLvYCLesnZwHXx6s/9Cr00jV7cCBll1TmNXnyjF1ca1qjFW+rFO/4Z2bJY0EIvn+IVoQDaJ8h4e17uYbvUizZ6aQpcp+NlJSv1QnKjF194iwFdVYxSLyQ3eun1vCyj1WoysM+ofIugaur97L+hF5lgWUkJphAmOrQB4kSTs6BkCrxjUMiwG/U1xPh/9r1lt7HOBGOiTGGSRjlDOQB/w7JHoRsJiqzbw1aWbWXXwgh8m4DkULJMcgw1zpkEzbPY+LnWbzfZ0YLimR7vMVQMxXgK/Jb120umysxJpgETZPoKHuLCfdsLSB7Sgk2a6Dc9AhvPMvpOci2GuD/uYrzBEGKJ9XBoGYuY9segGHVZCNERxMbvvmGcJ88G7RPymJ8E/LXqpzBMUvATwxynvxvSCdn13jTJpmo5cK2/BX5QPdaGXVgxrndrwHQ4N+cCW6s4NP7SRf5LZusLXj2QObDoAVYXuWK2vgCsA82B3c/BW0RLZOsL7msM2InYGN0350tm85121XVoNT9xroHpKktm6wvva44F5isWI9uUFb8mtHqaIROWbUW1OYMO+gXrkg/NAmSN+UK54K6HNt52uGK2vgh9ExpMsmEDSLpaZ9GPhBbAWiG7j91oZ7Fitr50dOLTXLIRdiN1fi6mvpztq8SHLHTCRYjdtnqkA/Mo21C9Gy3rS2FhHZGqwwA+VDvQ+gHeo2np3vHcjM9tevRNbnLUMjteGUlHaUBMScXHFyStZhf9oeMAfZJFC9rXW6SWQ3w2ugXt9vxufic5SA9uVRmbCYYtDKK9DMgkY1XWMAyvLLqKTZCJKqM/zYQDvTbGm/enIKrx+NmvkjdMvMM7ScoEryat9zKkLfx9eXeX448sQVvVgOmc5Li1u/jgHMe1M1NglBU9wwJBivGlXdr/1H4HdZSgqg==:0327
// ^FO0,96^GFA,01152,01152,00012,:Z64:
// eJzt0r1qwzAQAOATHoSW6gVE/CKBvFjAgg5ZCnmFPkaHDoYOHfMKMi14CbW9aRBW5buTJejQqVsPDx+O7idnAfzHbyHjvPsU193RRctsVtCOrT00nt06ECGn9gAdu0uJJ06O27keKbbiXKgJlbd6kqaQPr9IP8/5IJubYZpY8wgp2H3t+UG4SKO9+dPFs99Dlx7yxSw3w76aYTKdpfcwTJBtnbbZzsme/TiPHzPbhnHw2efRnbPN4I7s62H4yvVvh6XYLBOfadNgY2CnmUdPS6T/SNY/3KJpfbW13XebjeuTaFplg6aVCXL+hqUNVMtVr1gOG6g7lsMG6hlL4I7VCxWK0YF6ouQYo1Wf6CbZ6ePeI1+m7Vgo1uUmppRisd/KFLEv7lw1eLnS0FYNZNUAqgZ/Fd+hGJ3F:4F5C
// ^FO0,32^GFA,01152,01152,00012,:Z64:
// eJztkjEOwyAMRR0xMLJ2qOQeoSdortIjdOyW5GYchSMwMiBcJ9gJqB07dKgF0hMC8/1tgH/8TiARWJqFC4wUlQeiLJwN8b3KyTFvD5BXBvLCY1hX5WkGF4ULgEmVB044ZOH1TNhEQ/CsbIMjf1EeKdR/wflJtYGbiZLyUozoBFwS6DmaCPedg9YLaA++Wb/7UL7Dbc7ur04Da3t80NzW0tXY1N56snl1ffdw87YIN563veh6xI3DIIzp6Gnb630GzpxQZ+PEW7Wt4WSWfjde3GiGag==:F960
// ^FT215,279^A0N,29,28^FH^FD${pin}^FS
// ^FT59,279^A0N,29,28^FH^FD${id}^FS
// ^FT97,50^A0N,21,21^FH^FD${model}^FS
// ^PQ1,0,1,Y^XZ
// `
//         case 'G1N-U':
//           return `CT~~CD,~CC^~CT~
// ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD30^JUS^LRN^CI0^XZ
// ^XA
// ^MMT
// ^PW203
// ^LL0203
// ^LS0
// ^FO0,64^GFA,01152,01152,00012,:Z64:
// eJxjYBgFZALZADiT+f9/OJs1gN8BxrZrYH4BY4cyMFyBsf8BpaBMRqApolA2E1AxfwPUGAcIBgH+B0ArHiDEGaE2yx8AikMtEGVAiNsDzWD8gRBnCIGKy7c//PEHKi7qGxgCE7d6ZzzrH4QdYnFEUAYqXmfBw9wAExdlYXSAiXP3/PhRB2GHMboElMDEGd9YyEDFQwRcREUQ5lvPg4qLm7oWwtTb9bf9hJkj2oDkThAbGkCioDAwgYYD0L8MfIhwYBCBsNlBYcMGYTMjYgUeBiAgAA58VpAJDBlgO9VBJjA4gAOIFazGgR+kQQWsxoMxNDQEGgMWDPb//zdZH4CZJZqAMJf/BYLNGoJgM/9Dckcogs3wvwHBBnsOZtADJA1InhjeAABKbUWe:C8E3
// ^FO0,160^GFA,00512,00512,00008,:Z64:
// eJxjYBgkwPIfhJb8D6WbGyB0Yw9EvrkPym/ATsPUN0P1/+OB0H94aObmYQAABa8NJg==:1E7E
// ^FO64,160^GFA,00512,00512,00008,:Z64:
// eJxjYBg0gPGfcROY/m/YDKabrQ+B6UbB4xJAmrnZ+LEFRN4Yqn4XmG4QlIfQxvMgtCE/hLbuk4DIs0vQ2S9DBgAAPYMQVw==:D8EC
// ^FO32,128^GFA,01536,01536,00024,:Z64:
// eJztkEFLAkEYhmdcaSdYUqHDCMoOCtGhw5CXPUQSQl36AR46SMZ6nQjEg+mQUBMInj31U1JWskNgx44LQWcXOgw0akO79gsSOvieXh4+Hl4+ANb5nynED9oZyoxAzcGlglPGWMi7rWKXEENqLpUhpayEfHRsbVNqZLkH+9xM9/vTkAuC9gmJId6BnMfgvSA//OqiadUZM2ZqAQMVh9lM5N87RDnt96UEDfkBN1F4Xzgwrbb2Q/4C0vwzlcY05PgcFbUf8BsoeCy99BcyLeuoXTSgGsMZN4NgGvrzloOGj8UYUB73OfArFbL6Z/5lthIT30XOfE497ytxi5yI2/bEr2HVXtCTYGGPsYo4FgjkkcAd2qV55x2JiGewBWpvAzxmp8kq0+3X3/NdIdGcPoMq2REy4ubDZOCWm1jRM1Ci9XJzOUj7c7sW7lJB8kS3CKdeLeBej5Lj5IhVt3WLeKKn9zsKefadX008Ocudpj2ZuriBhzYclMwN3FjB79b5Z/kGe6GIpg==:C9A7
// ^FO32,32^GFA,01536,01536,00024,:Z64:
// eJxjYBgFo4AiwP7wQIHgB4YCIFEjU5fIVMMCEWdjsu6Q7GDo+MDZcyAtIbkpmQcizsLCyCDIwsARwMlywMGhkMVRBqqejYlBsq2BYwZnT0NCQjVTsg5MPRNQvYNAgCBLjYFDIJMj1Hz+d0D1fQwWHZw/GuTq8pnqeJDVMxT2cAocYHNwZ3NhQTK/j+Hhj86OBqaEdKUkmHomQQmg+QdZJrI4uDk4qzjD1DM+eCH548Dvvod9/2wSknck99A2HEfBKKA7AADIzjI2:98FE
// ^FO0,0^GFA,00512,00512,00008,:Z64:
// eJxjYBgh4PEBBgPGBgbGhyAaxD/cvODhDAbGTcePf/wIos+lGUsD5becUTH+2cDAsO+EnjKQZpxzxsUQqI9hzvnHn38C1ckcbtxYOGOAPUM9AACajyDH:DD9E
// ^FO0,0^GFA,00768,00768,00024,:Z64:
// eJztkDFLw0AAhV9sSQfLmZu8QiTB20uky0GL14B/pPQPtNDB4cCDDjocdY34L5wKDs1w0CX4GwIBXQouDgWPYOhvKLj0G97weDweDzhxVMRBh2BdCCFQPZeEkFtAHfwYrA2lFLbbPIoiCRSiT8j5L2E7FEXhfcT58P2NA7l6aALzqOdgtfbuxqA0vgH2nzwZZHzwusJ+vT77KnWr1Zcao3oaJhmXL23cO7dIJx3fl1cAqa8v4qfGNzAr813yR9PlSw2bpx7NJkHPwFrb+UkRBJQCruZSNj2Zg3MOVQW52TT7Z6Np6DN+yRaYJQnSJaQMw/98+ij8AZctSzA=:0CBA
// ^FO0,128^GFA,00256,00256,00008,:Z64:
// eJxjYKANYGxu4uOwYWBgT3GdaJbAwMCk5tGopMDAwJwSOdG4Aijf4jFRiQMkH3OxnAUorwLkswH5JrETzUF8pY4uJQ+guuPH2WRqaORIIAAALA0QUg==:DF83
// ^FO64,64^GFA,01920,01920,00020,:Z64:
// eJztkk9rE0EYxp/ZHWYXqem2oK6wNZsESo+TFmpPdo3b0EMOvfXiYRHNeatgL2l5m3hYpYhfxW+wAUEPKXr0UDDgF0gQxIOos5v9k7O1eOk77DA8/HjneZ9Z4KouVOMTWoxQwSKB7qHaVxJbGZL1DkuwYhY3IQylaSAyPTC4YJ5LmlCazk7I2jllkNeZ1yRecLuRDtfUXMVdU5qRcP65gWcjI5CxuJVznbgC90PKLef9/D0HsmN8e0u8mngxKTIj15Z2xGUt0rTE83RoV3lYlUaHW9u20GeTmOVQxdEqtmy/jPyqxAhjRFv4lV6c5MdgBIaNURu2g2xe5d1VuUUE00Y2r7iLkLPxGc1xfB0BByXcGrL8FjYQ8yM6i+F0cs5sIBZmyu3m/awmI36bRjHsHrL8ZEPXNJuilmvOglH5bR8Y39mURr3AXpgLLY1XvfF8fjw9eZcTH8SWik7/odx/pUqm8Sf7cnZj98ZBplUet9VO6mvxINOcHY4Q9z+FeP8m97fsczaY9GuDCVGubfrJv+kdgsVerq36XB9MBhuKCzJN/+0n/R7IkKHgtDVNeelLsBfUyP312BjqXjAjvvPvovi70l+rV1NLjt1CMz5CqMWbn8NC016lrysklZr+xdl0xEO+FO/NcfV6XaurflHZb/9muy26Qh6dl9zT1dax9khIs+T0Q7/tiTa3Vn6WXL27mvSrvTyd446f90SXDaf8wvNf1f+sP3wcik0=:D4BB
// ^FO0,32^GFA,00512,00512,00008,:Z64:
// eJy9kDEOwyAQBJdQUPoJfCSyv0URKUT+GE+hTkVJYXGGw6s0qY2QRnDH3i7APctKNU1ZFknKTcpgFjk6k2mjwSZb8R50GT52Lgl9d36aG2fvZB/1deqkp5es3KwyrEbvwwtRGXT+t4bLR/lL1tn/e3/pUZ/zOJ9+6I9+6Z95mA8zb8TMD6jOA9D/uXuduSpgZg==:4209
// ^FT33,191^A0N,20,19^FH^FD${id}^FS
// ^FT130,191^A0N,20,19^FH^FD${pin}^FS
// ^FT59,39^A0N,14,14^FH^FD${model}^FS
// ^PQ1,0,1,Y^XZ
// `
//         case 'G1N':
//           return `CT~~CD,~CC^~CT~
// ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD30^JUS^LRN^CI0^XZ
// ^XA
// ^MMT
// ^PW203
// ^LL0203
// ^LS0
// ^FO0,64^GFA,01152,01152,00012,:Z64:
// eJxjYBgFZALZADiT+f9/OJs1gN8BxrZrYH4BY4cyMFyBsf8BpaBMRqApolA2E1AxfwPUGAcIBgH+B0ArHiDEGaE2yx8AikMtEGVAiNsDzWD8gRBnCIGKy7c//PEHKi7qGxgCE7d6ZzzrH4QdYnFEUAYqXmfBw9wAExdlYXSAiXP3/PhRB2GHMboElMDEGd9YyEDFQwRcREUQ5lvPg4qLm7oWwtTb9bf9hJkj2oDkThAbGkCioDAwgYYD0L8MfIhwYBCBsNlBYcMGYTMjYgUeBiAgAA58VpAJDBlgO9VBJjA4gAOIFazGgR+kQQWsxoMxNDQEGgMWDPb//zdZH4CZJZqAMJf/BYLNGoJgM/9Dckcogs3wvwHBBnsOZtADJA1InhjeAABKbUWe:C8E3
// ^FO0,160^GFA,00512,00512,00008,:Z64:
// eJxjYBgkwPIfhJb8D6WbGyB0Yw9EvrkPym/ATsPUN0P1/+OB0H94aObmYQAABa8NJg==:1E7E
// ^FO64,160^GFA,00512,00512,00008,:Z64:
// eJxjYBg0gPGfcROY/m/YDKabrQ+B6UbB4xJAmrnZ+LEFRN4Yqn4XmG4QlIfQxvMgtCE/hLbuk4DIs0vQ2S9DBgAAPYMQVw==:D8EC
// ^FO32,128^GFA,01536,01536,00024,:Z64:
// eJztkEFLAkEYhmdcaSdYUqHDCMoOCtGhw5CXPUQSQl36AR46SMZ6nQjEg+mQUBMInj31U1JWskNgx44LQWcXOgw0akO79gsSOvieXh4+Hl4+ANb5nynED9oZyoxAzcGlglPGWMi7rWKXEENqLpUhpayEfHRsbVNqZLkH+9xM9/vTkAuC9gmJId6BnMfgvSA//OqiadUZM2ZqAQMVh9lM5N87RDnt96UEDfkBN1F4Xzgwrbb2Q/4C0vwzlcY05PgcFbUf8BsoeCy99BcyLeuoXTSgGsMZN4NgGvrzloOGj8UYUB73OfArFbL6Z/5lthIT30XOfE497ytxi5yI2/bEr2HVXtCTYGGPsYo4FgjkkcAd2qV55x2JiGewBWpvAzxmp8kq0+3X3/NdIdGcPoMq2REy4ubDZOCWm1jRM1Ci9XJzOUj7c7sW7lJB8kS3CKdeLeBej5Lj5IhVt3WLeKKn9zsKefadX008Ocudpj2ZuriBhzYclMwN3FjB79b5Z/kGe6GIpg==:C9A7
// ^FO32,32^GFA,01536,01536,00024,:Z64:
// eJxjYBgFo4AiwP7wQIHgB4YCIFEjU5fIVMMCEWdjsu6Q7GDo+MDZcyAtIbkpmQcizsLCyCDIwsARwMlywMGhkMVRBqqejYlBsq2BYwZnT0NCQjVTsg5MPRNQvYNAgCBLjYFDIJMj1Hz+d0D1fQwWHZw/GuTq8pnqeJDVMxT2cAocYHNwZ3NhQTK/j+Hhj86OBqaEdKUkmHomQQmg+QdZJrI4uDk4qzjD1DM+eCH548Dvvod9/2wSknck99A2HEfBKKA7AADIzjI2:98FE
// ^FO0,0^GFA,00512,00512,00008,:Z64:
// eJxjYBgh4PEBBgPGBgbGhyAaxD/cvODhDAbGTcePf/wIos+lGUsD5becUTH+2cDAsO+EnjKQZpxzxsUQqI9hzvnHn38C1ckcbtxYOGOAPUM9AACajyDH:DD9E
// ^FO0,0^GFA,00768,00768,00024,:Z64:
// eJztkDFLw0AAhV9sSQfLmZu8QiTB20uky0GL14B/pPQPtNDB4cCDDjocdY34L5wKDs1w0CX4GwIBXQouDgWPYOhvKLj0G97weDweDzhxVMRBh2BdCCFQPZeEkFtAHfwYrA2lFLbbPIoiCRSiT8j5L2E7FEXhfcT58P2NA7l6aALzqOdgtfbuxqA0vgH2nzwZZHzwusJ+vT77KnWr1Zcao3oaJhmXL23cO7dIJx3fl1cAqa8v4qfGNzAr813yR9PlSw2bpx7NJkHPwFrb+UkRBJQCruZSNj2Zg3MOVQW52TT7Z6Np6DN+yRaYJQnSJaQMw/98+ij8AZctSzA=:0CBA
// ^FO0,128^GFA,00256,00256,00008,:Z64:
// eJxjYKANYGxu4uOwYWBgT3GdaJbAwMCk5tGopMDAwJwSOdG4Aijf4jFRiQMkH3OxnAUorwLkswH5JrETzUF8pY4uJQ+guuPH2WRqaORIIAAALA0QUg==:DF83
// ^FO64,64^GFA,01920,01920,00020,:Z64:
// eJztkk9rE0EYxp/ZHWYXqem2oK6wNZsESo+TFmpPdo3b0EMOvfXiYRHNeatgL2l5m3hYpYhfxW+wAUEPKXr0UDDgF0gQxIOos5v9k7O1eOk77DA8/HjneZ9Z4KouVOMTWoxQwSKB7qHaVxJbGZL1DkuwYhY3IQylaSAyPTC4YJ5LmlCazk7I2jllkNeZ1yRecLuRDtfUXMVdU5qRcP65gWcjI5CxuJVznbgC90PKLef9/D0HsmN8e0u8mngxKTIj15Z2xGUt0rTE83RoV3lYlUaHW9u20GeTmOVQxdEqtmy/jPyqxAhjRFv4lV6c5MdgBIaNURu2g2xe5d1VuUUE00Y2r7iLkLPxGc1xfB0BByXcGrL8FjYQ8yM6i+F0cs5sIBZmyu3m/awmI36bRjHsHrL8ZEPXNJuilmvOglH5bR8Y39mURr3AXpgLLY1XvfF8fjw9eZcTH8SWik7/odx/pUqm8Sf7cnZj98ZBplUet9VO6mvxINOcHY4Q9z+FeP8m97fsczaY9GuDCVGubfrJv+kdgsVerq36XB9MBhuKCzJN/+0n/R7IkKHgtDVNeelLsBfUyP312BjqXjAjvvPvovi70l+rV1NLjt1CMz5CqMWbn8NC016lrysklZr+xdl0xEO+FO/NcfV6XaurflHZb/9muy26Qh6dl9zT1dax9khIs+T0Q7/tiTa3Vn6WXL27mvSrvTyd446f90SXDaf8wvNf1f+sP3wcik0=:D4BB
// ^FO0,32^GFA,00512,00512,00008,:Z64:
// eJy9kDEOwyAQBJdQUPoJfCSyv0URKUT+GE+hTkVJYXGGw6s0qY2QRnDH3i7APctKNU1ZFknKTcpgFjk6k2mjwSZb8R50GT52Lgl9d36aG2fvZB/1deqkp5es3KwyrEbvwwtRGXT+t4bLR/lL1tn/e3/pUZ/zOJ9+6I9+6Z95mA8zb8TMD6jOA9D/uXuduSpgZg==:4209
// ^FT33,191^A0N,20,19^FH^FD${id}^FS
// ^FT130,191^A0N,20,19^FH^FD${pin}^FS
// ^FT59,39^A0N,14,14^FH^FD${model}^FS
// ^PQ1,0,1,Y^XZ
// `
//         default:
//           return `CT~~CD,~CC^~CT~
// ^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD30^JUS^LRN^CI0^XZ
// ^XA
// ^MMT
// ^PW203
// ^LL0203
// ^LS0
// ^FO0,64^GFA,01152,01152,00012,:Z64:
// eJxjYBgFZALZADiT+f9/OJs1gN8BxrZrYH4BY4cyMFyBsf8BpaBMRqApolA2E1AxfwPUGAcIBgH+B0ArHiDEGaE2yx8AikMtEGVAiNsDzWD8gRBnCIGKy7c//PEHKi7qGxgCE7d6ZzzrH4QdYnFEUAYqXmfBw9wAExdlYXSAiXP3/PhRB2GHMboElMDEGd9YyEDFQwRcREUQ5lvPg4qLm7oWwtTb9bf9hJkj2oDkThAbGkCioDAwgYYD0L8MfIhwYBCBsNlBYcMGYTMjYgUeBiAgAA58VpAJDBlgO9VBJjA4gAOIFazGgR+kQQWsxoMxNDQEGgMWDPb//zdZH4CZJZqAMJf/BYLNGoJgM/9Dckcogs3wvwHBBnsOZtADJA1InhjeAABKbUWe:C8E3
// ^FO0,160^GFA,00512,00512,00008,:Z64:
// eJxjYBgkwPIfhJb8D6WbGyB0Yw9EvrkPym/ATsPUN0P1/+OB0H94aObmYQAABa8NJg==:1E7E
// ^FO64,160^GFA,00512,00512,00008,:Z64:
// eJxjYBg0gPGfcROY/m/YDKabrQ+B6UbB4xJAmrnZ+LEFRN4Yqn4XmG4QlIfQxvMgtCE/hLbuk4DIs0vQ2S9DBgAAPYMQVw==:D8EC
// ^FO32,128^GFA,01536,01536,00024,:Z64:
// eJztkEFLAkEYhmdcaSdYUqHDCMoOCtGhw5CXPUQSQl36AR46SMZ6nQjEg+mQUBMInj31U1JWskNgx44LQWcXOgw0akO79gsSOvieXh4+Hl4+ANb5nynED9oZyoxAzcGlglPGWMi7rWKXEENqLpUhpayEfHRsbVNqZLkH+9xM9/vTkAuC9gmJId6BnMfgvSA//OqiadUZM2ZqAQMVh9lM5N87RDnt96UEDfkBN1F4Xzgwrbb2Q/4C0vwzlcY05PgcFbUf8BsoeCy99BcyLeuoXTSgGsMZN4NgGvrzloOGj8UYUB73OfArFbL6Z/5lthIT30XOfE497ytxi5yI2/bEr2HVXtCTYGGPsYo4FgjkkcAd2qV55x2JiGewBWpvAzxmp8kq0+3X3/NdIdGcPoMq2REy4ubDZOCWm1jRM1Ci9XJzOUj7c7sW7lJB8kS3CKdeLeBej5Lj5IhVt3WLeKKn9zsKefadX008Ocudpj2ZuriBhzYclMwN3FjB79b5Z/kGe6GIpg==:C9A7
// ^FO32,32^GFA,01536,01536,00024,:Z64:
// eJxjYBgFo4AiwP7wQIHgB4YCIFEjU5fIVMMCEWdjsu6Q7GDo+MDZcyAtIbkpmQcizsLCyCDIwsARwMlywMGhkMVRBqqejYlBsq2BYwZnT0NCQjVTsg5MPRNQvYNAgCBLjYFDIJMj1Hz+d0D1fQwWHZw/GuTq8pnqeJDVMxT2cAocYHNwZ3NhQTK/j+Hhj86OBqaEdKUkmHomQQmg+QdZJrI4uDk4qzjD1DM+eCH548Dvvod9/2wSknck99A2HEfBKKA7AADIzjI2:98FE
// ^FO0,0^GFA,00512,00512,00008,:Z64:
// eJxjYBgh4PEBBgPGBgbGhyAaxD/cvODhDAbGTcePf/wIos+lGUsD5becUTH+2cDAsO+EnjKQZpxzxsUQqI9hzvnHn38C1ckcbtxYOGOAPUM9AACajyDH:DD9E
// ^FO0,0^GFA,00768,00768,00024,:Z64:
// eJztkDFLw0AAhV9sSQfLmZu8QiTB20uky0GL14B/pPQPtNDB4cCDDjocdY34L5wKDs1w0CX4GwIBXQouDgWPYOhvKLj0G97weDweDzhxVMRBh2BdCCFQPZeEkFtAHfwYrA2lFLbbPIoiCRSiT8j5L2E7FEXhfcT58P2NA7l6aALzqOdgtfbuxqA0vgH2nzwZZHzwusJ+vT77KnWr1Zcao3oaJhmXL23cO7dIJx3fl1cAqa8v4qfGNzAr813yR9PlSw2bpx7NJkHPwFrb+UkRBJQCruZSNj2Zg3MOVQW52TT7Z6Np6DN+yRaYJQnSJaQMw/98+ij8AZctSzA=:0CBA
// ^FO0,128^GFA,00256,00256,00008,:Z64:
// eJxjYKANYGxu4uOwYWBgT3GdaJbAwMCk5tGopMDAwJwSOdG4Aijf4jFRiQMkH3OxnAUorwLkswH5JrETzUF8pY4uJQ+guuPH2WRqaORIIAAALA0QUg==:DF83
// ^FO64,64^GFA,01920,01920,00020,:Z64:
// eJztkk9rE0EYxp/ZHWYXqem2oK6wNZsESo+TFmpPdo3b0EMOvfXiYRHNeatgL2l5m3hYpYhfxW+wAUEPKXr0UDDgF0gQxIOos5v9k7O1eOk77DA8/HjneZ9Z4KouVOMTWoxQwSKB7qHaVxJbGZL1DkuwYhY3IQylaSAyPTC4YJ5LmlCazk7I2jllkNeZ1yRecLuRDtfUXMVdU5qRcP65gWcjI5CxuJVznbgC90PKLef9/D0HsmN8e0u8mngxKTIj15Z2xGUt0rTE83RoV3lYlUaHW9u20GeTmOVQxdEqtmy/jPyqxAhjRFv4lV6c5MdgBIaNURu2g2xe5d1VuUUE00Y2r7iLkLPxGc1xfB0BByXcGrL8FjYQ8yM6i+F0cs5sIBZmyu3m/awmI36bRjHsHrL8ZEPXNJuilmvOglH5bR8Y39mURr3AXpgLLY1XvfF8fjw9eZcTH8SWik7/odx/pUqm8Sf7cnZj98ZBplUet9VO6mvxINOcHY4Q9z+FeP8m97fsczaY9GuDCVGubfrJv+kdgsVerq36XB9MBhuKCzJN/+0n/R7IkKHgtDVNeelLsBfUyP312BjqXjAjvvPvovi70l+rV1NLjt1CMz5CqMWbn8NC016lrysklZr+xdl0xEO+FO/NcfV6XaurflHZb/9muy26Qh6dl9zT1dax9khIs+T0Q7/tiTa3Vn6WXL27mvSrvTyd446f90SXDaf8wvNf1f+sP3wcik0=:D4BB
// ^FO0,32^GFA,00512,00512,00008,:Z64:
// eJy9kDEOwyAQBJdQUPoJfCSyv0URKUT+GE+hTkVJYXGGw6s0qY2QRnDH3i7APctKNU1ZFknKTcpgFjk6k2mjwSZb8R50GT52Lgl9d36aG2fvZB/1deqkp5es3KwyrEbvwwtRGXT+t4bLR/lL1tn/e3/pUZ/zOJ9+6I9+6Z95mA8zb8TMD6jOA9D/uXuduSpgZg==:4209
// ^FT33,191^A0N,20,19^FH^FD${id}^FS
// ^FT130,191^A0N,20,19^FH^FD${pin}^FS
// ^FT59,39^A0N,14,14^FH^FD${model}^FS
// ^PQ1,0,1,Y^XZ
// `
//       }
//     default:
//       switch (model) {
//         case 'S1-U':
//           return `^
// XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR4,4~SD30^JUS^LRN^CI0^XZ
// ^XA
// ^MMT
// ^PW300
// ^LL0300
// ^LS0
// ^FO96,192^GFA,01536,01536,00024,:Z64:
// eJztkTGLE0EUx984A3PFspuARQKLK+QLzJpmT+HWa/RrhGyRNkHLg7zLCrFxt4143X2JgM0Me3Ifw41biI0YrglcnPVN9ANYyNncq/78+M9v3jAA93MXc1zll+2jIw7Bpk1rfmnhqR8gQt/ITRuGEuS2jX7IzZ7ZKDhH6DXFSJ0cPQDvGJPHfoa8eLaqqL8VU/WQ+qKPHSVmwKC7+oSQfCxBPelx8KmvAo4chutv1H8vtcr++Gtpdhzi1Sn5P+S1ysgfbGyKvDnwmvrvYKrmzs+wq2EGW+KGeFko1b4lP8Ok9od4uxge+FIodXpx6HdGIga2jPvEe3lZK1M2O4+162nQINiodO89Zzo2n7/uBGs7ihmAM08y/N/f89fDzH6uX4pA/tx3pmfRPpIicDxo7LzOirS8tYmyqU3zIkXi4hWMYPImRiNVvORidk2ZuD/AEWRFoqs8GSy4VzeUiUvm+lchGNmZAfe0y46TH8ffU6xsMgDu1y67fW72c5y8ltrs1BC40C67A0Pyj09AVxfJAMnjMmGvS/4XodLGO/ifu+z2+d1P3B7ZkvxfKLt7b+xcT8JIGxkZy0V6TZk4p3302Pexyktcc89fUL67b7iffzS/AMG8wNQ=:E926
// ^FO96,32^GFA,01536,01536,00024,:Z64:
// eJztkbFKw1AUhv8k5VyHkMXBopFGAs5262Tq1MWHuJAh67046FC8l2Zw8QF8nFsKzSL4Ag7XVJzTrcM1mvgSBem3HPj5OJzDDxw48P/x3MhrSAHzCIVTy49WcOkYApeVO1IajxHyVpVNe8XTluBBVjFJg/UIs4FgY/MwlWyEAOb5yE9yLM5QU+IP9cSmwWmfhyBRgI5RkGA3Zj69YycIdn3OOaKx3jhVWnfZ7ffhfZsYTAjEYz1r1HLr7qd/92gdwk8STDJdT7qhU5u+dD7M7YAEh1DvRSzo3MhPGXY++DokbsH5ax1yGuqLr5x6Xz11f63ww9+qSK22RlZb1vsRrH8doLWbBWXa6mRTBot913NgT/wCsE9hvA==:B4D8
// ^FO0,0^GFA,02304,02304,00036,:Z64:
// eJztUrGO1DAUHMdW4iJsUqaIlMAXBF2TAm2cE4IGiV/Y3BekoKAArX05RRQooqfg+AskGt8iXYUEf4B1S71KmSqHE/bo6JBoMsWzpTcaz3tjYMGCv4H0x8sLuBCIiUmA9FEyoPbw0ptbwXDkHOBAomiGQsKMyUDGxIyBnlq+f9STYNAoL6JSQl8hJG5YtZGYenz1hzPpmI4bST82MqVOdtLk2cyB59Yx0e9/em4sIC6sKVIpWylOkIXzW5OFMTCfD8X5WqJ6Yzo496XUVvZBns46ns6exqUfXrHscaWR3Ot/gFXKchhOX4WzDh3SM254rtr0bGPn6vK95OleSaywb/N6Nhtvd6wOI8W210KgfMv083eb14r0EWlo8n3mrLY3fB1x1W1vjJ36q6+/fRjyRg05PafB5USJmGC+N+kIJjTEM6aFXSABBLN19sPd1OH00+THsX7MwTcGadOAXEa0ydN5dpad+qWfKZY9eWj33EaVxOYLhdUgbbT5nVex47eBUV2xW9/llY4O6Fjc5UV6j/l2z4rZU9vcdQxsYsu5Lfra+4c/bMGCBQv+F34BTt+ITA==:35D5
// ^FO128,224^GFA,00768,00768,00012,:Z64:
// eJztjrENhEAMBH0i+JAOoA0HC67rJQjo7OiAEkwHkBG8xB86W1wFL4J3tB6tdpfof8+8cC5AiPk5D+6DGv9ATFOhgw6QyfzasEy6Zd5A5qxJe8jqfEC3x83zeb3zsXv+AbydR2CM1kXMnfcS0Jr/0nXJfU/y196b+Mv3JF0VvLI9P7svt+hGNw==:557E
// ^FO0,224^GFA,00512,00512,00008,:Z64:
// eJxjYBgFqKCi/guYtrD/B+X/h/L/N4D5FewHwHwL5gMPwHzmgx8g/IMQPiOYBvIfQNQzQtUzovAr4PLMMPNQzQfa9wBiP1gj0D2MEP4HiEFUAQCI/iki:E180
// ^FO0,0^GFA,00768,00768,00012,:Z64:
// eJxjYBgFgx/Isx9gYChgMACx+duB7AcMFkjiEmB2/wMQGywu23qwgK2gwCIByObrPfzD/sE/C6Asg0zvcRmLj20SDiDx7nN8Fo/bweplIo+xFRT/B7N5fp7jK0iGsGUKj7AUFELMt/v9js/iM1R9IdCcwmNg83kqkcwvPPCBp6AAbP7QAADiQTF3:3AF5
// ^FO0,192^GFA,00512,00512,00008,:Z64:
// eJxjYBgFEHDw4AULEH2g+QOYPnjcoAbMb7LIAVKMB483+EDkHxyDqP8Apg80WPwD849J/gfRD5skm0F0PftHMM3H8Li5gZ4eIQkAAHoYHIg=:BEAB
// ^FO32,192^GFA,00256,00256,00008,:Z64:
// eJxjYBgwYAGlJdDEJedAaMt/UP4xKN0HpVug8m0QWrANuzxRAAA5cwb6:DBB9
// ^FO0,192^GFA,00256,00256,00008,:Z64:
// eJxjYBggwPjxA4MMiPHgAQMPiP4IpR8ufvDGAih/8fvHPzVA/m0l455DIDqhnPc/kD6l/IjnH5C+X14sewBIn1NT7jkGpI+lQ9QfUzYA6ScSAADv6CBZ:C9B5
// ^FO0,160^GFA,00768,00768,00012,:Z64:
// eJxjYBgFxAM7Pv7m5gc/wOw6ef72ww8/gNnJ1jP6nhk3QNiGAkfSDCHqkzfOOPPO+gGYLWM440yaIES9neGON2nGFlD1E3zSDCTA7Mc7Qeoh7GRBmTNuhRC2nT1//+PHEHtl2NiZGQ8k0NSfIwcAADfkJIo=:4638
// ^FO96,64^GFA,04480,04480,00028,:Z64:
// eJztls9rG0cUx9/siBkdFq1728AGLQn0WEZRKSoES1DIOX9ADpsEVtcRCvGldMcW2D4Yes0hkEP/AUMuhUIZV6C9hEDvhiw2JD30oOBSq2QT983sSt51m0NLKSn1w17N6sObNz++7z0BXNql/TPGoQERgAYu8Y1kVUaBFcwR5u0PrBgYRmpz0u7X393L5pPN6Y0m3ZxOavH6L9rDt3n66nQj8NLTF7rq15v14s93n8fdru/G3WaNdWZrwys/HpJxZ80jo3aNiW1xz1/s0pPOPp2ePKmzmRj6d57xjdEchiMfLrD4yt7z+LrI3Pj6hXgpxnubvjrpZF76+kK8yT7Gm0yeioxOnlL4c9Pv+d7MoP4e+wDsSxlAgne/BhxFo/kv0H6tZcHeiSYk2wACvDnwOV+A9yYrGNkRPow0gIQA/3o8wK/kbcuoQhbGU9iHHnzbBIcBUeGjggHGk/GQCJBkEQBHpg8OS4bxovgexhOgfWDGL9tdMh/kww1cZ+c3ZJwjG+2ds/DjG8ZvoXxy5CDr5JZxZuN9ZthVHXx0inNuj98t94DxXt5E5veU22MtZN0iD8iZuKKTWQBrAzwP3Hsuyc4pL86lJ3y1zpqAclGKKpqFZHPLWR3pwD6j8m2tdtyqxsIqIlXv888PzJZ6kUYvVKMw2nNdsqVeFkYvVHkL6GclW+qFPDZ64doNgJKSFfeHetkzenGU2wRKS8ZLvYCLesnZwHXx6s/9Cr00jV7cCBll1TmNXnyjF1ca1qjFW+rFO/4Z2bJY0EIvn+IVoQDaJ8h4e17uYbvUizZ6aQpcp+NlJSv1QnKjF194iwFdVYxSLyQ3eun1vCyj1WoysM+ofIugaur97L+hF5lgWUkJphAmOrQB4kSTs6BkCrxjUMiwG/U1xPh/9r1lt7HOBGOiTGGSRjlDOQB/w7JHoRsJiqzbw1aWbWXXwgh8m4DkULJMcgw1zpkEzbPY+LnWbzfZ0YLimR7vMVQMxXgK/Jb120umysxJpgETZPoKHuLCfdsLSB7Sgk2a6Dc9AhvPMvpOci2GuD/uYrzBEGKJ9XBoGYuY9segGHVZCNERxMbvvmGcJ88G7RPymJ8E/LXqpzBMUvATwxynvxvSCdn13jTJpmo5cK2/BX5QPdaGXVgxrndrwHQ4N+cCW6s4NP7SRf5LZusLXj2QObDoAVYXuWK2vgCsA82B3c/BW0RLZOsL7msM2InYGN0350tm85121XVoNT9xroHpKktm6wvva44F5isWI9uUFb8mtHqaIROWbUW1OYMO+gXrkg/NAmSN+UK54K6HNt52uGK2vgh9ExpMsmEDSLpaZ9GPhBbAWiG7j91oZ7Fitr50dOLTXLIRdiN1fi6mvpztq8SHLHTCRYjdtnqkA/Mo21C9Gy3rS2FhHZGqwwA+VDvQ+gHeo2np3vHcjM9tevRNbnLUMjteGUlHaUBMScXHFyStZhf9oeMAfZJFC9rXW6SWQ3w2ugXt9vxufic5SA9uVRmbCYYtDKK9DMgkY1XWMAyvLLqKTZCJKqM/zYQDvTbGm/enIKrx+NmvkjdMvMM7ScoEryat9zKkLfx9eXeX448sQVvVgOmc5Li1u/jgHMe1M1NglBU9wwJBivGlXdr/1H4HdZSgqg==:0327
// ^FO0,96^GFA,01152,01152,00012,:Z64:
// eJzt0r1qwzAQAOATHoSW6gVE/CKBvFjAgg5ZCnmFPkaHDoYOHfMKMi14CbW9aRBW5buTJejQqVsPDx+O7idnAfzHbyHjvPsU193RRctsVtCOrT00nt06ECGn9gAdu0uJJ06O27keKbbiXKgJlbd6kqaQPr9IP8/5IJubYZpY8wgp2H3t+UG4SKO9+dPFs99Dlx7yxSw3w76aYTKdpfcwTJBtnbbZzsme/TiPHzPbhnHw2efRnbPN4I7s62H4yvVvh6XYLBOfadNgY2CnmUdPS6T/SNY/3KJpfbW13XebjeuTaFplg6aVCXL+hqUNVMtVr1gOG6g7lsMG6hlL4I7VCxWK0YF6ouQYo1Wf6CbZ6ePeI1+m7Vgo1uUmppRisd/KFLEv7lw1eLnS0FYNZNUAqgZ/Fd+hGJ3F:4F5C
// ^FO0,32^GFA,01152,01152,00012,:Z64:
// eJztkjEOwyAMRR0xMLJ2qOQeoSdortIjdOyW5GYchSMwMiBcJ9gJqB07dKgF0hMC8/1tgH/8TiARWJqFC4wUlQeiLJwN8b3KyTFvD5BXBvLCY1hX5WkGF4ULgEmVB044ZOH1TNhEQ/CsbIMjf1EeKdR/wflJtYGbiZLyUozoBFwS6DmaCPedg9YLaA++Wb/7UL7Dbc7ur04Da3t80NzW0tXY1N56snl1ffdw87YIN563veh6xI3DIIzp6Gnb630GzpxQZ+PEW7Wt4WSWfjde3GiGag==:F960
// ^FT215,279^A0N,29,28^FH^FD${pin}^FS
// ^FT59,279^A0N,29,28^FH^FD${id}^FS
// ^FT97,50^A0N,21,21^FH^FD${model}^FS
// ^PQ1,0,1,Y^XZ
// `
//         case 'G1N-U':
//           return `CT~~CD,~CC^~CT~
// ^XA
// ~TA000
// ~JSN
// ^LT0
// ^MNW
// ^MTT
// ^PON
// ^PMN
// ^LH0,0
// ^JMA
// ^PR3,3
// ~SD30
// ^JUS
// ^LRN
// ^CI27
// ^PA0,1,1,0
// ^XZ
// ~DGR:G5410010.GRF,1488,16,gR0::::N01H5gG0M0BJFAY0L01LFY0K02IFABHFEX0K03HF4H01HFCW0K0HFEJ0BFEW0J01HFL0HFW0J0HFEL03FEV0I01HFCL01HFV0I03HF8M0HF8U0I07FCN07FCU0I0HFEN03FEU0H01HF8N01HFU0H03HF8O0HF8T0H07HFP07FCT0H0HFEP03FET0H07FCP03HFT0H0HF8P03HF8S001HF8P01HFT003HF8Q0HF8S001HFR07FCS003FER0HFES007FER07FCS007FER03FES007FCR03HFS00HFE0HF80FE80HF83FES007FC1HFC1HFC1HFC1HFS00HFE3HFE3HFE3HFE3HF8R00HFC1C1C3C1C3C3C1HFS00HF83C3E3C1E3C3E1HF8R00HF83C1C7C1E3C1C1HFS00HF83EH2782A3E203HF8R01HF81FH078H03EH01HFS00HF83F8078H03F801HF8R01HF81FC078H01FC01HF8R01HF80HF878HA0HF81HF8R01HF803F8787E03F01HFS00HF8H0FEF8FEH0FC0HF8R01HF8H07C785EH07C1HF8R00HF8203E783E203E1HF8R00HF8341E781E701C1HFS00HF83C1E381E383E0HF8R00HFC3C1C3C1C7C1C1HF8R00HFE3EBE3E3E3EBE1HF8R007FC1HFC1HFE1HFC1HFS00HFE0HF80HFE1HF80HF8R007FC054H0H54054H0HF8R003FEL02L0HF8R003HFS07FS003HFS0HF8R001HFS07F8R002HF8R03F8R0H07F8R01FS0H0HFCS0F8R0H07FCS078R0H03FES078R0H01HFS01S0I0HF8S08R0I07FCgL0I03FEgL0I01HFgL0J0HF8S0623CN0J07FCS043H4N0J03HF8R062E2N0J01HFCR0637O0K0BFE8Q06238N0K01HFDQ0610CN0L0BNFE8I0632EN0L017NFCI063H4N0N0OF8H03IEN0T05HFCI041O0T03IF8S0U07HF8S0U0IFES0U01HFES0U03IF8R0V07HFCR0V0BHFER0V01IFR0V02IF8Q0W07HFCQ0W03HFEQ0X07HFQ0X02HFAP0Y015Q0gR0::
// ~DGR:G5410004.GRF,96,4,N0:::71HFJ071HF8I071HFCI071C1CI071C1E38071C0E380:71C0EI0:::71C1CI071HFC38071HF838071FE0380N0::::
// ~DGR:G5410007.GRF,1536,32,iJ0:::7381C0F83HF81F01CH0EI01HF07HF1HFI01F03HFI07C01E01EH07N073C1C3FE3HF81F01CH0EI01HF87HF1HF8H07FC3HFH01HF07F87F807N073C1C7FE3HF81F01CH0EI01HFC7HF1HFCH0HFC3HFH03HF87387380FN073E1C70F03803B81CH0EI01C3C7H01C1CH0E1E38I03C3CE1CE1C3FN073F1C70703803B81CH0EI01C1C7H01C1CH0E0E38I07818E1CE1C7FN073B1C78H03803B81CH0EI01C3C7H01C1CH0FH038I07I0E1CE1CH7N073B9C3FH038071C1CH0EI01HFC7FE1HFCH07E03FEH07I0E1CE1C47N073H9C1FE038071C1CH0EI01HF87FE1HF8H03FC3FEH07I0E1CE1C07N0739DC07E0380E0E1CH0EI01HF07FE1FEJ0FC3FE3F7I0E1CE1C07N0738DCH0F0380HFE1CH0EI01CH07H01C7J01E3803F7I0E1CE1C07N0738FCE070380HFE1CH0EI01CH07H01C7801C0E3803F7818E1CE1C07N07387CF0F0381IF1CH0EI01CH07H01C3801E1E38I03C3CE1CE1C07N07387C7HF0381C071HF8HFC01CH07HF1C1CH0HFE38I03HF873873807N07383C7FE0381C071HF8HFC01CH07HF1C1CH0HFC38I01HF07F87F807N07381C1F803838039HF8HFC01CH07HF1C0EH03F038J07C01E01EH07N0iJ0::::::::J03E07HF1HFC039C0E07C1HFC7FC1C0E07C0HFE703F0381C0F8Q0J0HF87HF1HFC039E0E1HF1HFC7FE1C0E1HF0HFE70HFC3C1C3FEQ0I01HF87HF1HFC039E0E3HF1HFC7HF1C0E3HF8HFE71HFE3C1C7FEQ0I01C3C7H01CI039F0E38781C07071C0E3C3C0E071E1E3E1C70FQ0I01C1C7H01CI039F8E38381C07071C0E78180E073C0F3F1C707Q0I01EH07H01CI039D8E3CH01C07071C0E7J0E0738073B1C78R0J0FC07FE1HF8039DCE1F801C07HF1C0E7J0E0738073B9C3FR0J07F87FE1HF8039HCE0HF01C07FE1C0E7J0E0738073H9C1FEQ0J01F87FE1HF8039CHE03F01C07F81C0E7J0E07380739DC07EQ0K03C7H01CI039C6EH0781C071C1C0E7J0E07380738DCH0FQ0I0381C7H01CI039C7E70381C071E1C0E78180E073C0F38FCE07Q0I03C3C7H01CI039C3E78781C070E1E1E3C3C0E071E1E387CF0FQ0I01HFC7HF1HFC039C3E3HF81C07070HFC3HF80E071HFE387C7HFQ0I01HF87HF1HFC039C1E3HF01C07070HFC1HFH0E070HFC383C7FEQ0J07E07HF1HFC039C0E0FC01C070383FH07CH0E0703F0381C1F8Q0iJ0::::
// ~DGR:G5410003.GRF,456,24,hN0::7F83E03EH0CFEJ0F01CH0CHF0HF87C3FHC0C067F80CI07F8HF0HFH0CHF8H01F81CH0CHF8HF8FE3FCE0C067FC1CI06H0C38C380HC18H038C36H0HC1HC01C73H0F0C0H60E3CI0601CH1C1H0HC0C6030C36H0HC0HC018H3H0F0C0H60H6CI06018018I0HC0C6I0C36H0HC1HC01E03H0D8C0H60E4CI07F18018I0HC0CI01863H0CHF8HF8FC3F8IC067FC0CI07F18018I0HC0CI03863H0CFE0HF83E3F8IC067FH0CI06018018I0HC0CI0707FH0HC70CI073H0C6C0H6380CI0601CH1C1H0HC0CI0E0HFB0HC38C018H3H0C3D8H61C0CI06H0C38C380HC18H0180C1B9HC18C01C73H0C3DCE60C0CI06H0HF0HFH0CHF8603FHC19F8C1CHF8FE3H0C1CFC60E0CI06H03E03EH0CFE0603FD80CF0C0EHF87C3H0C0C786070CI0hN0:::
// ~DGR:G5410006.GRF,864,36,iR0:::07CU01FEI07H038I07E03E03EI01C0783HFH01F81F80F80FN01HFU03FEI07H038I0HF0HF0HFI03C1FE3HFH03FC3FC3FC3FCM03HFU038J07H038H01E78E78E78H03C1CEH07H079E79E39E39CM03878T038J07H038H01C39C21C2I07C387H0EH070E70E70870EM038383C1CF03F01F0H7FHE0F0707B8H01C39C01CJ07C38701CJ0EH0E7H070EM03CH0HF1HF87F87FC7HFHE3FC71HF8I0E71DE1DEI0DC38701CJ0EH0EH7870EM01F80E71E3CE1C71C7838E39C71C78I07E1HF1HFH019C387038I01C01C7FC70EM00HF1C39C1CEH0E0E7038E70E73838I07E1E71E7H019C1CF038I03C03C79C39EM003F1HF9C1CFC0E0E7038E7FE73838I0E71C39C39FB1C1HF0703F07807870E3FEM0H079HF9C1C7F8E0E7038E7FE73838H01C39C39C39FE1C0F70703F0FH0F070E1HEM07039C01C1C0FCE0E7038E7H073838H01C39C39C39JFH070703F1E01E070EH0EM07879C39C1C01CE0E7038E70E73838H01C39C39C3807HF1870EI01C01C070E30EM03HF8E71C1CE1C71C7038E39C71C78H01E78E70E7I01C3CE0EI038038039C79CM03HF0HF1C1C7F87FC7038E3FC71HF8I0HF0HF0HFI01C1FE0EI07FE7FE3FC3FCM00FC03C1C1C3F01F07038E0F0707B8I07E03C03CI01C0F80EI07FE7FE0F01FN0iR0::::
// ~DGR:G5410005.GRF,288,12,gJ0:::7C1FK01CI0EO07E3FK01CI0EO0:7637K01CI0EO0763707C03DC1E0E38M076371HF0HFC7F8E38M0J71C70E3C738E38M0J73839C1CE1CEO073673839C1CHFCEO0:73E73839C1CEH0EO073E73839C1CE1CEO073E71C70E3C738E38M071C71HF0HFC7F8E38M071C707C03DC1E0E38M0gJ0::::
// ~DGR:G5410002.GRF,152,8,V0::3C1E0F0783C3FI07E3F1F8FC7E3FI0C373B9DCHE73J0C361B0D86C36J0C361B0D86C37EI07E61B0D86C37FI07E61B0D86C3638H0C361B0D86C3018H0C361B0D86C3618H0C373B9DCHEH738H07E3F1F8FC7E3FI03C1E0F0783C1EI0V0:::
// ~DGR:G5410008.GRF,1824,24,hN0::0F8603I0FE0CI01EJ0FCJ07H01FCI0HFC18L03FC603I0HF8CI03FI03FEJ07H01HFI0HFC38L030E603I0C18J0718H0307J0D80183J0C038L0704603I0C0IC60618H070237H0D801818I0C078L06H0603I0C0IC6H018H06H03FH0D801818I0C0D8L06H0603I0C0IC6H03I06H038018C01818I0C0D8L06H0603I0C0HC6CH07I061F3H018C01818I0C198L06H0603I0C0HC6CH0EI061F3H01FC7D818I0C318L0704603I0C0HC6C01CI070H3H03FE7D818I0C3FCL030E603I0C18C3803J03073H03060183J0C3FCL03FC60H3H0HF8C3807F9803HF3H030601HF18H0C018L00F860H3H0FE0C3807F98H0FC3H060301FC18H0C018L0L01R08U08R0:L02Q01U01S0hN0:::0F8603I0HFJ078I03FJ0HC1FI07FE0CP03FC603I0HFJ0FCI0HF8I0HC7F8H07FE1CP030E603J06I01C6I0C1CI0HC61CI0601CP0704603J0C6F0186H01C08DC0HCE08I0603CP06H0603I01C7F8H06H018H0FC0ICK0606CP06H0603I018718H0CH018H0EH0ICK0606CP06H0603I03061801CH0187HCH0ICK060HCP06H0603I070618038H0187HCH0ICK0618CP0704603I06061807I01C0HCH0HCE08I061FEP030E603I0C06180CJ0C1HCH0HC61CI061FEP03FC60H301HF6181FE6H0HFHCH0HC7F8CH06H0CP00F860H301HF6181FE6H03F0CH0HC1F0CH06H0CP0L01P02S04V0:L02P04S08V0hN0:::H01878101FK01HF8L03FCFC101FT0H038FC7C7F8J01HF8L03FCFC7C7F8S0H039CE1061C02I018J04I018C01061CS0H0798628E080E7FC181F01CHF8318028E08S0H0D98H6HCH03C7FC183F878HF831F86HCU0H0D986H0CH0FJ018319EJ061FCH0CU001H986H0C01CJ01807B8J0618EH0CU07F1986H0CH0F07FC181F9E0HF86H06H0CU07HFD86H0E083C7FC1839878HF8E186H0E08S003FDCEH061C0EI0183181CI0C1CEH061CS0H018FCH07F802I0183F804I0C0FCH07F8S0H01878H01FL0181ECK0C078H01FT0hN0::::::K0707J06I018I03030F80EY0K0707J06I018I03031FC0EY0K078FJ06N030H38E1BY0K078F1F0761C019BC030H3061BY0K068B3F8FE3E019FE030H3C01BY0K06DB319CE63019C603031F8318X0K06DB079867F01986030307C318X0K06DB1F9867F019860303H0E3F8X0K067H3H98H6H01986030H3067FCX0K067H319CE7301986038738E60CX0K067H3F8FE3E0198601FE1FC60CX0K06231EC761C01986H0FC0F8C06X0hN0:::
// ~DGR:G5410009.GRF,352,8,V0:2JAH03FAL03IFC017HFCK03IF80KF8J03IF01HFDHFCJ03EHA03FA03FEJ03CI07FI01FJ03CI0FEJ0F8I03CH01FK07CI038H03EH02H03EI03CH07C0174018I03CH0F80IF808I03CH0701IFCK038H0F03IFEK03C01F07F01FK03C01E0FCH0EK03C01C07I04K03803E0FO03C01C1FO03EABE1EO03IFC1CO03IFE1EO03IFC1EO03EABE1EO03C01C1FO03803E0FO03C01C07O03C01E0FCH0EK03C01F07C05FK038H0F03IFEK03CH0701IFCK03CH0F80IF808I03CH07C01FC01CI038H03EH02H03EI03CH01FK07CI03CI0F8J0F8I03CI07CI05FJ038I03FA02FEJ038I017F57FCJ038J0KF8J0L01IFCK0M03FEL0V0
// ~DGR:G5410013.GRF,192,8,V0:::3FE1CE07N03HF1CF07N03HF9CF07N03879CF87N03839CFC71CL03879CEC71CL03HF9CHE71CL03HF1CE67N03FE1CEH7N03801CE37N03801CE3FN03801CE1FN03801CE1F1CL03801CE0F1CL03801CE071CL0V0::::
// ^XA
// ^MMT
// ^PW301
// ^LL300
// ^LS0
// ^FO0,114^XGR:G5410010.GRF,1,1^FS
// ^FO13,259^XGR:G5410004.GRF,1,1^FS
// ^FO65,208^XGR:G5410007.GRF,1,1^FS
// ^FO67,78^XGR:G5410003.GRF,1,1^FS
// ^FO1,21^XGR:G5410006.GRF,1,1^FS
// ^FO1,43^XGR:G5410005.GRF,1,1^FS
// ^FO5,190^XGR:G5410002.GRF,1,1^FS
// ^FO107,114^XGR:G5410008.GRF,1,1^FS
// ^FO2,66^XGR:G5410009.GRF,1,1^FS
// ^FPH,1^FT73,66^A0N,25,25^FH^CI28^FD${model}^FS^CI27
// ^FT39,282^A0N,29,28^FH^CI28^FD${id}^FS^CI27
// ^FO150,259^XGR:G5410013.GRF,1,1^FS
// ^FT192,282^A0N,29,28^FH^CI28^FD${pin}^FS^CI27
// ^PQ1,0,1,Y
// ^XZ
// ^XA^IDR:G5410013.GRF^FS^XZ
// ^XA^IDR:G5410009.GRF^FS^XZ
// ^XA^IDR:G5410008.GRF^FS^XZ
// ^XA^IDR:G5410002.GRF^FS^XZ
// ^XA^IDR:G5410005.GRF^FS^XZ
// ^XA^IDR:G5410006.GRF^FS^XZ
// ^XA^IDR:G5410003.GRF^FS^XZ
// ^XA^IDR:G5410007.GRF^FS^XZ
// ^XA^IDR:G5410004.GRF^FS^XZ
// ^XA^IDR:G5410010.GRF^FS^XZ
// `
//         case 'G1N':
//           return `CT~~CD,~CC^~CT~
// ^XA
// ~TA000
// ~JSN
// ^LT0
// ^MNW
// ^MTT
// ^PON
// ^PMN
// ^LH0,0
// ^JMA
// ^PR3,3
// ~SD30
// ^JUS
// ^LRN
// ^CI27
// ^PA0,1,1,0
// ^XZ
// ~DGR:G5410010.GRF,1488,16,gR0::::N01H5gG0M0BJFAY0L01LFY0K02IFABHFEX0K03HF4H01HFCW0K0HFEJ0BFEW0J01HFL0HFW0J0HFEL03FEV0I01HFCL01HFV0I03HF8M0HF8U0I07FCN07FCU0I0HFEN03FEU0H01HF8N01HFU0H03HF8O0HF8T0H07HFP07FCT0H0HFEP03FET0H07FCP03HFT0H0HF8P03HF8S001HF8P01HFT003HF8Q0HF8S001HFR07FCS003FER0HFES007FER07FCS007FER03FES007FCR03HFS00HFE0HF80FE80HF83FES007FC1HFC1HFC1HFC1HFS00HFE3HFE3HFE3HFE3HF8R00HFC1C1C3C1C3C3C1HFS00HF83C3E3C1E3C3E1HF8R00HF83C1C7C1E3C1C1HFS00HF83EH2782A3E203HF8R01HF81FH078H03EH01HFS00HF83F8078H03F801HF8R01HF81FC078H01FC01HF8R01HF80HF878HA0HF81HF8R01HF803F8787E03F01HFS00HF8H0FEF8FEH0FC0HF8R01HF8H07C785EH07C1HF8R00HF8203E783E203E1HF8R00HF8341E781E701C1HFS00HF83C1E381E383E0HF8R00HFC3C1C3C1C7C1C1HF8R00HFE3EBE3E3E3EBE1HF8R007FC1HFC1HFE1HFC1HFS00HFE0HF80HFE1HF80HF8R007FC054H0H54054H0HF8R003FEL02L0HF8R003HFS07FS003HFS0HF8R001HFS07F8R002HF8R03F8R0H07F8R01FS0H0HFCS0F8R0H07FCS078R0H03FES078R0H01HFS01S0I0HF8S08R0I07FCgL0I03FEgL0I01HFgL0J0HF8S0623CN0J07FCS043H4N0J03HF8R062E2N0J01HFCR0637O0K0BFE8Q06238N0K01HFDQ0610CN0L0BNFE8I0632EN0L017NFCI063H4N0N0OF8H03IEN0T05HFCI041O0T03IF8S0U07HF8S0U0IFES0U01HFES0U03IF8R0V07HFCR0V0BHFER0V01IFR0V02IF8Q0W07HFCQ0W03HFEQ0X07HFQ0X02HFAP0Y015Q0gR0::
// ~DGR:G5410004.GRF,96,4,N0:::71HFJ071HF8I071HFCI071C1CI071C1E38071C0E380:71C0EI0:::71C1CI071HFC38071HF838071FE0380N0::::
// ~DGR:G5410007.GRF,1536,32,iJ0:::7381C0F83HF81F01CH0EI01HF07HF1HFI01F03HFI07C01E01EH07N073C1C3FE3HF81F01CH0EI01HF87HF1HF8H07FC3HFH01HF07F87F807N073C1C7FE3HF81F01CH0EI01HFC7HF1HFCH0HFC3HFH03HF87387380FN073E1C70F03803B81CH0EI01C3C7H01C1CH0E1E38I03C3CE1CE1C3FN073F1C70703803B81CH0EI01C1C7H01C1CH0E0E38I07818E1CE1C7FN073B1C78H03803B81CH0EI01C3C7H01C1CH0FH038I07I0E1CE1CH7N073B9C3FH038071C1CH0EI01HFC7FE1HFCH07E03FEH07I0E1CE1C47N073H9C1FE038071C1CH0EI01HF87FE1HF8H03FC3FEH07I0E1CE1C07N0739DC07E0380E0E1CH0EI01HF07FE1FEJ0FC3FE3F7I0E1CE1C07N0738DCH0F0380HFE1CH0EI01CH07H01C7J01E3803F7I0E1CE1C07N0738FCE070380HFE1CH0EI01CH07H01C7801C0E3803F7818E1CE1C07N07387CF0F0381IF1CH0EI01CH07H01C3801E1E38I03C3CE1CE1C07N07387C7HF0381C071HF8HFC01CH07HF1C1CH0HFE38I03HF873873807N07383C7FE0381C071HF8HFC01CH07HF1C1CH0HFC38I01HF07F87F807N07381C1F803838039HF8HFC01CH07HF1C0EH03F038J07C01E01EH07N0iJ0::::::::J03E07HF1HFC039C0E07C1HFC7FC1C0E07C0HFE703F0381C0F8Q0J0HF87HF1HFC039E0E1HF1HFC7FE1C0E1HF0HFE70HFC3C1C3FEQ0I01HF87HF1HFC039E0E3HF1HFC7HF1C0E3HF8HFE71HFE3C1C7FEQ0I01C3C7H01CI039F0E38781C07071C0E3C3C0E071E1E3E1C70FQ0I01C1C7H01CI039F8E38381C07071C0E78180E073C0F3F1C707Q0I01EH07H01CI039D8E3CH01C07071C0E7J0E0738073B1C78R0J0FC07FE1HF8039DCE1F801C07HF1C0E7J0E0738073B9C3FR0J07F87FE1HF8039HCE0HF01C07FE1C0E7J0E0738073H9C1FEQ0J01F87FE1HF8039CHE03F01C07F81C0E7J0E07380739DC07EQ0K03C7H01CI039C6EH0781C071C1C0E7J0E07380738DCH0FQ0I0381C7H01CI039C7E70381C071E1C0E78180E073C0F38FCE07Q0I03C3C7H01CI039C3E78781C070E1E1E3C3C0E071E1E387CF0FQ0I01HFC7HF1HFC039C3E3HF81C07070HFC3HF80E071HFE387C7HFQ0I01HF87HF1HFC039C1E3HF01C07070HFC1HFH0E070HFC383C7FEQ0J07E07HF1HFC039C0E0FC01C070383FH07CH0E0703F0381C1F8Q0iJ0::::
// ~DGR:G5410003.GRF,456,24,hN0::7F83E03EH0CFEJ0F01CH0CHF0HF87C3FHC0C067F80CI07F8HF0HFH0CHF8H01F81CH0CHF8HF8FE3FCE0C067FC1CI06H0C38C380HC18H038C36H0HC1HC01C73H0F0C0H60E3CI0601CH1C1H0HC0C6030C36H0HC0HC018H3H0F0C0H60H6CI06018018I0HC0C6I0C36H0HC1HC01E03H0D8C0H60E4CI07F18018I0HC0CI01863H0CHF8HF8FC3F8IC067FC0CI07F18018I0HC0CI03863H0CFE0HF83E3F8IC067FH0CI06018018I0HC0CI0707FH0HC70CI073H0C6C0H6380CI0601CH1C1H0HC0CI0E0HFB0HC38C018H3H0C3D8H61C0CI06H0C38C380HC18H0180C1B9HC18C01C73H0C3DCE60C0CI06H0HF0HFH0CHF8603FHC19F8C1CHF8FE3H0C1CFC60E0CI06H03E03EH0CFE0603FD80CF0C0EHF87C3H0C0C786070CI0hN0:::
// ~DGR:G5410006.GRF,864,36,iR0:::07CU01FEI07H038I07E03E03EI01C0783HFH01F81F80F80FN01HFU03FEI07H038I0HF0HF0HFI03C1FE3HFH03FC3FC3FC3FCM03HFU038J07H038H01E78E78E78H03C1CEH07H079E79E39E39CM03878T038J07H038H01C39C21C2I07C387H0EH070E70E70870EM038383C1CF03F01F0H7FHE0F0707B8H01C39C01CJ07C38701CJ0EH0E7H070EM03CH0HF1HF87F87FC7HFHE3FC71HF8I0E71DE1DEI0DC38701CJ0EH0EH7870EM01F80E71E3CE1C71C7838E39C71C78I07E1HF1HFH019C387038I01C01C7FC70EM00HF1C39C1CEH0E0E7038E70E73838I07E1E71E7H019C1CF038I03C03C79C39EM003F1HF9C1CFC0E0E7038E7FE73838I0E71C39C39FB1C1HF0703F07807870E3FEM0H079HF9C1C7F8E0E7038E7FE73838H01C39C39C39FE1C0F70703F0FH0F070E1HEM07039C01C1C0FCE0E7038E7H073838H01C39C39C39JFH070703F1E01E070EH0EM07879C39C1C01CE0E7038E70E73838H01C39C39C3807HF1870EI01C01C070E30EM03HF8E71C1CE1C71C7038E39C71C78H01E78E70E7I01C3CE0EI038038039C79CM03HF0HF1C1C7F87FC7038E3FC71HF8I0HF0HF0HFI01C1FE0EI07FE7FE3FC3FCM00FC03C1C1C3F01F07038E0F0707B8I07E03C03CI01C0F80EI07FE7FE0F01FN0iR0::::
// ~DGR:G5410005.GRF,288,12,gJ0:::7C1FK01CI0EO07E3FK01CI0EO0:7637K01CI0EO0763707C03DC1E0E38M076371HF0HFC7F8E38M0J71C70E3C738E38M0J73839C1CE1CEO073673839C1CHFCEO0:73E73839C1CEH0EO073E73839C1CE1CEO073E71C70E3C738E38M071C71HF0HFC7F8E38M071C707C03DC1E0E38M0gJ0::::
// ~DGR:G5410002.GRF,152,8,V0::3C1E0F0783C3FI07E3F1F8FC7E3FI0C373B9DCHE73J0C361B0D86C36J0C361B0D86C37EI07E61B0D86C37FI07E61B0D86C3638H0C361B0D86C3018H0C361B0D86C3618H0C373B9DCHEH738H07E3F1F8FC7E3FI03C1E0F0783C1EI0V0:::
// ~DGR:G5410008.GRF,1824,24,hN0::0F8603I0FE0CI01EJ0FCJ07H01FCI0HFC18L03FC603I0HF8CI03FI03FEJ07H01HFI0HFC38L030E603I0C18J0718H0307J0D80183J0C038L0704603I0C0IC60618H070237H0D801818I0C078L06H0603I0C0IC6H018H06H03FH0D801818I0C0D8L06H0603I0C0IC6H03I06H038018C01818I0C0D8L06H0603I0C0HC6CH07I061F3H018C01818I0C198L06H0603I0C0HC6CH0EI061F3H01FC7D818I0C318L0704603I0C0HC6C01CI070H3H03FE7D818I0C3FCL030E603I0C18C3803J03073H03060183J0C3FCL03FC60H3H0HF8C3807F9803HF3H030601HF18H0C018L00F860H3H0FE0C3807F98H0FC3H060301FC18H0C018L0L01R08U08R0:L02Q01U01S0hN0:::0F8603I0HFJ078I03FJ0HC1FI07FE0CP03FC603I0HFJ0FCI0HF8I0HC7F8H07FE1CP030E603J06I01C6I0C1CI0HC61CI0601CP0704603J0C6F0186H01C08DC0HCE08I0603CP06H0603I01C7F8H06H018H0FC0ICK0606CP06H0603I018718H0CH018H0EH0ICK0606CP06H0603I03061801CH0187HCH0ICK060HCP06H0603I070618038H0187HCH0ICK0618CP0704603I06061807I01C0HCH0HCE08I061FEP030E603I0C06180CJ0C1HCH0HC61CI061FEP03FC60H301HF6181FE6H0HFHCH0HC7F8CH06H0CP00F860H301HF6181FE6H03F0CH0HC1F0CH06H0CP0L01P02S04V0:L02P04S08V0hN0:::H01878101FK01HF8L03FCFC101FT0H038FC7C7F8J01HF8L03FCFC7C7F8S0H039CE1061C02I018J04I018C01061CS0H0798628E080E7FC181F01CHF8318028E08S0H0D98H6HCH03C7FC183F878HF831F86HCU0H0D986H0CH0FJ018319EJ061FCH0CU001H986H0C01CJ01807B8J0618EH0CU07F1986H0CH0F07FC181F9E0HF86H06H0CU07HFD86H0E083C7FC1839878HF8E186H0E08S003FDCEH061C0EI0183181CI0C1CEH061CS0H018FCH07F802I0183F804I0C0FCH07F8S0H01878H01FL0181ECK0C078H01FT0hN0::::::K0707J06I018I03030F80EY0K0707J06I018I03031FC0EY0K078FJ06N030H38E1BY0K078F1F0761C019BC030H3061BY0K068B3F8FE3E019FE030H3C01BY0K06DB319CE63019C603031F8318X0K06DB079867F01986030307C318X0K06DB1F9867F019860303H0E3F8X0K067H3H98H6H01986030H3067FCX0K067H319CE7301986038738E60CX0K067H3F8FE3E0198601FE1FC60CX0K06231EC761C01986H0FC0F8C06X0hN0:::
// ~DGR:G5410009.GRF,352,8,V0:2JAH03FAL03IFC017HFCK03IF80KF8J03IF01HFDHFCJ03EHA03FA03FEJ03CI07FI01FJ03CI0FEJ0F8I03CH01FK07CI038H03EH02H03EI03CH07C0174018I03CH0F80IF808I03CH0701IFCK038H0F03IFEK03C01F07F01FK03C01E0FCH0EK03C01C07I04K03803E0FO03C01C1FO03EABE1EO03IFC1CO03IFE1EO03IFC1EO03EABE1EO03C01C1FO03803E0FO03C01C07O03C01E0FCH0EK03C01F07C05FK038H0F03IFEK03CH0701IFCK03CH0F80IF808I03CH07C01FC01CI038H03EH02H03EI03CH01FK07CI03CI0F8J0F8I03CI07CI05FJ038I03FA02FEJ038I017F57FCJ038J0KF8J0L01IFCK0M03FEL0V0
// ~DGR:G5410013.GRF,192,8,V0:::3FE1CE07N03HF1CF07N03HF9CF07N03879CF87N03839CFC71CL03879CEC71CL03HF9CHE71CL03HF1CE67N03FE1CEH7N03801CE37N03801CE3FN03801CE1FN03801CE1F1CL03801CE0F1CL03801CE071CL0V0::::
// ^XA
// ^MMT
// ^PW301
// ^LL300
// ^LS0
// ^FO0,114^XGR:G5410010.GRF,1,1^FS
// ^FO13,259^XGR:G5410004.GRF,1,1^FS
// ^FO65,208^XGR:G5410007.GRF,1,1^FS
// ^FO67,78^XGR:G5410003.GRF,1,1^FS
// ^FO1,21^XGR:G5410006.GRF,1,1^FS
// ^FO1,43^XGR:G5410005.GRF,1,1^FS
// ^FO5,190^XGR:G5410002.GRF,1,1^FS
// ^FO107,114^XGR:G5410008.GRF,1,1^FS
// ^FO2,66^XGR:G5410009.GRF,1,1^FS
// ^FPH,1^FT73,66^A0N,25,25^FH^CI28^FD${model}^FS^CI27
// ^FT39,282^A0N,29,28^FH^CI28^FD${id}^FS^CI27
// ^FO150,259^XGR:G5410013.GRF,1,1^FS
// ^FT192,282^A0N,29,28^FH^CI28^FD${pin}^FS^CI27
// ^PQ1,0,1,Y
// ^XZ
// ^XA^IDR:G5410013.GRF^FS^XZ
// ^XA^IDR:G5410009.GRF^FS^XZ
// ^XA^IDR:G5410008.GRF^FS^XZ
// ^XA^IDR:G5410002.GRF^FS^XZ
// ^XA^IDR:G5410005.GRF^FS^XZ
// ^XA^IDR:G5410006.GRF^FS^XZ
// ^XA^IDR:G5410003.GRF^FS^XZ
// ^XA^IDR:G5410007.GRF^FS^XZ
// ^XA^IDR:G5410004.GRF^FS^XZ
// ^XA^IDR:G5410010.GRF^FS^XZ
// `
//         default:
//           return `CT~~CD,~CC^~CT~
// ^XA
// ~TA000
// ~JSN
// ^LT0
// ^MNW
// ^MTT
// ^PON
// ^PMN
// ^LH0,0
// ^JMA
// ^PR3,3
// ~SD30
// ^JUS
// ^LRN
// ^CI27
// ^PA0,1,1,0
// ^XZ
// ~DGR:G5410010.GRF,1488,16,gR0::::N01H5gG0M0BJFAY0L01LFY0K02IFABHFEX0K03HF4H01HFCW0K0HFEJ0BFEW0J01HFL0HFW0J0HFEL03FEV0I01HFCL01HFV0I03HF8M0HF8U0I07FCN07FCU0I0HFEN03FEU0H01HF8N01HFU0H03HF8O0HF8T0H07HFP07FCT0H0HFEP03FET0H07FCP03HFT0H0HF8P03HF8S001HF8P01HFT003HF8Q0HF8S001HFR07FCS003FER0HFES007FER07FCS007FER03FES007FCR03HFS00HFE0HF80FE80HF83FES007FC1HFC1HFC1HFC1HFS00HFE3HFE3HFE3HFE3HF8R00HFC1C1C3C1C3C3C1HFS00HF83C3E3C1E3C3E1HF8R00HF83C1C7C1E3C1C1HFS00HF83EH2782A3E203HF8R01HF81FH078H03EH01HFS00HF83F8078H03F801HF8R01HF81FC078H01FC01HF8R01HF80HF878HA0HF81HF8R01HF803F8787E03F01HFS00HF8H0FEF8FEH0FC0HF8R01HF8H07C785EH07C1HF8R00HF8203E783E203E1HF8R00HF8341E781E701C1HFS00HF83C1E381E383E0HF8R00HFC3C1C3C1C7C1C1HF8R00HFE3EBE3E3E3EBE1HF8R007FC1HFC1HFE1HFC1HFS00HFE0HF80HFE1HF80HF8R007FC054H0H54054H0HF8R003FEL02L0HF8R003HFS07FS003HFS0HF8R001HFS07F8R002HF8R03F8R0H07F8R01FS0H0HFCS0F8R0H07FCS078R0H03FES078R0H01HFS01S0I0HF8S08R0I07FCgL0I03FEgL0I01HFgL0J0HF8S0623CN0J07FCS043H4N0J03HF8R062E2N0J01HFCR0637O0K0BFE8Q06238N0K01HFDQ0610CN0L0BNFE8I0632EN0L017NFCI063H4N0N0OF8H03IEN0T05HFCI041O0T03IF8S0U07HF8S0U0IFES0U01HFES0U03IF8R0V07HFCR0V0BHFER0V01IFR0V02IF8Q0W07HFCQ0W03HFEQ0X07HFQ0X02HFAP0Y015Q0gR0::
// ~DGR:G5410004.GRF,96,4,N0:::71HFJ071HF8I071HFCI071C1CI071C1E38071C0E380:71C0EI0:::71C1CI071HFC38071HF838071FE0380N0::::
// ~DGR:G5410007.GRF,1536,32,iJ0:::7381C0F83HF81F01CH0EI01HF07HF1HFI01F03HFI07C01E01EH07N073C1C3FE3HF81F01CH0EI01HF87HF1HF8H07FC3HFH01HF07F87F807N073C1C7FE3HF81F01CH0EI01HFC7HF1HFCH0HFC3HFH03HF87387380FN073E1C70F03803B81CH0EI01C3C7H01C1CH0E1E38I03C3CE1CE1C3FN073F1C70703803B81CH0EI01C1C7H01C1CH0E0E38I07818E1CE1C7FN073B1C78H03803B81CH0EI01C3C7H01C1CH0FH038I07I0E1CE1CH7N073B9C3FH038071C1CH0EI01HFC7FE1HFCH07E03FEH07I0E1CE1C47N073H9C1FE038071C1CH0EI01HF87FE1HF8H03FC3FEH07I0E1CE1C07N0739DC07E0380E0E1CH0EI01HF07FE1FEJ0FC3FE3F7I0E1CE1C07N0738DCH0F0380HFE1CH0EI01CH07H01C7J01E3803F7I0E1CE1C07N0738FCE070380HFE1CH0EI01CH07H01C7801C0E3803F7818E1CE1C07N07387CF0F0381IF1CH0EI01CH07H01C3801E1E38I03C3CE1CE1C07N07387C7HF0381C071HF8HFC01CH07HF1C1CH0HFE38I03HF873873807N07383C7FE0381C071HF8HFC01CH07HF1C1CH0HFC38I01HF07F87F807N07381C1F803838039HF8HFC01CH07HF1C0EH03F038J07C01E01EH07N0iJ0::::::::J03E07HF1HFC039C0E07C1HFC7FC1C0E07C0HFE703F0381C0F8Q0J0HF87HF1HFC039E0E1HF1HFC7FE1C0E1HF0HFE70HFC3C1C3FEQ0I01HF87HF1HFC039E0E3HF1HFC7HF1C0E3HF8HFE71HFE3C1C7FEQ0I01C3C7H01CI039F0E38781C07071C0E3C3C0E071E1E3E1C70FQ0I01C1C7H01CI039F8E38381C07071C0E78180E073C0F3F1C707Q0I01EH07H01CI039D8E3CH01C07071C0E7J0E0738073B1C78R0J0FC07FE1HF8039DCE1F801C07HF1C0E7J0E0738073B9C3FR0J07F87FE1HF8039HCE0HF01C07FE1C0E7J0E0738073H9C1FEQ0J01F87FE1HF8039CHE03F01C07F81C0E7J0E07380739DC07EQ0K03C7H01CI039C6EH0781C071C1C0E7J0E07380738DCH0FQ0I0381C7H01CI039C7E70381C071E1C0E78180E073C0F38FCE07Q0I03C3C7H01CI039C3E78781C070E1E1E3C3C0E071E1E387CF0FQ0I01HFC7HF1HFC039C3E3HF81C07070HFC3HF80E071HFE387C7HFQ0I01HF87HF1HFC039C1E3HF01C07070HFC1HFH0E070HFC383C7FEQ0J07E07HF1HFC039C0E0FC01C070383FH07CH0E0703F0381C1F8Q0iJ0::::
// ~DGR:G5410003.GRF,456,24,hN0::7F83E03EH0CFEJ0F01CH0CHF0HF87C3FHC0C067F80CI07F8HF0HFH0CHF8H01F81CH0CHF8HF8FE3FCE0C067FC1CI06H0C38C380HC18H038C36H0HC1HC01C73H0F0C0H60E3CI0601CH1C1H0HC0C6030C36H0HC0HC018H3H0F0C0H60H6CI06018018I0HC0C6I0C36H0HC1HC01E03H0D8C0H60E4CI07F18018I0HC0CI01863H0CHF8HF8FC3F8IC067FC0CI07F18018I0HC0CI03863H0CFE0HF83E3F8IC067FH0CI06018018I0HC0CI0707FH0HC70CI073H0C6C0H6380CI0601CH1C1H0HC0CI0E0HFB0HC38C018H3H0C3D8H61C0CI06H0C38C380HC18H0180C1B9HC18C01C73H0C3DCE60C0CI06H0HF0HFH0CHF8603FHC19F8C1CHF8FE3H0C1CFC60E0CI06H03E03EH0CFE0603FD80CF0C0EHF87C3H0C0C786070CI0hN0:::
// ~DGR:G5410006.GRF,864,36,iR0:::07CU01FEI07H038I07E03E03EI01C0783HFH01F81F80F80FN01HFU03FEI07H038I0HF0HF0HFI03C1FE3HFH03FC3FC3FC3FCM03HFU038J07H038H01E78E78E78H03C1CEH07H079E79E39E39CM03878T038J07H038H01C39C21C2I07C387H0EH070E70E70870EM038383C1CF03F01F0H7FHE0F0707B8H01C39C01CJ07C38701CJ0EH0E7H070EM03CH0HF1HF87F87FC7HFHE3FC71HF8I0E71DE1DEI0DC38701CJ0EH0EH7870EM01F80E71E3CE1C71C7838E39C71C78I07E1HF1HFH019C387038I01C01C7FC70EM00HF1C39C1CEH0E0E7038E70E73838I07E1E71E7H019C1CF038I03C03C79C39EM003F1HF9C1CFC0E0E7038E7FE73838I0E71C39C39FB1C1HF0703F07807870E3FEM0H079HF9C1C7F8E0E7038E7FE73838H01C39C39C39FE1C0F70703F0FH0F070E1HEM07039C01C1C0FCE0E7038E7H073838H01C39C39C39JFH070703F1E01E070EH0EM07879C39C1C01CE0E7038E70E73838H01C39C39C3807HF1870EI01C01C070E30EM03HF8E71C1CE1C71C7038E39C71C78H01E78E70E7I01C3CE0EI038038039C79CM03HF0HF1C1C7F87FC7038E3FC71HF8I0HF0HF0HFI01C1FE0EI07FE7FE3FC3FCM00FC03C1C1C3F01F07038E0F0707B8I07E03C03CI01C0F80EI07FE7FE0F01FN0iR0::::
// ~DGR:G5410005.GRF,288,12,gJ0:::7C1FK01CI0EO07E3FK01CI0EO0:7637K01CI0EO0763707C03DC1E0E38M076371HF0HFC7F8E38M0J71C70E3C738E38M0J73839C1CE1CEO073673839C1CHFCEO0:73E73839C1CEH0EO073E73839C1CE1CEO073E71C70E3C738E38M071C71HF0HFC7F8E38M071C707C03DC1E0E38M0gJ0::::
// ~DGR:G5410002.GRF,152,8,V0::3C1E0F0783C3FI07E3F1F8FC7E3FI0C373B9DCHE73J0C361B0D86C36J0C361B0D86C37EI07E61B0D86C37FI07E61B0D86C3638H0C361B0D86C3018H0C361B0D86C3618H0C373B9DCHEH738H07E3F1F8FC7E3FI03C1E0F0783C1EI0V0:::
// ~DGR:G5410008.GRF,1824,24,hN0::0F8603I0FE0CI01EJ0FCJ07H01FCI0HFC18L03FC603I0HF8CI03FI03FEJ07H01HFI0HFC38L030E603I0C18J0718H0307J0D80183J0C038L0704603I0C0IC60618H070237H0D801818I0C078L06H0603I0C0IC6H018H06H03FH0D801818I0C0D8L06H0603I0C0IC6H03I06H038018C01818I0C0D8L06H0603I0C0HC6CH07I061F3H018C01818I0C198L06H0603I0C0HC6CH0EI061F3H01FC7D818I0C318L0704603I0C0HC6C01CI070H3H03FE7D818I0C3FCL030E603I0C18C3803J03073H03060183J0C3FCL03FC60H3H0HF8C3807F9803HF3H030601HF18H0C018L00F860H3H0FE0C3807F98H0FC3H060301FC18H0C018L0L01R08U08R0:L02Q01U01S0hN0:::0F8603I0HFJ078I03FJ0HC1FI07FE0CP03FC603I0HFJ0FCI0HF8I0HC7F8H07FE1CP030E603J06I01C6I0C1CI0HC61CI0601CP0704603J0C6F0186H01C08DC0HCE08I0603CP06H0603I01C7F8H06H018H0FC0ICK0606CP06H0603I018718H0CH018H0EH0ICK0606CP06H0603I03061801CH0187HCH0ICK060HCP06H0603I070618038H0187HCH0ICK0618CP0704603I06061807I01C0HCH0HCE08I061FEP030E603I0C06180CJ0C1HCH0HC61CI061FEP03FC60H301HF6181FE6H0HFHCH0HC7F8CH06H0CP00F860H301HF6181FE6H03F0CH0HC1F0CH06H0CP0L01P02S04V0:L02P04S08V0hN0:::H01878101FK01HF8L03FCFC101FT0H038FC7C7F8J01HF8L03FCFC7C7F8S0H039CE1061C02I018J04I018C01061CS0H0798628E080E7FC181F01CHF8318028E08S0H0D98H6HCH03C7FC183F878HF831F86HCU0H0D986H0CH0FJ018319EJ061FCH0CU001H986H0C01CJ01807B8J0618EH0CU07F1986H0CH0F07FC181F9E0HF86H06H0CU07HFD86H0E083C7FC1839878HF8E186H0E08S003FDCEH061C0EI0183181CI0C1CEH061CS0H018FCH07F802I0183F804I0C0FCH07F8S0H01878H01FL0181ECK0C078H01FT0hN0::::::K0707J06I018I03030F80EY0K0707J06I018I03031FC0EY0K078FJ06N030H38E1BY0K078F1F0761C019BC030H3061BY0K068B3F8FE3E019FE030H3C01BY0K06DB319CE63019C603031F8318X0K06DB079867F01986030307C318X0K06DB1F9867F019860303H0E3F8X0K067H3H98H6H01986030H3067FCX0K067H319CE7301986038738E60CX0K067H3F8FE3E0198601FE1FC60CX0K06231EC761C01986H0FC0F8C06X0hN0:::
// ~DGR:G5410009.GRF,352,8,V0:2JAH03FAL03IFC017HFCK03IF80KF8J03IF01HFDHFCJ03EHA03FA03FEJ03CI07FI01FJ03CI0FEJ0F8I03CH01FK07CI038H03EH02H03EI03CH07C0174018I03CH0F80IF808I03CH0701IFCK038H0F03IFEK03C01F07F01FK03C01E0FCH0EK03C01C07I04K03803E0FO03C01C1FO03EABE1EO03IFC1CO03IFE1EO03IFC1EO03EABE1EO03C01C1FO03803E0FO03C01C07O03C01E0FCH0EK03C01F07C05FK038H0F03IFEK03CH0701IFCK03CH0F80IF808I03CH07C01FC01CI038H03EH02H03EI03CH01FK07CI03CI0F8J0F8I03CI07CI05FJ038I03FA02FEJ038I017F57FCJ038J0KF8J0L01IFCK0M03FEL0V0
// ~DGR:G5410013.GRF,192,8,V0:::3FE1CE07N03HF1CF07N03HF9CF07N03879CF87N03839CFC71CL03879CEC71CL03HF9CHE71CL03HF1CE67N03FE1CEH7N03801CE37N03801CE3FN03801CE1FN03801CE1F1CL03801CE0F1CL03801CE071CL0V0::::
// ^XA
// ^MMT
// ^PW301
// ^LL300
// ^LS0
// ^FO0,114^XGR:G5410010.GRF,1,1^FS
// ^FO13,259^XGR:G5410004.GRF,1,1^FS
// ^FO65,208^XGR:G5410007.GRF,1,1^FS
// ^FO67,78^XGR:G5410003.GRF,1,1^FS
// ^FO1,21^XGR:G5410006.GRF,1,1^FS
// ^FO1,43^XGR:G5410005.GRF,1,1^FS
// ^FO5,190^XGR:G5410002.GRF,1,1^FS
// ^FO107,114^XGR:G5410008.GRF,1,1^FS
// ^FO2,66^XGR:G5410009.GRF,1,1^FS
// ^FPH,1^FT73,66^A0N,25,25^FH^CI28^FD${model}^FS^CI27
// ^FT39,282^A0N,29,28^FH^CI28^FD${id}^FS^CI27
// ^FO150,259^XGR:G5410013.GRF,1,1^FS
// ^FT192,282^A0N,29,28^FH^CI28^FD${pin}^FS^CI27
// ^PQ1,0,1,Y
// ^XZ
// ^XA^IDR:G5410013.GRF^FS^XZ
// ^XA^IDR:G5410009.GRF^FS^XZ
// ^XA^IDR:G5410008.GRF^FS^XZ
// ^XA^IDR:G5410002.GRF^FS^XZ
// ^XA^IDR:G5410005.GRF^FS^XZ
// ^XA^IDR:G5410006.GRF^FS^XZ
// ^XA^IDR:G5410003.GRF^FS^XZ
// ^XA^IDR:G5410007.GRF^FS^XZ
// ^XA^IDR:G5410004.GRF^FS^XZ
// ^XA^IDR:G5410010.GRF^FS^XZ
// `
//       }
//   }
// }

export default Stickers
