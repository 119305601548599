import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import configureStore from './store/configureStore'
import {PersistGate} from 'redux-persist/es/integration/react'
import {ApolloProvider, graphql} from 'react-apollo'

import * as Sentry from '@sentry/browser'

import Login from './Login'
import {setMobile, refreshToken} from 'store/actions'
import getApolloClient from './getApolloClient'

import Router from './router'
import {GET_ME} from 'queries'
import {routes, adminRoutes, managerRoutes} from './routes'
import Layout from './Layout'

import './index.scss'

Sentry.init({dsn: 'https://6f431dc452644fd4b1d238330dc2b645@sentry.io/1543644'})

const {persistor, store} = configureStore()
const apolloClient = getApolloClient({store, endpoint: '/api/graphql'})

class App extends Component {
  state = {error: false}
  componentDidCatch(error, info) {
    this.setState({error: true})
  }
  render() {
    if (this.state.error) return <ErrorComponent />
    return (
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <PersistGate
            persistor={persistor}
            onBeforeLift={() => {
              store.dispatch(setMobile())
              store.getState().user.authorization &&
                store.dispatch(
                  refreshToken(
                    store.getState().user.authorization.replace('Bearer ', '')
                  )
                )
            }}
          >
            <Login>
              <ConnectedRouter />
            </Login>
          </PersistGate>
        </ApolloProvider>
      </Provider>
    )
  }
}
const ErrorComponent = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      fontSize: '1.3em',
      lineSpacing: '1.3'
    }}
  >
    <h1>An error has occured.</h1>
    <p>
      <a href="https://app.sensorfield.com">Click here</a> to reload the page.
      If the problem occurs again, contact us at{' '}
      <a href="tel:281-631-5926">(281) 631-5926</a>.
    </p>
  </div>
)

const ConnectedRouter = graphql(GET_ME)(({data}) => (
  <Router
    routes={data && data.me && data.me.isSuperUser ? adminRoutes : data?.me?.isManager ? managerRoutes : routes}
  >
    {({path, side, content, tab}) => (
      <Layout path={path} side={side} content={content} tab={tab} />
    )}
  </Router>
))

ReactDOM.render(<App />, document.getElementById('root'))
