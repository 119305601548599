import React, {Component} from 'react'
import {gql} from 'graphql.macro'

import {Input, Select} from 'Components/Form'
import {graphql, Query} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import Modal from 'Components/Modal'
import Tooltip from 'Components/Tooltip'
import update from 'immutability-helper'

import './index.scss'
import Loading from 'Components/Loading'
import getDisplayOptions from 'utils/getDisplayOptions'

const UPDATE_MASS_SETTING = gql`
  mutation UPDATE_MASS_SETTING(
    $deviceIDs: String!
    $setting1: String!
    $setting2: String!
    $setting3: String!
    $setting4: String!
    $setting5: String!
    $setting6: String!
    $setting7: String!
    $setting8: String!
    $setting9: String!
    $setting10: String!
    $rules: [RuleActionTypeB]
    $actions: [AlarmActionTypeB]
  ) {
    updateMassSetting(deviceIDs: $deviceIDs, setting1: $setting1, setting2: $setting2, setting3: $setting3, setting4: $setting4, setting5: $setting5, setting6: $setting6, setting7: $setting7, setting8: $setting8, setting9: $setting9, setting10: $setting10, rules: $rules, actions: $actions) {
     success
    }
  }
`

const CLEAR_MASS_DEVICE_DATA = gql`
  mutation CLEAR_MASS_DEVICE_DATA($deviceIDs: String!, $deleteAlarmsToo: Boolean!, $deleteOnlyAlarms: Boolean!) {
    clearMassDeviceData(deviceIDs: $deviceIDs, deleteAlarmsToo: $deleteAlarmsToo, deleteOnlyAlarms: $deleteOnlyAlarms) {
      success
    }
  }
`

const GET_COMPANIES = gql`
  query GET_COMPANIES {
    companies {
      id
      name
    }
  }
`

const GET_GROUPS_BY_COMPANY = gql`
  query GET_GROUP_BY_COMPANY($companyID: Int) {
    groupsRe(companyID: $companyID) {
      id
      name
    }
  }
`

const GET_GROUPS_BY_COMPANY_USER = gql`
  query GET_GROUP_BY_COMPANY_USER($companyID: Int) {
    groupsRe(companyID: $companyID) {
      id
      name
    }
  }
`


const GET_USERS_RE = gql`
  query GET_USERS_RE {
    me {
      id
      isSuperUser
      company {
        id
      }
    }
    usersRe {
      id
      firstName
      lastName
    }
  }
`

const GET_FOTA_VERSIONS = gql`
  query GET_FOTA_VERSIONS {
    fota {
      name
      crc_id
      image_type
    }
  }
`
class UpdateDevices extends Component {
  state = {
    infoButton: true,
    tagButton: false,
    settingButton: false,
    alarmButton: false,
    deviceIDs: [],
    deviceIDsPlaceholder: '',
    tag: '',
    path: '',
    companyID: '',
    displayUnits: '',
    groupID: '',
    criticalHigh: null,
    highWarning: null,
    lowWarning: null,
    criticalLow: null,
    applicationFirmwareVersions: [],
    relocateFirmwareVersions: [],
    applicationImageSignature: null,
    relocateImageSignature: null,
    intervalReading: null,
    intervalTransmit: null,
    clearDataWarning: false,
    loading: false,
    message: null,
    deleteAlarms: false,
    keepChanges: true,
    onlyAlarms: false,
    formError: null,
    rules: [].map(({name, tag, symbol, value, desc, sent}) => ({
      name,
      tag,
      symbol,
      value,
      desc,
      sent
    })),
    actions: [].map(({delay, tag, action, user}) => ({
      delay,
      tag,
      action,
      userID: user ? user.id : null,
    })),
  }
  resetState = () =>
    this.setState({
      deviceIDs: [],
      deviceIDsPlaceholder: '',
      tag: '',
      path: '',
      companyID: '',
      displayUnits: '',
      groupID: '',
      criticalHigh: null,
      highWarning: null,
      lowWarning: null,
      criticalLow: null,
      applicationImageSignature: null,
      relocateImageSignature: null,
      intervalReading: null,
      intervalTransmit: null,
      clearDataWarning: false,
      deleteAlarms: false,
      keepChanges: true,
      onlyAlarms: false,
      formError: null,
      rules: [].map(({name, tag, symbol, value, desc, sent}) => ({
        name,
        tag, 
        symbol,
        value,
        desc,
        sent
      })),
      actions: [].map(({delay, tag, action, user}) => ({
        delay,
        tag,
        action,
        userID: user ? user.id : null,
      }))
    })

  submitForm = async () => {
      let setting1, setting2, setting3, setting4, setting5, setting6, setting7, setting8, setting9, setting10, rules, actions

      if (this.state.applicationImageSignature !== null && this.state.applicationImageSignature !== 'Select signature') {
        setting1 = this.state.applicationImageSignature
      }
      if (this.state.relocateImageSignature !== null && this.state.relocateImageSignature !== 'Select signature') {
        setting2 = this.state.relocateImageSignature
      }
      if (this.state.path !== '') { 
        setting3 = this.state.path
      }      
      if (this.state.companyID !== '' && this.state.companyID !== 'Select Company') {
        setting4 = this.state.companyID
      }
      if (this.state.intervalReading !== null) {
        setting5 = this.state.intervalReading
      }
      if (this.state.intervalTransmit !== null) {
        setting6 = this.state.intervalTransmit    
      }
      if (this.state.groupID !== '' && this.state.groupID !== 'Select Group'){
        setting7 = this.state.groupID
      }
      if ((this.state.rules.filter((r) => (r.name && r.tag && r.symbol && r.value))).length !== 0){
        rules = this.state.rules.filter((r) => (r.name && r.tag))
      }
      if ((this.state.actions.filter((a) => (a.userID && a.tag))).length !== 0) {
        actions = this.state.actions.filter((a) => ((a.tag && a.userID)))
      }

      // For some reason display units used from tagOptions has default option that is null
      if (this.state.displayUnits !== '' && this.state.displayUnits !== 'Select Units' && this.state.displayUnits !== null) {
        if (this.state.tag !== '' && this.state.tag !== 'Select Tag') {
          setting8 = this.state.tag
          setting9 = this.state.displayUnits
        }
      }

      if  (this.state.criticalHigh || this.state.criticalLow || this.state.highWarning || this.state.lowWarning) {
        if (this.state.tag !== '' && this.state.tag !== 'Select Tag') {
          setting8 = this.state.tag
          setting10 = this.state.criticalHigh + ',' + this.state.criticalLow + ',' + this.state.highWarning + ',' + this.state.lowWarning
        }
      }

      if (this.state.deviceIDsPlaceholder !== '' && (setting1 || setting2 || setting3 || setting4 || setting5 || setting6 || setting7 || setting8 || setting9 || setting10 || rules || actions)) {
        this.setState({loading: true})
        const deviceIDs = this.state.deviceIDsPlaceholder
        this.setState({deviceIDs})

        try {
          await this.props.updateMassSetting({
          variables: {
            deviceIDs: deviceIDs,
            setting1: setting1 ? setting1.toString() : '',
            setting2: setting2 ? setting2.toString() : '',
            setting3: setting3 ? setting3.toString() : '',
            setting4: setting4 ? setting4.toString() : '',
            setting5: setting5 ? setting5.toString() : '',
            setting6: setting6 ? setting6.toString() : '',
            setting7: setting7 ? setting7.toString() : '',
            setting8: setting8 ? setting8.toString() : '',
            setting9: setting9 ? setting9.toString() : '',
            setting10: setting10 ? setting10.toString() : '',
            rules: rules ? rules : null,
            actions: actions ? actions : null
          }
          })
          this.resetState()
          this.setState({message: 'Updated Devices'})
        } catch (e) {
          if(this.props?.updateMassSettingResult?.error) {
            const errorMessage = this.props?.updateMassSettingResult?.error?.message.split(":").pop().replace(',', ', ')
            this.setState({message: errorMessage})
          }
        }
      } else {
        this.setState({formError: true})
      }
  }

  clearData = async () => {
    this.setState({
      clearDataWarning: false,
    })

    if (this.state.deviceIDsPlaceholder !== '') {
      this.setState({loading: true})
      const deviceIDs = this.state.deviceIDsPlaceholder
      this.setState({deviceIDs})
      try {      
        await this.props.clearMassDeviceData({
          variables: {
            deviceIDs: deviceIDs,
            deleteAlarmsToo: this.state.deleteAlarms,
            deleteOnlyAlarms: this.state.onlyAlarms
          }
        })

        if (this.state.keepChanges === true) await this.submitForm()

        this.resetState()
        this.setState({message: 'Updated Devices'})
      } catch (e) {
        if(this.props?.clearMassDeviceDataResult?.error) {
          const errorMessage = this.props?.clearMassDeviceDataResult?.error?.message.split(":").pop().replace(',', ', ')
          this.setState({message: errorMessage})
        }
      }
    }
}

  closeDisplayMessage = () => {
    this.setState({loading: false})
    this.setState({message: null})
  }

  infoButtonClick = () => {
    this.setState({infoButton: !this.state.infoButton})
  }

  settingsButtonClick = () => {
    this.setState({settingButton: !this.state.settingButton})
  }

  alarmButtonClick = () => {
    this.setState({alarmButton: !this.state.alarmButton})
  }

  tagButtonClick = () => {
    this.setState({tagButton: !this.state.tagButton})
  }

  closePopup = () => {
    this.setState({formError: false})
  }

  render() {
    const isSuperUser = this?.props?.users?.me?.isSuperUser || false
    const isSpecificUser = this?.props?.users?.me?.id === 12034 || false
    const users = this?.props?.users?.usersRe || []
    const firmwareVersions = this?.props?.fota?.fota || []

    return (
      <div className='directory-body'>
        <div className='directory-top-bar'>
          <p className='title2344'>UPDATE DEVICES</p>
          <Tooltip>
          *mass update all chosen devices in your directory with the selected settings. use with caution.
          </Tooltip>
          </div>
        <div className="mass-setting-box">
        <div className="top-box">
          <input
            type="text"
            name="deviceID"
            placeholder="Device IDs (Comma Delimited): "
            value={this.state.deviceIDsPlaceholder}
            onChange={(e) =>
              this.setState({deviceIDsPlaceholder: e.target.value})
            }
            className="device-id-input"
            style={{borderColor: "red"}}
          />
          <div className="button-container2">
            <button
              className="red primary"
              onClick={() => this.setState({clearDataWarning: true})}
            >
              {isSuperUser ? 'Clear Data' : 'Clear Alarms'}
            </button>
            {' '}
            <button className="primary" onClick={this.submitForm}>
              Save Changes
            </button>
          </div>
          {this.state.loading && (
            <Modal onClose={() => this.setState({loading: false})}>
             <button style={{float: 'right'}} onClick={this.closeDisplayMessage}>
              X
            </button>
              <Modal.Body>
              {(this.state.loading && this.state.message === null) && (<Loading />)}
              {this.state.message !== null && this.state.message}
              </Modal.Body>
            </Modal>
          )}
        </div>
        {this.state.formError && (
          <div>
          <p className="form-popup"><button onClick={this.closePopup}><i className='fa fa-x' /></button> There was an error with submitting the form. Check your settings for correct fields.</p>
          </div>
        )}
        <div style={{margin: "1em"}}>
            <div className="dropdownxz">
                <button className="dropdownx-button" onClick={this.infoButtonClick}>Info Settings<i className={this.state.infoButton ? 'fa fa-angle-down' : 'fa fa-angle-right'}/></button>
                {this.state.infoButton && (
                <ul className="dropdownx-content">
                    <li>
                    <Input
                        id="device-path-input"
                        type="directory"
                        label="Directory Path"
                        onChange={(e) => this.setState({path: e.target.value})}
                        data-key="path"
                        value={this.state.path}
                        static={false}
                    />
                    </li>
                    {(isSpecificUser || isSuperUser) && (
                    <li>
                    <Input
                        type="select"
                        label="Company"
                        onChange={(e) => this.setState({companyID: e.target.value})}
                        data-key="companyID"
                        value={this.state.companyID}
                        static={false}
                        options={[{label: 'Select Company', value: 'Select Company'}].concat(
                        ((this?.props?.companies?.companies) || [])
                        .map((c) => ({
                            label: c.name,
                            value: c.id
                        }))
                        )}
                    />
                    </li>)}
                    <li>
                    {((isSuperUser || isSpecificUser) && (this.state.companyID || this.props?.users?.me?.company?.id)) && (
                    <Query query = {GET_GROUPS_BY_COMPANY} variables={{companyID: (this.state.companyID !== "" && this.state.companyID !== 'Select Company' && this.state.companyID !== 0) ? this.state.companyID : this.props?.users?.me?.company?.id}}>
                      {({loading, error, data}) => {
                          if(loading) return <Loading />
                          if(error) return null
                          return(
                            <Input 
                              type="select"
                              label="Company Groups"
                              onChange={(e) => this.setState({groupID: e.target.value})}
                              data-key="groupID"
                              value={this.state.groupID}
                              static={false}
                              options= {[{label: 'Select Group', value: 'Select Group'}].concat(
                                ((data.groupsRe) || [])
                                .map((g) => 
                                  ({
                                    label: g.name,
                                    value: g.id
                                  })))}
                            />
                          )
                        }}
                      </Query>)}
                      {(!isSuperUser && !isSpecificUser && this.props?.users?.me?.company?.id) && (
                        <Query query = {GET_GROUPS_BY_COMPANY_USER} variables={{companyID: this.props?.users?.me?.company?.id}}>
                        {({loading, error, data}) => {
                            if(loading) return <Loading />
                            if(error) return null
                            return(
                              <Input 
                                type="select"
                                label="Company Groups"
                                onChange={(e) => this.setState({groupID: e.target.value})}
                                data-key="groupID"
                                value={this.state.groupID}
                                static={false}
                                options= {[{label: 'Select Group', value: 'Select Group'}].concat(
                                  ((data.groupsRe) || [])
                                  .map((g) => 
                                    ({
                                      label: g.name,
                                      value: g.id
                                    })))}
                              />
                            )
                          }}
                        </Query>)}
                    </li>
                </ul>)}
            </div>
            <div className="dropdownx">
                <button className="dropdownx-button" onClick={this.tagButtonClick}>Graph Settings <i className={this.state.tagButton ? 'fa fa-angle-down' : 'fa fa-angle-right'}/></button>
                {this.state.tagButton && (
                <ul className="dropdownx-content">
                <li>
                <Input
                    type="select"
                    label="Sensor Type"
                    onChange={(e) => {
                      this.setState({tag: e.target.value})
                    }}
                    data-key="tag"
                    value={this.state.tag}
                    options={[{label: 'Select Tag', value: 'Select Tag'}].concat(tagOptions)}
                /></li>
                <li>
                <Input
                    type="select"
                    label="Display Units"
                    onChange={(e) => {
                      this.setState({displayUnits: e.target.value})
                    }}
                    data-key="displayUnits"
                    value={this.state.displayUnits}
                    options= {[{label: 'Select Units', value: 'Select Units'}].concat(getDisplayOptions(this.state.tag))}
                /></li>
                </ul>)}
            </div>
            <div className="dropdownx">
                <button className="dropdownx-button" onClick={this.settingsButtonClick}>Transmit Settings <i className={this.state.settingButton ? 'fa fa-angle-down' : 'fa fa-angle-right'}/></button>
                {this.state.settingButton && (
                <ul className="dropdownx-content">
                <li>
                <Input
                    type="number"
                    label="Reading Interval (minutes)"
                    onChange={(e) => {
                      this.setState({intervalReading: e.target.value})
                    }}
                    data-key="intervalReading"
                    value={this.state.intervalReading}
                /></li>
                <li>
                <Input
                    type="number"
                    label="Transmit Interval (minutes)"
                    onChange={(e) => {
                      this.setState({intervalTransmit: e.target.value})
                    }}
                    data-key="intervalTransmit"
                    value={this.state.intervalTransmit}
                /></li>
                {(isSuperUser && firmwareVersions) && (<li>
                  <label>Application Image Signature</label>
                  <select
                    value={this.state.applicationImageSignature || ''}
                    onChange={(e) => {
                      this.setState({applicationImageSignature: e.target.value})
                    }}
                  >
                    <option key="1">Select signature</option>
                    <option key="32762" value="32762">NULL</option>
                    {firmwareVersions.filter(({name}) => name.toLowerCase().includes('application') || name.toLowerCase().includes('generic'))
                    .map(({crc_id}) => (
                      <option key={crc_id} value={crc_id}>
                        {crc_id}
                      </option>
                    ))}
                  </select>
                </li>)}
                {(isSuperUser && firmwareVersions) && (<li>
                  <label>Relocate Image Signature</label>
                  <select
                    value={this.state.relocateImageSignature || ''}
                    onChange={(e) => {
                      this.setState({relocateImageSignature: e.target.value})
                    }}
                  >
                    <option key="1">Select signature</option>
                    <option key="32762" value="32762">NULL</option>
                    {firmwareVersions
                    .filter(({name}) =>
                      name.toLowerCase().includes('relocate')
                    )
                    .map(({crc_id}) => (
                      <option key={crc_id} value={crc_id}>
                        {crc_id}
                      </option>
                    ))}
                  </select>
                </li>)}
                </ul>)}
            </div>
          <div className='dropdownx'>
            <button className="dropdownx-button" onClick={this.alarmButtonClick}>Alarm Settings<i className={this.state.alarmButton ? 'fa fa-angle-down' : 'fa fa-angle-right'}/></button>
            {this.state.alarmButton && (
            <ul className="dropdownxx-content">
              <li>
                <table>
                <thead>
                  <tr>
                    <th colSpan={6}>Alarm Rules<Tooltip color={'red'}><p style={{color: 'red'}}>
                      This uses the new alarm rules system that only works for newer firmware. YOU WILL NOT GET ALARMS IF YOUR DEVICE DOES NOT HAVE COMPATIBLE FIRMWARE. Make sure to set an user to be notified.</p>
                      </Tooltip></th>
                  </tr>
                </thead>
                <tbody className='table-scroll'>
                  {this?.state?.rules?.length !== 0 && (            
                  <tr style={{fontWeight: "bold", color: "blue"}}>
                    <td></td>
                    <td><i className="fa fa-caret-down" /> Alarm Name</td>
                    <td><i className="fa fa-caret-down" /> Sensor Type<Tooltip style={{color: 'red'}}>Assumes sensor type for all selected devices. Must be selected.</Tooltip></td>
                    <td><i className="fa fa-caret-down" /> Rule</td>
                    <td><i className="fa fa-caret-down" /> Value<Tooltip style={{color: 'red'}}>Assumes the unit of the current device (i.e. if graph of that device is in celcius, you must input a value that is in celcius). Change display unit if you want to use other measurement.</Tooltip></td>
                    <td><i className="fa fa-caret-down" /> Description (optional)</td>
                  </tr>)}
                  {this.state.rules.map((a, i) => (
                    <tr key={i}>
                      <td>
                        <button
                          onClick={() =>
                            this.setState({
                              rules: update(this.state.rules, {
                                $splice: [[i, 1]]
                              })
                            })
                          }
                        >
                          x
                        </button>
                      </td>
                      <td>
                        <Input
                          type="string"
                          fieldOnly
                          value={this.state.rules[i].name}
                          onChange={(e) =>
                            this.setState({
                              rules: update(this.state.rules, {
                                [i]: {name: {$set: e.target.value}}
                              })
                            })
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          fieldOnly
                          value={this.state.rules[i].tag}
                          onChange={(e) =>
                            this.setState({
                              rules: update(this.state.rules, {
                                [i]: {tag: {$set: e.target.value}}
                              })
                            })
                          }
                          options={tagOptions}
                        />
                      </td>
                      <td>
                        <Input
                          type="select"
                          fieldOnly
                          value={this.state.rules[i].symbol}
                          onChange={(e) =>
                            this.setState({
                              rules: update(this.state.rules, {
                                [i]: {symbol: {$set: e.target.value}}
                              })
                            })
                          }
                          options={[
                            {label: 'greater than', value: 1},
                            {label: 'less than', value: -1},
                            // {label: 'rate of change', value: 2}
                          ]}
                        />
                      </td>
                      <td>
                        <Input
                          type="float"
                          fieldOnly
                          value={this.state.rules[i].value}
                          onChange={(e) =>
                            this.setState({
                              rules: update(this.state.rules, {
                                [i]: {value: {$set: e.target.value}}
                              })
                            })
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="string"
                          fieldOnly
                          value={this.state.rules[i].desc}
                          onChange={(e) =>
                            this.setState({
                              rules: update(this.state.rules, {
                                [i]: {desc: {$set: e.target.value}}
                              })
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                    <tr>
                      <td colSpan="6" className="center-text">
                        <button
                          className="primary"
                          onClick={() =>
                            this.setState({
                              rules: this.state.rules.concat({
                                name: null,
                                tag: null,
                                symbol: null,
                                value: null,
                                desc: null
                              })
                            })
                          }
                        >
                          Add Alarm
                        </button>
                      </td>
                    </tr>
                </tbody>
              </table>
              </li>
              <br></br>
              <li>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={5}>Notifications</th>
                    </tr>
                  </thead>
              <tbody>
              {this?.state?.actions?.length !== 0 && (
                <tr style={{fontWeight: "bold", color: "blue"}}>
                  <td></td>
                  <td><i className="fa fa-caret-down" /> User</td>
                  <td><i className="fa fa-caret-down" /> Sensor Type<Tooltip>Assumes sensor type for all selected devices. Must be selected.</Tooltip></td>
                  <td><i className="fa fa-caret-down" /> Delay</td>
                  <td><i className="fa fa-caret-down" /> Action</td>
                </tr>)}
                {this.state.actions.map((a, i) => (
                  <tr key={i}>
                    <td>
                        <button
                          onClick={() =>
                            this.setState({
                              actions: update(this.state.actions, {
                                $splice: [[i, 1]]
                              })
                            })
                          }
                        >
                          x
                        </button>
                    </td>
                    <td>
                    <Select
                          options={users?.map((u) => ({label: u.firstName + ' ' + u.lastName, value: u.id}))}
                          fieldOnly
                          onChange={(e) =>
                            this.setState({
                              actions: update(this.state.actions, {
                                [i]: {userID: {$set: e.target.value}}
                              })
                            })
                          }
                          value={this.state.actions[i].userID}
                        />
                    </td>
                    <td>
                        <Input
                          type="select"
                          fieldOnly
                          value={this.state.actions[i].tag}
                          onChange={(e) =>
                            this.setState({
                              actions: update(this.state.actions, {
                                [i]: {tag: {$set: e.target.value}}
                              })
                            })
                          }
                          options={tagOptions}
                        />
                      </td>
                    <td>
                      <Input
                        type="select"
                        fieldOnly
                        value={this.state.actions[i].delay}
                        onChange={(e) =>
                          this.setState({
                            actions: update(this.state.actions, {
                              [i]: {delay: {$set: e.target.value}}
                            })
                          })
                        }
                        options={[
                          0,
                          5 * 60,
                          15 * 60,
                          30 * 60,
                          60 * 60,
                          120 * 60
                        ].map((delay) => ({
                          label: secondsToTimespan(delay),
                          value: delay
                        }))}
                      />
                    </td>
                    <td>
                      <Input
                        type="select"
                        fieldOnly
                        value={this.state.actions[i].action}
                        onChange={(e) =>
                          this.setState({
                            actions: update(this.state.actions, {
                              [i]: {action: {$set: e.target.value}}
                            })
                          })
                        }
                        options={[
                          {label: 'text', value: 'sms'},
                          {label: 'email', value: 'email'}
                        ]}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="5" className="center-text">
                    <button
                      className="primary"
                      onClick={() =>
                        this.setState({
                          actions: this.state.actions.concat({
                            delay: 0,
                            tag: null,
                            action: 'sms',
                            userID: null,
                          })
                        })
                      }
                    >
                      Add Notification
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
              </li>
              <br></br>
              <li>
                <table>
                  <thead>
                  <tr>
                    <th colSpan={6}>Alarm Legacy<Tooltip color={'red'}><p style={{color: 'red'}}>
                      Old alarm system for devices that can't use alarm rules. Don't use both.</p>
                      </Tooltip></th>
                  </tr>
                  </thead>
                <tbody>
                <tr>
                  <td style={{width: '50%'}}>
                    <Input
                      type="select"
                      label="Sensor Type"
                      onChange={(e) => {
                        this.setState({tag: e.target.value})
                      }}
                      data-key="tag"
                      value={this.state.tag}
                      options={[{label: 'None', value: 'None'}].concat(tagOptions)}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{width: '50%'}}>
                    <Input
                      type='float'
                      label={
                        <span>
                          <i className="fa fa-caret-up" /> Critical High
                        </span>
                      }
                      onChange={(e) =>
                        this.setState({criticalHigh: e.target.value})
                      }
                      value={this.state.criticalHigh}
                      className="high-low-settings"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{width: '50%'}}>
                    <Input
                      type='float'
                      label={
                      <span>
                        <i className="fa fa-caret-up" /> High Warning
                      </span>
                      }
                      onChange={(e) =>
                      this.setState({highWarning: e.target.value})
                      }
                      value={this.state.highWarning}
                      className="high-low-settings"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{width: '50%'}}>
                    <Input
                      type='float'
                      label={
                      <span>
                      <i className="fa fa-caret-down" /> Low Warning
                      </span>
                      }
                      onChange={(e) =>
                      this.setState({lowWarning: e.target.value})
                      }
                      value={this.state.lowWarning}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{width: '50%'}}>
                    <Input
                      type='float'
                      label={
                        <span>
                          <i className="fa fa-caret-up" /> Critical Low
                        </span>
                      }
                      onChange={(e) =>
                        this.setState({criticalLow: e.target.value})
                      }
                      value={this.state.criticalLow}
                      className="high-low-settings"
                    />
                  </td>
                </tr>
                </tbody>
                </table>
              </li>
            </ul>)}
          </div>
        </div>
        {this.state.clearDataWarning && (
          <Modal onClose={() => this.setState({clearDataWarning: false})}>
            <Modal.Title>Confirm Before Continuing</Modal.Title>
            {isSuperUser ? (
              <Modal.Body>
              Are you sure that you want to clear all sensor data for these devices?

              <Input
                type="checkbox"
                className="alarmsCheck"
                value={this.state.keepChanges}
                label="Also make setting changes I have selected"
                onChange={() => this.setState({keepChanges: !this.state.keepChanges})}
              />
              <Input
                type="checkbox"
                className="alarmsCheck"
                value={this.state.deleteAlarms}
                label="Delete alarms too"
                onChange={() => this.setState({deleteAlarms: !this.state.deleteAlarms, onlyAlarms: false})}
              />
              <Input
                type="checkbox"
                className="alarmsCheck"
                value={this.state.onlyAlarms}
                label="Delete ONLY alarms (sensor data unchanged)"
                onChange={() => this.setState({onlyAlarms: !this.state.onlyAlarms, deleteAlarms: false})}
              />
            </Modal.Body> ) :
            <Modal.Body>
            Are you sure that you want to clear ALL alarms for these devices?

            <Input
              type="checkbox"
              className="alarmsCheck"
              value={this.state.keepChanges}
              label="Also make setting changes I have selected"
              onChange={() => this.setState({keepChanges: !this.state.keepChanges})}
            />
          </Modal.Body>}
            <Modal.Footer>
              <button onClick={() => this.setState({clearDataWarning: false})}>
                Cancel
              </button>
              <button onClick={this.clearData} className="red primary">
                Yes, Clear All
              </button>
            </Modal.Footer>
          </Modal>
        )}{' '}
      </div>
      </div>
    )
  }
}

const tagOptions = [
  {label: 'temperature', value: 'temp'},{label: 'vibration', value: 'vib'},
  {label: 'switch', value: 'swt'}]


// TODO figure a way to add conversions for alarms on the backend for the other tags (requires cal)
// const tagOptionsTemp = [{label: 'pressure', value: 'psr'},{label: 'tank', value: 'spsr'},
//     {label: 'temperature', value: 'temp'},{label: 'vibration', value: 'vib'},
//     {label: 'switch', value: 'swt'}]

const secondsToTimespan = (seconds) => {
  if (seconds === 0) return 'Immediately'
  if (seconds < 60) return `After ${seconds} secs`
  if (seconds < 60 * 60) return `After ${seconds / 60} min`
  else return `After ${seconds / (60 * 60)} hours`
}

export default compose(
  graphql(UPDATE_MASS_SETTING, {name: 'updateMassSetting'}),
  graphql(CLEAR_MASS_DEVICE_DATA, {name: 'clearMassDeviceData'}),
  graphql(GET_COMPANIES, {name: 'companies'}),
  graphql(GET_USERS_RE, {name: 'users'}),
  graphql(GET_FOTA_VERSIONS, {name: 'fota'})
)(UpdateDevices)
