import React, {Component} from 'react'
import {dateTime as formatDateTime} from 'utils/format'
import {Query} from 'react-apollo'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import capitalize from 'lodash/capitalize'
import {GET_ME} from 'queries'
import {route} from 'router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './index.scss'

const GET_MESSAGE_LOG = gql`
  query GET_MESSAGE_LOG(
    $deviceID: Int
    $messageType: String
    $startTime: Int
    $endTime: Int
  ) {
    messageLog(
      deviceID: $deviceID
      messageType: $messageType
      startTime: $startTime
      endTime: $endTime
    ) {
      deviceID
      time
      messageType
      data {
        values
        message
        voltage
        temperature
        eventFlag
        additionalData
      }
    }
  }
`

class MessageLog extends Component {
  state = {
    deviceID: this.props.deviceID || null,
    messageType: 'all',
    startTime: Math.round(Date.now() / 1000) - 7 * 24 * 60 * 60,
    endTime: Math.round(Date.now() / 1000) + 60
  }

  renderData = ({messageType, data, time}) => {
    switch (messageType) {
      case 'data':
        return data !== null
          ? (data.values || []).join(', ')
          : JSON.stringify(data)
      case 'health':
        return (
          `${data ? data.temperature || '---' : '---'} \xB0F, ` +
          `${data ? data.voltage || '---' : '---'} V, ` +
          `${(data ? data.additionalData || [] : []).join(', ')}`
        )
      case 'gps':
        return data !== null
          ? (data.values || []).join(', ')
          : JSON.stringify(data)
      case 'overflow':
        return `{"message":[${data ? data.message : null}]}`
      case 'event':
      default:
        return JSON.stringify(data)
    }
  }

  renderSelect = (data) => {
    return (
      <select
        value={this.state.messageType}
        onChange={(e) => this.setState({messageType: e.target.value})}
      >
        <option value="all">All</option>
        <option value="data">Data</option>
        <option value="event">Event</option>
        <option value="health">Health</option>
        <option value="gps">GPS</option>
        <option value="overflow">Overflow</option>
      </select>
    )
  }

  render() {
    return (
      <Query query={GET_ME}>
        {({loading, error, data}) => {
          if (error) return 'error'
          if (loading) return <Loading />
          // Nico Mabilleau (Chevron) id: 11477
          if (data.me.isSuperUser
            || ([11198, 10937, 11204].includes(data.me.id) && this.state.deviceID === 5352)
            || ([11477].includes(data.me.id) && [5890, 5982].includes(this.state.deviceID))
          ) {
            return (
              <div className="message-log">
                <div className="toolbar">
                  <div style={{marginRight: '0.4em'}}>DeviceID: </div>
                  <input
                    type="number"
                    value={this.state.deviceID || ''}
                    onChange={(e) => {
                      const deviceID = e.target.value
                      this.setState({deviceID})
                      if (this.timeout) clearTimeout(this.timeout)
                      this.timeout = setTimeout(
                        () => route(`/admin/messageLog/${deviceID}`),
                        1000
                      )
                    }}
                  />
                  {data !== undefined ? this.renderSelect(data) : null}
                  <div style={{marginRight: '0.4em'}}>Start Date: </div>
                  <DatePicker
                    selected={this.state.startTime * 1000}
                    onChange={(date) => {
                      date.setHours(0, 0, 0)
                      this.setState({startTime: date / 1000})
                    }}
                  />
                  <div style={{marginRight: '0.4em'}}>End Date: </div>
                  <DatePicker
                    selected={this.state.endTime * 1000}
                    onChange={(date) => {
                      date.setHours(23, 59, 59)
                      this.setState({endTime: date / 1000})
                    }}
                  />
                </div>
                <div className="log">
                  {this.props.deviceID && (
                  <Query
                    query={GET_MESSAGE_LOG}
                    variables={{
                      deviceID: 1 * this.props.deviceID,
                      messageType: this.state.messageType,
                      startTime: this.state.startTime,
                      endTime: this.state.endTime
                    }}
                  >
                    {({loading, error, data}) => {
                      if (loading) return <Loading />
                      if (error) return 'error'

                      return (
                        <table>
                          <thead>
                            <tr>
                              <th>Device ID</th>
                              <th>Time</th>
                              <th>Message Type</th>
                              <th>Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.messageLog.map((m, i) => (
                              <tr key={i}>
                                <td>{m.deviceID}</td>
                                <td>{formatDateTime(m.time)}</td>
                                <td>{capitalize(m.messageType)}</td>
                                <td>{this.renderData(m)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )
                    }}
                  </Query>)}
                </div>
              </div>
            )
          } else {
            return <div>You do not have access to this page.</div>
          }
        }}
      </Query>
    )
  }
}

export default MessageLog
